import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';
import SignaturePad from 'react-signature-canvas';

import { createNotification } from '../Notification';
import { SAVE_PHYSICIAN_SIGNATURE } from './PhysicianInfo';

import './signature.css';

const PhysicianSignatureCapture = () => {
  const [sigImage, setSigImage] = useState(null);

  const { id } = useParams();

  const signatureRef = useRef(null);

  const [saveSignatureMutation, { loading: savingSignature }] = useMutation(
    SAVE_PHYSICIAN_SIGNATURE,
    {
      onCompleted({ setSignature }) {
        console.log(`Signature saved:`, setSignature);
        createNotification('info', 'Signature saved, you may close this page now.');
      },
      onError(error) {
        // TODO: Use notification
        console.log('Save signature', error);
      }
    },
  );

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = (close) => {
    const signature = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');

    setSigImage(signature);

    const variables = {
      foreignKey: id,
      type: 'Physician',
      signature,
    };

    if (process.env.NODE_ENV === 'development') {
      console.log('variables:', variables);
    }

    // Save the signature
    saveSignatureMutation({ variables });

    close();
  };

  const deleteSignature = (close) => {
    setSigImage(null);
    close && close();
  };

  return (
    <div className="container">
      <h3 className="title is-3 ml-5">Provider's Authorization</h3>
      <p className="ml-5"><strong>I hereby authorize Radeas Labs to perform tests...</strong></p>
      <p className="ml-5 mt-3">I acknowledge that I will provide the appropriate diagnosis codes (ICD-10) to the highest level of specificity, as to support medical necessity of the tests ordered. Additionally, I will have documentation to support medical necessity recorded in the patient’s medical chart. I understand the Office of the Inspector General requires such documentation in the patient’s medical record including date of service, tests ordered, and documentation to support medical necessity. I also agree to comply in a timely manner with any request for medical documentation required for the adjudication of Radeas Lab claims. Documentation to support medical necessity for all tests ordered should be recorded in the patient’s chart. By not signing, Provider signature and test orders are required to be documented in patient’s medical chart and available upon request.</p>

      <Popup
        modal
        trigger={<p className="button is-info is-outlined mx-6 my-4">Sign</p>}
        closeOnDocumentClick={false}
      >
        {(close) => (
          <>
            <SignaturePad
              ref={signatureRef}
              canvasProps={{ className: "signatureCanvas" }}
            />

            <div
              className="p-3"
              style={{ border: "1px solid black" }}
            >
              <button
                className="button is-info mr-6"
                onClick={() => saveSignature(close)}
              >
                Save
              </button>
              <button
                className="button is-info is-outlined mr-6"
                onClick={clearSignature}
              >
                Clear
              </button>
              <button
                className="button is-danger"
                onClick={close}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Popup>

      {sigImage && (
        <img
          src={sigImage}
          alt="Signature"
          style={{
            display: "block",
            margin: "0 auto",
            border: "1px solid black",
            width: "35%",
          }}
        />
      )}
    </div>
  );
};

export default PhysicianSignatureCapture;
