import React, { useEffect, useState } from 'react';

const TestMenuTable = ({ tests, testStrips, clinicSettings, handleChange, handleTestStripChange }) => {
  const [selectedTests, setSelectedTests] = useState([]);
  const [selectedTestStrips, setSelectedTestStrips] = useState([]);

  useEffect(() => {
    setSelectedTests(tests.map((test) => ({
      ...test,
      selected: test.isActive,
      scrn: test.scrnAvailable ? true : null,
    })));

    setSelectedTestStrips(testStrips.map((test) => ({
      ...test,
      // selected: test.isActive,
      scrn: true,
    })));
  }, []);

  useEffect(() => {
    handleChange({
      tests: selectedTests,
    });
  }, [selectedTests]);

  useEffect(() => {
    handleTestStripChange({
      tests: selectedTestStrips,
    });
  }, [selectedTestStrips]);

  const renderScreen = (test, idx) => {
    // if (!clinicSettings?.enableScreen || !test?.scrnAvailable) {
    //   return <td />;
    // }

    if (clinicSettings.enableScreen && test.scrnAvailable) {
      return (
        <td>
          <input
            type="checkbox"
            checked={test.scrn}
            onChange={() => handleScreen(idx)}
          />
        </td>
      );
    }

    return <td />;
  };

  const generateTestStripsTable = () => {
    return (
      testStrips.map((row, i) => {
        return (
          <tr key={i}>
            <td>
              <input
                type="checkbox"
                checked={row.selected}
                onChange={() => handleSelectedTestStrip(i)}
              />
            </td>
            <td className="has-text-dark">{row.drugClass}</td>
            <td className="has-text-dark">
              {row.parents ? (
                `${row.parents && new Array(row.parents.length+1).join("\u00A0\u00A0\u00A0\u00A0")}${row.drugName}`
              ) : (
                row.drugName
              )}
            </td>
            <td className="has-text-dark">{row.drugBrand}</td>
            <td><span className="icon"><i className="fas fa-check-square" /></span></td>
          </tr>
        );
      })
    );
  };

  const generateTable = () => {
    return (
      selectedTests.map((row, i) => {
        return (
          <tr key={i}>
            <td>
              <input
                type="checkbox"
                checked={row.selected}
                onChange={() => handleSelected(i)}
              />
            </td>
            <td className="has-text-dark">{row.drugClass}</td>
            <td className="has-text-dark">
              {row.parents ? (
                `${row.parents && new Array(row.parents.length+1).join("\u00A0\u00A0\u00A0\u00A0")}${row.drugName}`
              ) : (
                row.drugName
              )}
            </td>
            <td className="has-text-dark">{row.drugBrand}</td>
            {renderScreen(row, i)}
          </tr>
        );
      })
    );
  };

  const handleSelected = (idx) => {
    const updatedState = selectedTests.map((row, i) => {
      if (i === idx) {
        return {
          ...row,
          selected: !selectedTests[i].selected,
        };
      }

      return row;
    });

    setSelectedTests(updatedState);
  };

  const handleSelectedTestStrip = (idx) => {
    const updatedState = selectedTestStrips.map((row, i) => {
      if (i === idx) {
        return {
          ...row,
          selected: !selectedTestStrips[i].selected,
        };
      }

      return row;
    });

    setSelectedTestStrips(updatedState);
  };

  const handleScreen = (idx) => {
    const updatedState = selectedTests.map((row, i) => {
      if (i === idx) {
        return {
          ...row,
          scrn: !selectedTests[i].scrn,
        };
      }

      return row;
    });

    setSelectedTests(updatedState);
  };

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <table className="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th />
              <th>Drug Class</th>
              <th>Drug Name</th>
              <th>Drug Brand</th>
              {clinicSettings.enableScreen ? <th>Screen</th> : <th />}
            </tr>
          </thead>

          <tbody>
            {generateTable()}
          </tbody>
        </table>
      </div>

      {testStrips?.length > 0 && (
        <>
          <br />
          <br />

          <div style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-hoverable">
              <thead>
                <tr>
                  <th />
                  <th>Drug Class</th>
                  <th>Drug Name</th>
                  <th>Drug Brand</th>
                  <th>Screen</th>
                </tr>
              </thead>

              <tbody>
                {generateTestStripsTable()}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default TestMenuTable;
