import React from 'react';
import { css } from '@emotion/react';
// import CircleLoader from 'react-spinners/CircleLoader';
import ClipLoader from 'react-spinners/ClipLoader';
// import RingLoader from 'react-spinners/RingLoader';
import PuffLoader from 'react-spinners/PuffLoader';

const spinnerStyle = css`
  display: block;
  margin: 0 auto;
`;

const buttonSpinnerStyle = css`
  display: inline;
  margin: 0 0 0 1rem;
`;

const Loading = () => {
  // <CircleLoader color="#0888d1" size={60} css={spinnerStyle} />
  return (
    <div>
      <ClipLoader color="#83c3e8" size={50} css={spinnerStyle} />
    </div>
  );
};

const ButtonLoading = () => {
  // <RingLoader color="#0888d1" size={30} css={buttonSpinnerStyle} />
  return (
    <PuffLoader color="#83c3e8" size={30} css={buttonSpinnerStyle} />
  );
};

export default Loading;

export { ButtonLoading };
