import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import Loading, { ButtonLoading } from '../Loading';

// TODO: Wire to backend, save authUser as "initials"
export const GET_BILLING_INFO = gql`
  query ToxicologyTestProfile {
    toxicologyTestProfile {
      id
      clinicCode
      billingPoctOrIa
      billingCodes
      feeForTestBilling
      providerInitials
      specialInstructions
    }
  }
`;

export const UPDATE_BILLING_INFO = gql`
  mutation UpdateToxicologyTestProfile(
    $clinicCode: String!,
    $billingPoctOrIa: Boolean
    $billingCodes: String
    $feeForTestBilling: Boolean
    $providerInitials: String
    $specialInstructions: String
  ) {
    updateToxicologyTestProfile(
      data: {
        clinicCode: $clinicCode,
        billingPoctOrIa: $billingPoctOrIa,
        billingCodes: $billingCodes,
        feeForTestBilling: $feeForTestBilling,
        providerInitials: $providerInitials,
        specialInstructions: $specialInstructions
      }
    ) {
      id
      clinicCode
      billingPoctOrIa
      billingCodes
      feeForTestBilling
      providerInitials
      specialInstructions
    }
  }
`;

const Billing = ({ authUser, nextStep }) => {
  return (
    <div className="container">
      <h3 className="title is-3 ml-5">Billing</h3>

      <br />

      <BillingForm nextStep={nextStep} />
    </div>
  );
};

const BillingForm = ({ nextStep }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useQuery(
    GET_BILLING_INFO,
    {
      fetchPolicy: 'network-only',
      onCompleted({ toxicologyTestProfile }) {
        setData(toxicologyTestProfile);
        setLoading(false);
      },
      onError(error) {
        // TODO: show error notification
        console.log('Test Profile - Billing Error:', error);

        setLoading(false);
      }
    }
  );

  const [updateBillingInfo, { loading: loadingMutation }] = useMutation(
    UPDATE_BILLING_INFO,
    {
      onCompleted({ updateToxicologyTestProfile: data }) {
        console.log(data);

        // Move to next step
        nextStep();
      },
      onError(error) {
        // TODO: Use notification
        console.log('Update Clinic Info mutation error:', error);
      }
    }
  );

  if (loading) return <Loading />;

  return (
    <Formik
      initialValues={{
        billingPoctOrIa: data.billingPoctOrIa || false,
        billingCodes: data.billingCodes || '',
        feeForTestBilling: data.feeForTestBilling || false,
        providerInitials: data.providerInitials || '',
        specialInstructions: data.specialInstructions || '',
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        console.log(values);

        const {
          billingPoctOrIa,
          billingCodes,
          feeForTestBilling,
          providerInitials,
          specialInstructions,
        } = values;

        // Execute the mutation
        updateBillingInfo({
          variables: {
            clinicCode: data.clinicCode,
            billingPoctOrIa,
            billingCodes,
            feeForTestBilling,
            providerInitials,
            specialInstructions,
          }
        });

        // resetForm();
        setSubmitting(false);

        // Move to next step
        nextStep();
      }}
      validationSchema={Yup.object().shape({
        billingPoctOrIa: Yup.boolean(),
        billingCodes: Yup.string(),
        feeForTestBilling: Yup.boolean(),
        providerInitials: Yup.string(),
        specialInstructions: Yup.string(),
      })}
    >
      {(formProps) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = formProps;

        return (
          <div className="columns ml-4">
            <div className="column is-two-thirds">
              <form onSubmit={handleSubmit}>
                <div className="field">
                  <label className="checkbox">
                    <span>Clinic billing POCT or IA instrument:</span>
                    <Field type="checkbox" name="billingPoctOrIa" className="ml-2" />
                  </label>
                </div>

                {values.billingPoctOrIa ? (
                  <div className="field">
                    <label className="label" htmlFor="input-billingCodes">
                      Codes:
                    </label>
                    <input
                      id="input-billingCodes"
                      name="billingCodes"
                      className="input"
                      type="text"
                      value={values.billingCodes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                ) : null}

                <div className="field mt-5">
                  <label className="checkbox">
                    <span>Clinic Lab participation in Fee-for-Test Billing:</span>
                    <Field type="checkbox" name="feeForTestBilling" className="ml-2" />
                  </label>
                </div>

                <div className="field mt-5">
                  <label className="label" htmlFor="input-specialInstructions">
                    Special Instructions
                  </label>
                  <textarea
                    id="input-specialInstructions"
                    name="specialInstructions"
                    className="textarea"
                    placeholder="(Optional)"
                    value={values.specialInstructions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="mt-6">
                  <button
                    className="button is-primary"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    Save and Continue
                  </button>
                  {loading ? <ButtonLoading /> : null}
                </div>
              </form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Billing;
