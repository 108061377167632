import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

import Loading, { ButtonLoading } from '../Loading';

export const GET_AUTHORIZATION_INFO = gql`
  query ToxicologyTestProfile {
    toxicologyTestProfile {
      id
      clinicCode
      authorization
    }
  }
`;

export const UPDATE_AUTHORIZATION_INFO = gql`
  mutation UpdateToxicologyTestProfile(
    $clinicCode: String!,
    $authorization: Boolean
  ) {
    updateToxicologyTestProfile(
      data: {
        clinicCode: $clinicCode,
        authorization: $authorization
      }
    ) {
      id
      authorization
    }
  }
`;

const Authorization = ({ authUser, nextStep }) => {
  const [authorization, setAuthorization] = useState(null);
  const [clinicCode, setClinicCode] = useState('');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [requireAuthorization, setRequireAuthorization] = useState(false);

  useQuery(
    GET_AUTHORIZATION_INFO,
    {
      fetchPolicy: 'network-only',
      onCompleted({ toxicologyTestProfile }) {
        setClinicCode(toxicologyTestProfile.clinicCode);
        setAuthorization(!!toxicologyTestProfile.authorization);

        setLoading(false);
      },
      onError(error) {
        // TODO: show error notification
        console.log('Test Profile - clinic authorization error:', error);

        setLoading(false);
      },
    },
  );

  const [updateAuthorizationInfo] = useMutation(
    UPDATE_AUTHORIZATION_INFO,
    {
      onCompleted({ updateToxicologyTestProfile }) {
        setSubmitting(false);

        // Move to next step
        nextStep();
      },
      onError(error) {
        // TODO: use notification
        console.log('Update authorization mutation error:', error);

        setSubmitting(false);
      },
    },
  );

  const handleSubmit = () => {
    if (authorization) {
      updateAuthorizationInfo({
        variables: {
          clinicCode,
          authorization,
        },
      });
    } else {
      setRequireAuthorization(true);
    }

    setSubmitting(true);
  };

  if (loading) return <Loading />;

  return (
    <div className="container">
      <h3 className="title is-3 ml-5">Authorization</h3>
      <br />

      <div className="columns ml-4">
        <div className="column is-two-thirds">
          <p>
            I authorize Radeas Laboratories to perform drug testing on all specimen as follows:

            <ol className="ml-6 mt-5">
              <li>
                Quantify all positive Point of Care results indicated on the individual patient requisition form.
              </li>
              <li>
                Quantify negative Point of Care test results as indicated.
              </li>
              <li>
                Perform any additional testing/special instructions as defined on this form.
              </li>
              <li>
                Perform specimen validity testing unless otherwise indicated.
              </li>
              <li>
                Documentation to support medical necessity for all tests ordered should be recorded in the patient’s chart.
              </li>
            </ol><br/>

            I acknowledge that the test profile is deemed medically necessary because of the clinician’s desire to take reasonable steps to prevent abuse and diversion of controlled medications. I understand that I may order any of the above-stated tests separately or in necessary combinations consistent with the patient’s medical needs. I understand this test profile may be modified in writing at any time.
          </p>

          <div className="field mt-4">
            <label className="checkbox">
              <input
                type="checkbox"
                name="authorization"
                value="agree"
                checked={authorization}
                onChange={() => setAuthorization(!authorization)}
              />
              <span className="ml-2">I Agree (required)</span>
            </label>
          </div>

          <div className="mt-6">
            <button
              className="button is-primary"
              onClick={handleSubmit}
              disabled={!authorization}

            >
              Finish
            </button>
            {submitting ? <ButtonLoading /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authorization;
