import React from 'react';

import { useAuth, ChangePassword } from './auth';

const Account = () => {
  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  return (
    <section className="section">
      <div className="container">
        <div className="column is-half">
          <ChangePassword />
        </div>
      </div>
    </section>
  );
};

export default Account;
