import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Loading, { ButtonLoading } from '../Loading';
import { createNotification } from '../Notification';

import '../../styles/form.css';

export const GET_CLINIC_INFO = gql`
  query ToxicologyTestProfile {
    toxicologyTestProfile {
      id
      clinicName
      clinicCode
      address1
      address2
      city
      state
      zipCode
      clinicPhone
      clinicFax
      contactName
      contactPhone
      contactEmail
      salesRepresentative
    }
  }
`;

export const UPDATE_CLINIC_INFO = gql`
  mutation UpdateToxicologyTestProfile(
    $clinicCode: String!,
    $clinicName: String,
    $address1: String,
    $address2: String,
    $city: String,
    $state: String,
    $zipCode: String,
    $clinicPhone: String,
    $clinicFax: String,
    $contactName: String,
    $contactPhone: String,
    $contactEmail: String,
    $salesRepresentative: String,
  ) {
    updateToxicologyTestProfile(
      data: {
        clinicCode: $clinicCode,
        clinicName: $clinicName,
        address1: $address1,
        address2: $address2,
        city: $city,
        state: $state,
        zipCode: $zipCode,
        clinicPhone: $clinicPhone,
        clinicFax: $clinicFax,
        contactName: $contactName,
        contactPhone: $contactPhone,
        contactEmail: $contactEmail,
        salesRepresentative: $salesRepresentative,
      }
    ) {
      id
      clinicName
      clinicCode
      address1
      address2
      city
      state
      zipCode
      clinicPhone
      clinicFax
      contactName
      contactPhone
      contactEmail
      salesRepresentative
    }
  }
`;

const ClinicInfo = ({ authUser, nextStep }) => {
  // useEffect(() => {
  //   createNotification('info', 'Please refresh page if "Practice Name" is blank');
  // }, []);

  return (
    <div className="container">
      <h3 className="title is-3 ml-5">Clinic Information</h3>

      <br />

      <ClinicInfoForm authUser={authUser} nextStep={nextStep} />
    </div>
  );
};

const ClinicInfoForm = ({ authUser, nextStep }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log('Auth User:', authUser);
  //     getClinicInfo();
  //   }, 500);
  // }, []);

  useQuery(
    GET_CLINIC_INFO,
    {
      fetchPolicy: 'network-only',
      onCompleted({ toxicologyTestProfile }) {
        // console.log(`Clinic Code: ${toxicologyTestProfile.clinicCode}`)
        setData(toxicologyTestProfile);
        setLoading(false);
      },
      onError(error) {
        // TODO: show error notification
        console.log('Test Profile - Clinic Info', error);

        setLoading(false);
      }
    }
  );

  const [updateClinicInfo, { loading: loadingMutation }] = useMutation(
    UPDATE_CLINIC_INFO,
    {
      onCompleted({ updateToxicologyTestProfile: data }) {
        // console.log(data);

        // Move to next step
        nextStep();
      },
      onError(error) {
        // TODO: Use notification
        console.log('Update Clinic Info mutation', error);
      }
    }
  );

  if (loading) return <Loading />;

  return (
    <Formik
      initialValues={{
        practiceName: data.clinicName,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
        phone: data.clinicPhone,
        fax: data.clinicFax,
        pointOfContact: data.contactName,
        pointOfContactPhone: data.contactPhone,
        pointOfContactEmail: data.contactEmail,
        salesRepresentative: data.salesRepresentative || '',
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        // console.log(values);

        const {
          practiceName: clinicName,
          address1,
          address2,
          city,
          state,
          zipCode,
          phone: clinicPhone,
          fax: clinicFax,
          pointOfContact: contactName,
          pointOfContactPhone: contactPhone,
          pointOfContactEmail: contactEmail,
          salesRepresentative,
        } = values;

        // Execute the mutation
        updateClinicInfo({
          variables: {
            clinicCode: data.clinicCode,
            clinicName,
            address1,
            address2,
            city,
            state,
            zipCode,
            clinicPhone,
            clinicFax,
            contactName,
            contactPhone,
            contactEmail,
            salesRepresentative,
          }
        });

        // resetForm();
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        practiceName: Yup.string()
          .required('Practice name is required.'),
        address1: Yup.string()
          .required('Street address is required.'),
        address2: Yup.string(),
        city: Yup.string()
          .required("City is required."),
        state: Yup.string()
          .required("State is required.")
          .test("len", "Please select a state.", val => val && val.length === 2),
        zipCode: Yup.string()
          .required('Zip code is required.')
          .min(5, "Must be at least 5 digits."),
        phone: Yup.string()
          .required('Clinic phone number is required.')
          .test("len", "Should be 10 digits.", val => val && val.length === 10),
        fax: Yup.string(),
        pointOfContact: Yup.string()
          .required('A name is required.'),
        pointOfContactEmail: Yup.string()
          .email("Enter a valid email.")
          .required("An email is required."),
        pointOfContactPhone: Yup.string()
          .required("A phone number is required.")
          .test("len", "Should be 10 digits.", val => val && val.length === 10),
        salesRepresentative: Yup.string()
      })}
    >
      {(formProps) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched
        } = formProps;

        return (
          <div className="columns ml-4">
            <div className="column is-two-thirds">
              <form onSubmit={handleSubmit}>
                <div className="block">
                  <label className="label" htmlFor="input-practiceName">
                    Practice Name *
                  </label>
                  <input
                    id="input-practiceName"
                    name="practiceName"
                    className={errors.practiceName && touched.practiceName ? "input error" : "input"}
                    type="text"
                    placeholder="Enter your practice's name"
                    value={values.practiceName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.practiceName && touched.practiceName && (
                    <div className="input-feedback" data-testid="errors-practiceName">
                      {errors.practiceName}
                    </div>
                  )}
                </div>

                <div className="block">
                  <label className="label" htmlFor="input-address1">
                    Street Address *
                  </label>
                  <input
                    id="input-address1"
                    name="address1"
                    className={errors.address1 && touched.address1 ? "input error" : "input"}
                    type="text"
                    placeholder="Enter your street address"
                    value={values.address1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.address1 && touched.address1 && (
                    <div className="input-feedback" data-testid="errors-address1">
                      {errors.address1}
                    </div>
                  )}
                </div>

                <div className="block">
                  <label className="label" htmlFor="input-address2">
                    Address Line 2
                  </label>
                  <input
                    id="input-address2"
                    name="address2"
                    className={errors.address2 && touched.address2 ? "input error" : "input"}
                    type="text"
                    placeholder="Street address continued (optional)"
                    value={values.address2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.address2 && touched.address2 && (
                    <div className="input-feedback" data-testid="errors-address2">
                      {errors.address2}
                    </div>
                  )}
                </div>

                <div className="block">
                  <label className="label" htmlFor="input-city">
                    City *
                  </label>
                  <input
                    id="input-city"
                    name="city"
                    className={errors.city && touched.city ? "input error" : "input"}
                    type="text"
                    placeholder="City"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.city && touched.city && (
                    <div className="input-feedback" data-testid="errors-city">
                      {errors.city}
                    </div>
                  )}
                </div>

                <div className="block">
                  <label className="label" htmlFor="input-state">
                    State *
                  </label>
                  <div className="select">
                    <select
                      id="input-state"
                      name="state"
                      value={values.state}
                      className={errors.state && touched.state ? "input error" : "input"}
                      onChange={(opt) => setFieldValue("state", opt.target.value)}
                      onBlur={() => setFieldTouched("state", true)}
                    >
                      <option defaultValue value="-">--</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                  {errors.state && touched.state && (
                    <div className="input-feedback" data-testid="errors-state">
                      {errors.state}
                    </div>
                  )}
                </div>


                  <label className="label" htmlFor="input-zipCode">
                    Zip Code *
                  </label>
                  <input
                    id="input-zipCode"
                    name="zipCode"
                    className={errors.zipCode && touched.zipCode ? "input error" : "input"}
                    type="text"
                    placeholder="Enter your zip code"
                    value={values.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength="11"
                  />
                  {(errors.zipCode && touched.zipCode ?
                    <div className="input-feedback" data-testid="errors-zipCode">
                      {errors.zipCode}
                    </div>:
                    <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode"/>
                  )}



                  <label className="label" htmlFor="input-phone">
                    Phone *
                  </label>
                  <input
                    id="input-phone"
                    name="phone"
                    className={errors.phone && touched.phone ? "input error" : "input"}
                    type="phone"
                    placeholder="Enter your phone number"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    pattern="[2-9][0-9]{9}"
                    maxLength="10"
                  />
                  {(errors.phone && touched.phone ?
                    <div className="input-feedback" data-testid="errors-phone">
                      {errors.phone}
                    </div>:
                      <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode"/>
                  )}



                  <label className="label" htmlFor="input-fax">
                    Fax
                  </label>
                  <input
                    id="input-fax"
                    name="fax"
                    className={errors.fax && touched.fax ? "input error" : "input"}
                    type="phone"
                    placeholder="Enter your fax number"
                    value={values.fax}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength="10"
                    pattern="[2-9][0-9]{9}"
                  />
                  {(errors.fax && touched.fax ?
                    <div className="input-feedback" data-testid="errors-fax">
                      {errors.fax}
                    </div>:
                      <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode"/>
                  )}



                  <label className="label" htmlFor="input-pointOfContact">
                    Point of Contact *
                  </label>
                  <input
                    id="input-pointOfContact"
                    name="pointOfContact"
                    className={errors.pointOfContact && touched.pointOfContact ? "input error" : "input"}
                    type="text"
                    placeholder="Enter a contact name"
                    value={values.pointOfContact}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {(errors.pointOfContact && touched.pointOfContact ?
                    <div className="input-feedback" data-testid="errors-pointOfContact">
                      {errors.pointOfContact}
                    </div>:
                    <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode"/>
                  )}



                  <label className="label" htmlFor="input-pointOfContactPhone">
                    Point of Contact Phone *
                  </label>
                  <input
                    id="input-pointOfContactPhone"
                    name="pointOfContactPhone"
                    className={errors.pointOfContactPhone && touched.pointOfContactPhone ? "input error" : "input"}
                    type="phone"
                    placeholder="Enter a contact phone number"
                    value={values.pointOfContactPhone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength="10"
                    pattern="[2-9][0-9]{9}"
                  />
                  {(errors.pointOfContactPhone && touched.pointOfContactPhone ?
                    <div className="input-feedback" data-testid="errors-pointOfContactPhone">
                      {errors.pointOfContactPhone}
                    </div>:
                    <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode"/>
                  )}



                  <label className="label" htmlFor="input-pointOfContactEmail">
                    Point of Contact Email *
                  </label>
                  <input
                    id="input-pointOfContactEmail"
                    name="pointOfContactEmail"
                    className={errors.pointOfContactEmail && touched.pointOfContactEmail ? "input error" : "input"}
                    type="email"
                    placeholder="Enter a contact email"
                    value={values.pointOfContactEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {(errors.pointOfContactEmail && touched.pointOfContactEmail ?
                    <div className="input-feedback" data-testid="errors-pointOfContactEmail">
                      {errors.pointOfContactEmail}
                    </div>:
                    <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode"/>
                  )}


                {/* TODO: Put sales rep in graphql and db */}

                  <label className="label" htmlFor="input-salesRepresentative">
                    Sales Representative
                  </label>
                  <input
                    id="input-salesRepresentative"
                    name="salesRepresentative"
                    className={errors.salesRepresentative && touched.salesRepresentative ? "input error" : "input"}
                    type="text"
                    placeholder="Sales representative's name"
                    value={values.salesRepresentative}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {(errors.salesRepresentative && touched.salesRepresentative ?
                    <div className="input-feedback" data-testid="errors-salesRepresentative">
                      {errors.salesRepresentative}
                    </div>:
                    <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode"/>
                  )}


                <div className="mt-6">
                  <input
                    type="submit"
                    className="button is-primary"
                    value="Save and Continue"
                    disabled={isSubmitting}
                  />
                  {loadingMutation ? <ButtonLoading /> : null}
                </div>
              </form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default ClinicInfo;
