import React, { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory, Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { ButtonLoading } from '../Loading';
import { SignInLink } from './SignIn';

import Notification, { clearNotification, createNotification,  useNotification} from '../Notification';
import { decodeJwt } from '../../utils';
import { authUserVar } from '../../cache';
import * as ROUTES from '../../constants/routes';
import { CLINIC_USER } from '../../constants/roles';

import '../../styles/form.css';


export const MIN_PASSWORD_LENGTH = 8;
const DEFAULT_ROLES = [CLINIC_USER];

export const REGISTER_USER = gql`
  mutation CreateClinicUser(
    $clinicCode: String!,
    $accessCode: String!
    $firstName: String!,
    $lastName: String!,
    $phone: String!,
    $email: String!,
    $password: String!,
    $roles: [String]!
  ) {
    createClinicUser(
      data: {
        clinicCode: $clinicCode,
        accessCode: $accessCode,
        firstName: $firstName,
        lastName: $lastName,
        phone: $phone,
        email: $email,
        password: $password,
        roles: $roles
      }
    ) {
      token
      toxicologyTestProfile
    }
  }
`;

const SignUp = () => {
  useEffect(() => {
    // Clear notifications when navigating away
    return function cleanup() {
      clearNotification();
    }
  }, []);

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <RegisterForm />
            <br />
            <SignInLink />
          </div>
        </div>
      </div>
    </section>
  );
};

const RegisterForm = () => {
  const history = useHistory();
  const { messageType, messageText } = useNotification();
  const [createUser, { loading }] = useMutation(
    REGISTER_USER,
    {
      onCompleted({ createClinicUser }) {
        if (createClinicUser) {
          localStorage.setItem('token', createClinicUser.token);

          // Save id and roles in local state
          const { authUserId, roles } = decodeJwt(createClinicUser.token);
          authUserVar({ id: authUserId, roles });

          // Redirect after registration
          if (!createClinicUser.toxicologyTestProfile) {
            // Redirect to toxicology test profile page if profile doesn't exist
            history.push(ROUTES.CLINIC_TEST_PROFILE);
          } else {
            // Else redirect to dashboard
            history.push(ROUTES.DASHBOARD_PENDING_ORDERS);
          }
        }
      },
      onError(error) {
        console.log('Register', error);

        if (error.message === 'Invalid clinic code') {
          createNotification('danger', 'Invalid clinic code. Please try again.');
        } else {
          createNotification('danger', 'Sorry, something went wrong. Please try again.');
        }
      },
    },
  );

  return (
    <div>
      {/* <Notification messageType={messageType} messageText={messageText} /> */}
      <h1 className="title is-size-2 has-text-centered">Radeas Labs</h1>

      <br/><br/>
      <div className="page">
      <h3 className="title is-size-4 has-text-centered">Register</h3>

      <Formik
        initialValues={{
          clinicCode: '',
          accessCode: '',
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          password: '',
          confirmPassword: ''
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const {
            clinicCode,
            accessCode,
            firstName,
            lastName,
            phone,
            email,
            password
          } = values;

          // Execute the mutation
          createUser({
            variables: {
              clinicCode,
              accessCode,
              firstName,
              lastName,
              phone,
              email,
              password,
              roles: DEFAULT_ROLES,
            }
          });

          // resetForm();
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          clinicCode: Yup.string()
            .required("Clinic code is required"),
          accessCode: Yup.string()
            .required("Access code is required"),
          firstName: Yup.string()
            .required("First name is required"),
          lastName: Yup.string()
            .required("Last name is required"),
          phone: Yup.string()
            .required("Phone number is required")
            .test("len", "Should be 10 digits", val => val && val.length === 10),
          email: Yup.string()
            .email("Enter a valid email")
            .required("Email is required"),
          password: Yup.string()
            .required("Password is required")
            .min(MIN_PASSWORD_LENGTH, `Password must be at least ${MIN_PASSWORD_LENGTH} characters`),
          confirmPassword: Yup.string()
            .required("Password confirmation is required")
            .oneOf([Yup.ref('password'), null], "Passwords must match"),
        })}
      >
        {(formProps) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = formProps;

          return (
            <form onSubmit={handleSubmit}>
              <label className="label" htmlFor="input-clinicCode">
                Clinic Code
              </label>
              <input
                id="input-clinicCode"
                name="clinicCode"
                className={errors.clinicCode && touched.clinicCode ? "input error" : "input"}
                type="text"
                placeholder="Enter your Clinic Code"
                value={values.clinicCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {(errors.clinicCode && touched.clinicCode ?
                <div className="input-feedback help has-text-right" data-testid="errors-clinicCode">
                  {errors.clinicCode}
                </div>
                :
                <div className="input-feedback help feedback-hidden" data-testid="errors-clinicCode"/>
              )}

              <label className="label" htmlFor="input-accessCode">
                Access Code
              </label>
              <input
                id="input-accessCode"
                name="accessCode"
                className={errors.accessCode && touched.accessCode ? "input error" : "input"}
                type="text"
                placeholder="Enter your Access Code"
                value={values.accessCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {(errors.accessCode && touched.accessCode ?
                <div className="input-feedback help has-text-right" data-testid="errors-accessCode">
                  {errors.accessCode}
                </div>
                :
                <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode"/>
              )}

              <label className="label" htmlFor="input-firstName">
                First Name
              </label>
              <input
                id="input-firstName"
                name="firstName"
                className={errors.firstName && touched.firstName ? "input error" : "input"}
                type="text"
                placeholder="Enter your first name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {(errors.firstName && touched.firstName ?
                <div className="input-feedback help has-text-right" data-testid="errors-firstName">
                  {errors.firstName}
                </div>
                :
                <div className="input-feedback help feedback-hidden" data-testid="errors-firstName"/>
              )}

              <label className="label" htmlFor="input-lastName">
                Last Name
              </label>
              <input
                id="input-lastName"
                name="lastName"
                className={errors.lastName && touched.lastName ? "input error" : "input"}
                type="text"
                placeholder="Enter your last name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {(errors.lastName && touched.lastName ?
                <div className="input-feedback help has-text-right" data-testid="errors-lastName">
                  {errors.lastName}
                </div>
                :
                <div className="input-feedback help feedback-hidden" data-testid="errors-lastName"/>
              )}

              <label className="label" htmlFor="input-phone">
                Phone
              </label>
              <input
                id="input-phone"
                name="phone"
                className={errors.phone && touched.phone ? "input error" : "input"}
                type="tel"
                placeholder="Enter your phone number"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength="10"
                pattern="[2-9][0-9]{9}"
              />
              {(errors.phone && touched.phone ?
                <div className="input-feedback help has-text-right" data-testid="errors-phone">
                  {errors.phone}
                </div>
                :
                <div className="input-feedback help feedback-hidden" data-testid="errors-phone"/>
              )}

              <label className="label" htmlFor="input-email">
                Email
              </label>
              <input
                id="input-email"
                name="email"
                className={errors.email && touched.email ? "input error" : "input"}
                type="email"
                placeholder="Enter your email address"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {(errors.email && touched.email ?
                <div className="input-feedback help has-text-right" data-testid="errors-email">
                  {errors.email}
                </div>
                :
                <div className="input-feedback help feedback-hidden" data-testid="errors-email"/>
              )}

              <label className="label" htmlFor="input-password">
                Password
              </label>
              <input
                id="input-password"
                name="password"
                className={errors.password && touched.password ? "input error" : "input"}
                type="password"
                placeholder="Enter a password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {(errors.password && touched.password ?
                <div className="input-feedback help has-text-right" data-testid="errors-password">
                  {errors.password}
                </div>
                :
                <div className="input-feedback help feedback-hidden" data-testid="errors-password"/>
              )}

              <label className="label" htmlFor="input-confirmPassword">
                Confirm Password
              </label>
              <input
                id="input-confirmPassword"
                name="confirmPassword"
                className={errors.confirmPassword && touched.confirmPassword ? "input error" : "input"}
                type="password"
                placeholder="Re-enter password"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {(errors.confirmPassword && touched.confirmPassword ?
                <div className="input-feedback help has-text-right" data-testid="errors-confirmPassword">
                  {errors.confirmPassword}
                </div>
                :
                <div className="input-feedback help feedback-hidden" data-testid="errors-confirmPassword"/>
              )}

              <input
                type="submit"
                className="button is-primary"
                value="Submit"
                disabled={isSubmitting}
              />
              {loading ? <ButtonLoading /> : null}
            </form>
          );
        }}
      </Formik>
    </div>
    </div>
  );
};

const SignUpLink = () => {
  return (
    <p className="ml-5">
      Don't have an account? <Link to={ROUTES.SIGN_UP}>Request Access</Link>
    </p>
  );
};

export default SignUp;

export { SignUpLink };
