import jwt_decode from 'jwt-decode';
import Dymo from 'dymojs';
import axios from 'axios';

export function decodeJwt(token) {
  try {
    const decoded = jwt_decode(token);

    const { exp, iat } = decoded;
    const authUserId = decoded.sub;
    const { roles } = decoded[process.env.REACT_APP_JWT_NAMESPACE];

    return { authUserId, roles, exp, iat };
  } catch {
    return { authUserId: null, roles: null, exp: null, iat: null };
  }
}

export function printLabel(printerName = '', clinicCode = '', sampleId = '', patientInitials = '', patientDOB = '') {
  if (process.env.NODE_ENV === 'development') {
    console.log(`Printing Sample ID Label for ${clinicCode} with sample ID: ${sampleId}...`);
  }

  let printCount = 0;

  // const printerName = 'DYMO LabelWriter 450 Turbo';
  // const printerName = 'DYMO LabelWriter 550';
  const defaultPrinters = [
    'DYMO LabelWriter 450 Turbo',
    'DYMO LabelWriter 550',
  ];

  const dymo = new Dymo();
  // const labelDate = new Date(Date.now()).toLocaleString().split(',')[0];
  const labelDate = new Date(Date.now()).toLocaleDateString('en-US');

  // Label data
  const labelXml = `<?xml version="1.0" encoding="utf-8"?>
    <DesktopLabel Version="1">
      <DYMOLabel Version="3">
        <Description>DYMO Label</Description>
        <Orientation>Landscape</Orientation>
        <LabelName>Small30336</LabelName>
        <InitialLength>0</InitialLength>
        <BorderStyle>SolidLine</BorderStyle>
        <DYMORect>
          <DYMOPoint>
            <X>0.09</X>
            <Y>0.05666666</Y>
          </DYMOPoint>
          <Size>
            <Width>1.976666</Width>
            <Height>0.9033334</Height>
          </Size>
        </DYMORect>
        <BorderColor>
          <SolidColorBrush>
            <Color A="1" R="0" G="0" B="0"></Color>
          </SolidColorBrush>
        </BorderColor>
        <BorderThickness>1</BorderThickness>
        <Show_Border>False</Show_Border>
        <DynamicLayoutManager>
          <RotationBehavior>ClearObjects</RotationBehavior>
          <LabelObjects>
            <BarcodeObject>
              <Name>IBarcodeObject0</Name>
              <Brushes>
                <BackgroundBrush>
                  <SolidColorBrush>
                    <Color A="1" R="1" G="1" B="1"></Color>
                  </SolidColorBrush>
                </BackgroundBrush>
                <BorderBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BorderBrush>
                <StrokeBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </StrokeBrush>
                <FillBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </FillBrush>
              </Brushes>
              <Rotation>Rotation0</Rotation>
              <OutlineThickness>1</OutlineThickness>
              <IsOutlined>False</IsOutlined>
              <BorderStyle>SolidLine</BorderStyle>
              <Margin>
                <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
              </Margin>
              <BarcodeFormat>Code128B</BarcodeFormat>
              <Data>
                <MultiDataString>
                  <DataString>${sampleId}</DataString>
                </MultiDataString>
              </Data>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <Size>Small</Size>
              <TextPosition>Bottom</TextPosition>
              <FontInfo>
                <FontName>Arial</FontName>
                <FontSize>12</FontSize>
                <IsBold>False</IsBold>
                <IsItalic>False</IsItalic>
                <IsUnderline>False</IsUnderline>
                <FontBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </FontBrush>
              </FontInfo>
              <ObjectLayout>
                <DYMOPoint>
                  <X>0.1</X>
                  <Y>0.05666666</Y>
                </DYMOPoint>
                <Size>
                  <Width>1.936833</Width>
                  <Height>0.4516667</Height>
                </Size>
              </ObjectLayout>
            </BarcodeObject>
            <QRCodeObject>
              <Name>IQRCodeObject0</Name>
              <Brushes>
                <BackgroundBrush>
                  <SolidColorBrush>
                    <Color A="1" R="1" G="1" B="1"></Color>
                  </SolidColorBrush>
                </BackgroundBrush>
                <BorderBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BorderBrush>
                <StrokeBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </StrokeBrush>
                <FillBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </FillBrush>
              </Brushes>
              <Rotation>Rotation0</Rotation>
              <OutlineThickness>1</OutlineThickness>
              <IsOutlined>False</IsOutlined>
              <BorderStyle>SolidLine</BorderStyle>
              <Margin>
                <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
              </Margin>
              <BarcodeFormat>QRCode</BarcodeFormat>
              <Data>
                <DataString>${sampleId}</DataString>
              </Data>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <Size>AutoFit</Size>
              <EQRCodeType>QRCodeText</EQRCodeType>
              <TextDataHolder>
                <Value>${sampleId}</Value>
              </TextDataHolder>
              <ObjectLayout>
                <DYMOPoint>
                  <X>0.09</X>
                  <Y>0.4977233</Y>
                </DYMOPoint>
                <Size>
                  <Width>0.505814</Width>
                  <Height>0.4622768</Height>
                </Size>
              </ObjectLayout>
            </QRCodeObject>
            <TextObject>
              <Name>ITextObject0</Name>
              <Brushes>
                <BackgroundBrush>
                  <SolidColorBrush>
                    <Color A="0" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BackgroundBrush>
                <BorderBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BorderBrush>
                <StrokeBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </StrokeBrush>
                <FillBrush>
                  <SolidColorBrush>
                    <Color A="0" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </FillBrush>
              </Brushes>
              <Rotation>Rotation0</Rotation>
              <OutlineThickness>1</OutlineThickness>
              <IsOutlined>False</IsOutlined>
              <BorderStyle>SolidLine</BorderStyle>
              <Margin>
                <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
              </Margin>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <FitMode>AlwaysFit</FitMode>
              <IsVertical>False</IsVertical>
              <FormattedText>
                <FitMode>AlwaysFit</FitMode>
                <HorizontalAlignment>Center</HorizontalAlignment>
                <VerticalAlignment>Middle</VerticalAlignment>
                <IsVertical>False</IsVertical>
                <LineTextSpan>
                  <TextSpan>
                    <Text>${clinicCode}</Text>
                    <FontInfo>
                      <FontName>Segoe UI</FontName>
                      <FontSize>9.8</FontSize>
                      <IsBold>False</IsBold>
                      <IsItalic>False</IsItalic>
                      <IsUnderline>False</IsUnderline>
                      <FontBrush>
                        <SolidColorBrush>
                          <Color A="1" R="0" G="0" B="0"></Color>
                        </SolidColorBrush>
                      </FontBrush>
                    </FontInfo>
                  </TextSpan>
                </LineTextSpan>
              </FormattedText>
              <ObjectLayout>
                <DYMOPoint>
                  <X>0.6808707</X>
                  <Y>0.5315851</Y>
                </DYMOPoint>
                <Size>
                  <Width>0.6367509</Width>
                  <Height>0.1971551</Height>
                </Size>
              </ObjectLayout>
            </TextObject>
            <TextObject>
              <Name>ITextObject1</Name>
              <Brushes>
                <BackgroundBrush>
                  <SolidColorBrush>
                    <Color A="0" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BackgroundBrush>
                <BorderBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BorderBrush>
                <StrokeBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </StrokeBrush>
                <FillBrush>
                  <SolidColorBrush>
                    <Color A="0" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </FillBrush>
              </Brushes>
              <Rotation>Rotation0</Rotation>
              <OutlineThickness>1</OutlineThickness>
              <IsOutlined>False</IsOutlined>
              <BorderStyle>SolidLine</BorderStyle>
              <Margin>
                <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
              </Margin>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <FitMode>AlwaysFit</FitMode>
              <IsVertical>False</IsVertical>
              <FormattedText>
                <FitMode>AlwaysFit</FitMode>
                <HorizontalAlignment>Center</HorizontalAlignment>
                <VerticalAlignment>Middle</VerticalAlignment>
                <IsVertical>False</IsVertical>
                <LineTextSpan>
                  <TextSpan>
                    <Text>${labelDate}</Text>
                    <FontInfo>
                      <FontName>Segoe UI</FontName>
                      <FontSize>8.6</FontSize>
                      <IsBold>False</IsBold>
                      <IsItalic>False</IsItalic>
                      <IsUnderline>False</IsUnderline>
                      <FontBrush>
                        <SolidColorBrush>
                          <Color A="1" R="0" G="0" B="0"></Color>
                        </SolidColorBrush>
                      </FontBrush>
                    </FontInfo>
                  </TextSpan>
                </LineTextSpan>
              </FormattedText>
              <ObjectLayout>
                <DYMOPoint>
                  <X>1.388742</X>
                  <Y>0.5199611</Y>
                </DYMOPoint>
                <Size>
                  <Width>0.62333</Width>
                  <Height>0.2101208</Height>
                </Size>
              </ObjectLayout>
            </TextObject>
            <TextObject>
              <Name>ITextObject2</Name>
              <Brushes>
                <BackgroundBrush>
                  <SolidColorBrush>
                    <Color A="0" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BackgroundBrush>
                <BorderBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BorderBrush>
                <StrokeBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </StrokeBrush>
                <FillBrush>
                  <SolidColorBrush>
                    <Color A="0" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </FillBrush>
              </Brushes>
              <Rotation>Rotation0</Rotation>
              <OutlineThickness>1</OutlineThickness>
              <IsOutlined>False</IsOutlined>
              <BorderStyle>SolidLine</BorderStyle>
              <Margin>
                <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
              </Margin>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <FitMode>AlwaysFit</FitMode>
              <IsVertical>False</IsVertical>
              <FormattedText>
                <FitMode>AlwaysFit</FitMode>
                <HorizontalAlignment>Center</HorizontalAlignment>
                <VerticalAlignment>Middle</VerticalAlignment>
                <IsVertical>False</IsVertical>
                <LineTextSpan>
                  <TextSpan>
                    <Text>${patientInitials}</Text>
                    <FontInfo>
                      <FontName>Segoe UI</FontName>
                      <FontSize>11.7</FontSize>
                      <IsBold>False</IsBold>
                      <IsItalic>False</IsItalic>
                      <IsUnderline>False</IsUnderline>
                      <FontBrush>
                        <SolidColorBrush>
                          <Color A="1" R="0" G="0" B="0"></Color>
                        </SolidColorBrush>
                      </FontBrush>
                    </FontInfo>
                  </TextSpan>
                </LineTextSpan>
              </FormattedText>
              <ObjectLayout>
                <DYMOPoint>
                  <X>0.6205306</X>
                  <Y>0.7601376</Y>
                </DYMOPoint>
                <Size>
                  <Width>0.7574308</Width>
                  <Height>0.1998625</Height>
                </Size>
              </ObjectLayout>
            </TextObject>
            <TextObject>
              <Name>ITextObject3</Name>
              <Brushes>
                <BackgroundBrush>
                  <SolidColorBrush>
                    <Color A="0" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BackgroundBrush>
                <BorderBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </BorderBrush>
                <StrokeBrush>
                  <SolidColorBrush>
                    <Color A="1" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </StrokeBrush>
                <FillBrush>
                  <SolidColorBrush>
                    <Color A="0" R="0" G="0" B="0"></Color>
                  </SolidColorBrush>
                </FillBrush>
              </Brushes>
              <Rotation>Rotation0</Rotation>
              <OutlineThickness>1</OutlineThickness>
              <IsOutlined>False</IsOutlined>
              <BorderStyle>SolidLine</BorderStyle>
              <Margin>
                <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
              </Margin>
              <HorizontalAlignment>Center</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <FitMode>AlwaysFit</FitMode>
              <IsVertical>False</IsVertical>
              <FormattedText>
                <FitMode>AlwaysFit</FitMode>
                <HorizontalAlignment>Center</HorizontalAlignment>
                <VerticalAlignment>Middle</VerticalAlignment>
                <IsVertical>False</IsVertical>
                <LineTextSpan>
                  <TextSpan>
                    <Text>${patientDOB}</Text>
                    <FontInfo>
                      <FontName>Segoe UI</FontName>
                      <FontSize>8.7</FontSize>
                      <IsBold>False</IsBold>
                      <IsItalic>False</IsItalic>
                      <IsUnderline>False</IsUnderline>
                      <FontBrush>
                        <SolidColorBrush>
                          <Color A="1" R="0" G="0" B="0"></Color>
                        </SolidColorBrush>
                      </FontBrush>
                    </FontInfo>
                  </TextSpan>
                </LineTextSpan>
              </FormattedText>
              <ObjectLayout>
                <DYMOPoint>
                  <X>1.398136</X>
                  <Y>0.7337756</Y>
                </DYMOPoint>
                <Size>
                  <Width>0.6179653</Width>
                  <Height>0.2262245</Height>
                </Size>
              </ObjectLayout>
            </TextObject>
          </LabelObjects>
        </DynamicLayoutManager>
      </DYMOLabel>
      <LabelApplication>Blank</LabelApplication>
      <DataTable>
        <Columns></Columns>
        <Rows></Rows>
      </DataTable>
    </DesktopLabel>
  `;

  // Print the label
  if (printerName.length > 0) {
    console.log(`Sending to ${printerName}...`);

    dymo.print(printerName, labelXml).then(() => {
      console.log('Done.');
    }).catch((error) => {
      console.log('Label printing error:', error);
    });

    return true;
  }

  return false;
}

export async function getPrinters() {
  if (process.env.NODE_ENV === 'development') {
    console.log('Getting connected label printers...');
  }

  const dymo = new Dymo();

  // Get connected printers
  const connectedPrinters = [];

  try {
    const printersXml = await dymo.getPrinters();

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(printersXml, "text/xml");

    const printers = xmlDoc.getElementsByTagName("Printers")[0].childNodes;
    // console.log({ printers });

    printers.forEach((printer) => {
      const isConnected = printer.getElementsByTagName("IsConnected")[0].childNodes[0].textContent?.toUpperCase();
      // console.log({ isConnected });

      if (isConnected === 'TRUE') {
        const name = printer.getElementsByTagName("Name")[0].childNodes[0].textContent;
        connectedPrinters.push(name);

        console.log(`${name} is connected.`);
      }
    });

    return connectedPrinters;
  } catch (error) {
    console.log('Error getting connected printers:', error);

    return connectedPrinters;
  }
}

export function filterTests(tests) {
  const filtered = tests.filter((test) => !test.drugName.substring(0, 3).includes('All') && test.pos);

  return filtered;
}

export function sendText(phone, msg) {
  // Super simplistic phone validation
  if (!phone || phone.length < 10) {
    return false;
  }

  const key = process.env.REACT_APP_TEXT_API_KEY;

  console.log('sendText phone:', phone);
  console.log('sendText msg:', msg);

  axios.post(process.env.REACT_APP_SEND_PHYSICIAN_SIGNATURE_URL, {
    key,
    to_phone: phone,
    text_body: msg,
  })
    .then((res) => {
      console.log(res);

      return true;
    })
    .catch((err) => {
      console.log(err);

      return false;
    });

  return false;
}

// const labelXml = `<?xml version="1.0" encoding="utf-8"?>
// <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
//   <PaperOrientation>Landscape</PaperOrientation>
//   <Id>Small30336</Id>
//   <PaperName>30336 1 in x 2-1/8 in</PaperName>
//   <DrawCommands>
//     <RoundRectangle X="0" Y="0" Width="1440" Height="3060" Rx="180" Ry="180"/>
//   </DrawCommands>
//   <ObjectInfo>
//     <TextObject>
//       <Name>TEXT</Name>
//       <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
//       <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
//       <LinkedObjectName></LinkedObjectName>
//       <Rotation>Rotation0</Rotation>
//       <IsMirrored>False</IsMirrored>
//       <IsVariable>False</IsVariable>
//       <HorizontalAlignment>Center</HorizontalAlignment>
//       <VerticalAlignment>Middle</VerticalAlignment>
//       <TextFitMode>ShrinkToFit</TextFitMode>
//       <UseFullFontHeight>True</UseFullFontHeight>
//       <Verticalized>False</Verticalized>
//       <StyledText>
//         <Element>
//           <String>${clinicCode} ${labelDate}</String>
//           <Attributes>
//             <Font Family="PT Mono" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
//             <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
//           </Attributes>
//         </Element>
//       </StyledText>
//     </TextObject>
//     <Bounds X="129.5999" Y="1026.897" Width="2846.4" Height="331.5031"/>
//   </ObjectInfo>
//   <ObjectInfo>
//     <BarcodeObject>
//       <Name>BARCODE</Name>
//       <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
//       <BackColor Alpha="255" Red="255" Green="255" Blue="255"/>
//       <LinkedObjectName></LinkedObjectName>
//       <Rotation>Rotation0</Rotation>
//       <IsMirrored>False</IsMirrored>
//       <IsVariable>False</IsVariable>
//       <Text>${sampleId}</Text>
//       <Type>Code128Auto</Type>
//       <Size>Small</Size>
//       <TextPosition>Bottom</TextPosition>
//       <TextFont Family="Helvetica" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
//       <CheckSumFont Family="Helvetica" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
//       <TextEmbedding>None</TextEmbedding>
//       <ECLevel>0</ECLevel>
//       <HorizontalAlignment>Center</HorizontalAlignment>
//       <QuietZonesPadding Left="0" Right="0" Top="0" Bottom="0"/>
//     </BarcodeObject>
//     <Bounds X="129.5999" Y="57.59995" Width="2846.4" Height="986.9531"/>
//   </ObjectInfo>
// </DieCutLabel>
// `;
