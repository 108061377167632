import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Link, useHistory } from "react-router-dom";
import { customAlphabet } from "nanoid";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import PatientList from "./PatientList";
import PatientPage from "./PatientPage";
import { ButtonLoading } from "../Loading";
import { useAuth } from "../auth";
import { createNotification } from "../Notification";
import * as ROUTES from "../../constants/routes";

import "../../styles/form.css";

export const GET_CLINIC_CODE = gql`
  query ClinicCode {
    meClinicUser {
      clinicCode
    }
  }
`;

export const CREATE_PATIENT = gql`
  mutation CreatePatient(
    $patientId: String!
    $clinicCode: String!
    $firstName: String!
    $lastName: String!
    $middleName: String!
    $sex: String!
    $birthMonth: String!
    $birthDay: String!
    $birthYear: String!
    $email: String!
    $phone: String!
    $address1: String!
    $address2: String!
    $city: String!
    $state: String!
    $zipCode: String!
    $race: String!
    $maritalStatus: String!
    $responsiblePartyRelationship: String!
    $responsiblePartyFirstName: String!
    $responsiblePartyLastName: String!
    $responsiblePartyMiddleName: String!
    $responsiblePartyBirthMonth: String!
    $responsiblePartyBirthDay: String!
    $responsiblePartyBirthYear: String!
    $responsiblePartySex: String!
    $responsiblePartyAddress1: String!
    $responsiblePartyAddress2: String!
    $responsiblePartyCity: String!
    $responsiblePartyState: String!
    $responsiblePartyZipCode: String!
    $responsiblePartyPhone: String!
    $responsiblePartyEmail: String!
    $patientInsurance: String!
    $primaryInsuranceCompany: String!
    $primaryInsurancePolicyNumber: String!
    $primaryInsuranceGroupNumber: String!
    $primaryInsuranceSubscriberRelationship: String!
    $additionalInsurance: [AdditionalInsuranceInput]
  ) {
    createPatient(
      data: {
        patientId: $patientId
        clinicCode: $clinicCode
        firstName: $firstName
        lastName: $lastName
        middleName: $middleName
        sex: $sex
        birthMonth: $birthMonth
        birthDay: $birthDay
        birthYear: $birthYear
        email: $email
        phone: $phone
        address1: $address1
        address2: $address2
        city: $city
        state: $state
        zipCode: $zipCode
        race: $race
        maritalStatus: $maritalStatus
        responsiblePartyRelationship: $responsiblePartyRelationship
        responsiblePartyFirstName: $responsiblePartyFirstName
        responsiblePartyLastName: $responsiblePartyLastName
        responsiblePartyMiddleName: $responsiblePartyMiddleName
        responsiblePartyBirthMonth: $responsiblePartyBirthMonth
        responsiblePartyBirthDay: $responsiblePartyBirthDay
        responsiblePartyBirthYear: $responsiblePartyBirthYear
        responsiblePartySex: $responsiblePartySex
        responsiblePartyAddress1: $responsiblePartyAddress1
        responsiblePartyAddress2: $responsiblePartyAddress2
        responsiblePartyCity: $responsiblePartyCity
        responsiblePartyState: $responsiblePartyState
        responsiblePartyZipCode: $responsiblePartyZipCode
        responsiblePartyPhone: $responsiblePartyPhone
        responsiblePartyEmail: $responsiblePartyEmail
        patientInsurance: $patientInsurance
        primaryInsuranceCompany: $primaryInsuranceCompany
        primaryInsurancePolicyNumber: $primaryInsurancePolicyNumber
        primaryInsuranceGroupNumber: $primaryInsuranceGroupNumber
        primaryInsuranceSubscriberRelationship: $primaryInsuranceSubscriberRelationship
        additionalInsurance: $additionalInsurance
      }
    ) {
      id
      updatedDate
    }
  }
`;

const Patient = () => {
  // Require auth
  useAuth({ messageType: "info", messageText: "Please log in" });

  return (
    <section className="section">
      <div className="container">
        <div className="column is-half-desktop">
          <NewPatientForm />
        </div>
      </div>
    </section>
  );
};

const NewPatientForm = () => {
  const history = useHistory();

  const [clinicCode, setClinicCode] = useState("");

  // Genereate years from 1900 to current year
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    new Array((currentYear % 100) + 101),
    (_, i) => currentYear - i
  );

  const nanoid = customAlphabet("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ", 14);

  useQuery(GET_CLINIC_CODE, {
    fetchPolicy: "network-only",
    onCompleted({ meClinicUser }) {
      setClinicCode(meClinicUser.clinicCode);
    },
    onError(error) {
      console.log("Error getting clinic code:", error);
      createNotification("danger", "Sorry, could not retrieve clinic info.");
    },
  });

  const [createPatient, { loading }] = useMutation(CREATE_PATIENT, {
    onCompleted({ createPatient }) {
      if (process.env.NODE_ENV === 'development') console.log(createPatient);

      createNotification("info", "Patient created.");
    },
    onError(error) {
      console.log("Create patient mutation error:", error);
      createNotification("danger", "Sorry, something went wrong. Could not create the patient.");
    },
  });

  const generatePatientId = (e, setFieldValue) => {
    e.preventDefault();

    const patientId = nanoid();
    setFieldValue("patientId", patientId);
  };

  return (
    <Formik
      initialValues={{
        patientId: "",
        firstName: "",
        lastName: "",
        middleName: "",
        sex: "",
        birthMonth: "",
        birthDay: "",
        birthYear: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        race: "",
        maritalStatus: "",
        responsiblePartyRelationship: "self",
        responsiblePartyFirstName: "",
        responsiblePartyLastName: "",
        responsiblePartyMiddleName: "",
        responsiblePartyBirthMonth: "",
        responsiblePartyBirthDay: "",
        responsiblePartyBirthYear: "",
        responsiblePartySex: "",
        responsiblePartyAddress1: "",
        responsiblePartyAddress2: "",
        responsiblePartyCity: "",
        responsiblePartyState: "",
        responsiblePartyZipCode: "",
        responsiblePartyPhone: "",
        responsiblePartyEmail: "",
        patientInsurance: "insurance",
        primaryInsuranceCompany: "",
        primaryInsurancePolicyNumber: "",
        primaryInsuranceGroupNumber: "",
        primaryInsuranceSubscriberRelationship: "self",
        secondaryInsuranceCompanyName: "",
        secondaryInsurancePolicyNumber: "",
        secondaryInsuranceGroupNumber: "",
      }}
      validationSchema={Yup.object().shape({
        // patientId: Yup.string()
        //   .required("Please enter a patient ID"),
        patientId: Yup.string(),
        firstName: Yup.string().required("Patient's first name is required"),
        lastName: Yup.string().required("Patient's last name is required"),
        middleName: Yup.string(),
        sex: Yup.string().required("Please select an option"),
        birthMonth: Yup.string().required("Required"),
        birthDay: Yup.string().required("Required"),
        birthYear: Yup.string().required("Required"),
        // email: Yup.string()
        //   .email("Please enter a valid email")
        //   .required("Patient's email is required"),
        email: Yup.string()
          .email()
          .when('phone', {
            is: (phone) => !phone || phone.length === 0,
            then: Yup.string().email().required("Either a phone number or email address is required"),
            otherwise: Yup.string(),
          }),
        // phone: Yup.string().required("Patient's phone is required"),
          phone: Yup.string().when('email', {
            is: (email) => !email || email.length === 0,
            then: Yup.string().required("Either a phone number or email address is required"),
            otherwise: Yup.string(),
          }),
        address1: Yup.string().required("Patient's street address is required"),
        address2: Yup.string(),
        city: Yup.string().required("Patient's city is required"),
        state: Yup.string().required("Patient's state is required"),
        zipCode: Yup.string().required("Patient's zip code is require"),
        race: Yup.string(),
        maritalStatus: Yup.string(),
        responsiblePartyRelationship:
          Yup.string().required("Please select one"),
        responsiblePartyFirstName: Yup.string(),
        responsiblePartyLastName: Yup.string(),
        responsiblePartyMiddleName: Yup.string(),
        responsiblePartyBirthMonth: Yup.string(),
        responsiblePartyBirthDay: Yup.string(),
        responsiblePartyBirthYear: Yup.string(),
        responsiblePartySex: Yup.string(),
        responsiblePartyAddress1: Yup.string(),
        responsiblePartyAddress2: Yup.string(),
        responsiblePartyCity: Yup.string(),
        responsiblePartyState: Yup.string(),
        responsiblePartyZipCode: Yup.string(),
        responsiblePartyPhone: Yup.string(),
        responsiblePartyEmail: Yup.string(),
        patientInsurance: Yup.string(),
        primaryInsuranceCompany: Yup.string().when("patientInsurance", {
          is: "insurance",
          then: Yup.string().required("Insurance company is required"),
        }),
        primaryInsurancePolicyNumber: Yup.string().when("patientInsurance", {
          is: "insurance",
          then: Yup.string().required("Policy number is required"),
        }),
        primaryInsuranceGroupNumber: Yup.string().when("patientInsurance", {
          is: "insurance",
          then: Yup.string().required("Group number is required"),
        }),
        primaryInsuranceSubscriberRelationship: Yup.string().when(
          "patientInsurance",
          {
            is: "insurance",
            then: Yup.string().required("Subscriber relationship is required"),
          }
        ),
        secondaryInsuranceCompanyName: Yup.string(),
        secondaryInsurancePolicyNumber: Yup.string(),
        secondaryInsuranceGroupNumber: Yup.string(),
      }, [['email', 'phone']])}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const newValues = {};

        for (const key in values) {
          if (['secondaryInsuranceCompanyName', 'secondaryInsurancePolicyNumber', 'secondaryInsuranceGroupNumber'].includes(key)) {
            newValues['additionalInsurance'] = [{
              companyName: values.secondaryInsuranceCompanyName,
              policyNumber: values.secondaryInsurancePolicyNumber,
              groupNumber: values.secondaryInsuranceGroupNumber,
            }];
          } else {
            newValues[key] = values[key];
          }
        }

        const data = { ...newValues, clinicCode };

        // Save mutation
        createPatient({ variables: data });

        // resetForm();
        setSubmitting();

        history.goBack();
      }}
    >
      {(formProps) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        } = formProps;

        return (
          <div className="page">
            <h4 className="title is-4 is-inline-block mt-1">
              Create New Patient
            </h4>

            <h6 className="subtitle mt-2">
              Enter Patient Information
            </h6>

            <form onSubmit={handleSubmit}>
              <label className="label has-text-dark" htmlFor="input-patientId">
                Patient ID (From Your EHR)
              </label>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input
                    id="input-patientId"
                    name="patientId"
                    className={
                      errors.patientId && touched.patientId
                        ? "input error"
                        : "input"
                    }
                    type="text"
                    placeholder="(Optional) Enter a patient ID"
                    value={values.patientId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {/*<div className="control">
                    <button
                      className="button is-primary"
                      onClick={(e) => generatePatientId(e, setFieldValue)}
                    >
                      Generate
                    </button>
                  </div>*/}
              </div>
              {errors.patientId && touched.patientId ? (
                <div
                  className="input-feedback help has-text-right"
                  data-testid="errors-patientId"
                >
                  {errors.patientId}
                </div>
              ) : (
                <div
                  className="input-feedback help feedback-hidden"
                  data-testid="errors-accessCode"
                />
              )}

              <label className="label has-text-dark" htmlFor="input-firstName">
                First Name *
              </label>
              <input
                id="input-firstName"
                name="firstName"
                className={
                  errors.firstName && touched.firstName
                    ? "input error"
                    : "input"
                }
                type="text"
                placeholder="Patient's first name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.firstName && touched.firstName ? (
                <div
                  className="input-feedback help has-text-right"
                  data-testid="errors-firstName"
                >
                  {errors.firstName}
                </div>
              ) : (
                <div
                  className="input-feedback help feedback-hidden"
                  data-testid="errors-accessCode"
                />
              )}

              <label className="label has-text-dark" htmlFor="input-middleName">
                Middle Name
              </label>
              <input
                id="input-middleName"
                name="middleName"
                className={
                  errors.middleName && touched.middleName
                    ? "input error"
                    : "input"
                }
                type="text"
                placeholder="(Optional) Patient's middle name"
                value={values.middleName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.middleName && touched.middleName ? (
                <div
                  className="input-feedback help has-text-right"
                  data-testid="errors-middleName"
                >
                  {errors.middleName}
                </div>
              ) : (
                <div
                  className="input-feedback help feedback-hidden"
                  data-testid="errors-accessCode"
                />
              )}

              <label className="label has-text-dark" htmlFor="input-lastName">
                Last Name *
              </label>
              <input
                id="input-lastName"
                name="lastName"
                className={
                  errors.lastName && touched.lastName ? "input error" : "input"
                }
                type="text"
                placeholder="Patient's Last name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.lastName && touched.lastName ? (
                <div
                  className="input-feedback help has-text-right"
                  data-testid="errors-lastName"
                >
                  {errors.lastName}
                </div>
              ) : (
                <div
                  className="input-feedback help feedback-hidden"
                  data-testid="errors-accessCode"
                />
              )}

              <div className="block">
                <label className="label has-text-dark" htmlFor="input-sex">
                  Gender *
                </label>
                <div className="select">
                  <select
                    id="input-sex"
                    name="sex"
                    value={values.sex}
                    className={
                      errors.sex && touched.sex ? "input error" : "input"
                    }
                    onChange={(opt) => setFieldValue("sex", opt.target.value)}
                    onBlur={() => setFieldTouched("sex", true)}
                  >
                    <option defaultValue value="-">
                      --
                    </option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                {errors.sex && touched.sex ? (
                  <div className="input-feedback help" data-testid="errors-sex">
                    {errors.sex}
                  </div>
                ) : (
                  <div
                    className="input-feedback help feedback-hidden"
                    data-testid="errors-accessCode"
                  />
                )}
              </div>

              <div className="block">
                <label className="label has-text-dark" htmlFor="input-birthday">
                  Birthday *
                </label>
                <div className="select">
                  <select
                    id="input-birthMonth"
                    name="birthMonth"
                    value={values.birthMonth}
                    className={
                      errors.birthMonth && touched.birthMonth
                        ? "input error"
                        : "input"
                    }
                    onChange={(opt) =>
                      setFieldValue("birthMonth", opt.target.value)
                    }
                    onBlur={() => setFieldTouched("birthMonth", true)}
                  >
                    <option defaultValue value="-">
                      MM
                    </option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                  {errors.birthMonth && touched.birthMonth ? (
                    <div
                      className="input-feedback help"
                      data-testid="errors-birthMonth"
                    >
                      {errors.birthMonth}
                    </div>
                  ) : (
                    <div
                      className="input-feedback help feedback-hidden"
                      data-testid="errors-birthMonth"
                    />
                  )}
                </div>
                {" / "}
                <div className="select">
                  <select
                    id="input-birthDay"
                    name="birthDay"
                    value={values.birthDay}
                    className={
                      errors.birthDay && touched.birthDay
                        ? "input error"
                        : "input"
                    }
                    onChange={(opt) =>
                      setFieldValue("birthDay", opt.target.value)
                    }
                    onBlur={() => setFieldTouched("birthDay", true)}
                  >
                    <option defaultValue value="-">
                      DD
                    </option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </select>
                  {errors.birthDay && touched.birthDay ? (
                    <div
                      className="input-feedback help"
                      data-testid="errors-birthDay"
                    >
                      {errors.birthDay}
                    </div>
                  ) : (
                    <div
                      className="input-feedback help feedback-hidden"
                      data-testid="errors-birthDay"
                    />
                  )}
                </div>
                {" / "}
                <div className="select">
                  <select
                    id="input-birthYear"
                    name="birthYear"
                    value={values.birthYear}
                    className={
                      errors.birthYear && touched.birthYear
                        ? "input error"
                        : "input"
                    }
                    onChange={(opt) =>
                      setFieldValue("birthYear", opt.target.value)
                    }
                    onBlur={() => setFieldTouched("birthYear", true)}
                  >
                    <option defaultValue value="-">
                      YYYY
                    </option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {errors.birthYear && touched.birthYear ? (
                    <div
                      className="input-feedback help"
                      data-testid="errors-birthYear"
                    >
                      {errors.birthYear}
                    </div>
                  ) : (
                    <div
                      className="input-feedback help feedback-hidden"
                      data-testid="errors-birthYear"
                    />
                  )}
                </div>
              </div>

              <label className="label has-text-dark" htmlFor="input-email">
                Email *
              </label>
              <input
                id="input-email"
                name="email"
                className={
                  errors.email && touched.email ? "input error" : "input"
                }
                type="email"
                placeholder="Patient's email address"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <div
                  className="input-feedback help has-text-right"
                  data-testid="errors-email"
                >
                  {errors.email}
                </div>
              ) : (
                <div
                  className="input-feedback help feedback-hidden"
                  data-testid="errors-accessCode"
                />
              )}

              <label className="label has-text-dark" htmlFor="input-phone">
                Phone *
              </label>
              <input
                id="input-phone"
                name="phone"
                className={
                  errors.phone && touched.phone ? "input error" : "input"
                }
                type="phone"
                placeholder="Patient's phone number"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                pattern="[2-9][0-9]{9}"
                maxLength="10"
              />
              {errors.phone && touched.phone ? (
                <div
                  className="input-feedback help has-text-right"
                  data-testid="errors-phone"
                >
                  {errors.phone}
                </div>
              ) : (
                <div
                  className="input-feedback help feedback-hidden"
                  data-testid="errors-accessCode"
                />
              )}

              <label className="label has-text-dark" htmlFor="input-address1">
                Street Address *
              </label>
              <input
                id="input-address1"
                name="address1"
                className={
                  errors.address1 && touched.address1 ? "input error" : "input"
                }
                type="text"
                placeholder="Patient's street address"
                value={values.address1}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.address1 && touched.address1 ? (
                <div
                  className="input-feedback help has-text-right"
                  data-testid="errors-address1"
                >
                  {errors.address1}
                </div>
              ) : (
                <div
                  className="input-feedback help feedback-hidden"
                  data-testid="errors-accessCode"
                />
              )}

              <label className="label has-text-dark" htmlFor="input-address2">
                Address Line 2
              </label>
              <input
                id="input-address2"
                name="address2"
                className={
                  errors.address2 && touched.address2 ? "input error" : "input"
                }
                type="text"
                placeholder="(Optional) Patient's street address continued"
                value={values.address2}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.address2 && touched.address2 ? (
                <div
                  className="input-feedback help has-text-right"
                  data-testid="errors-address2"
                >
                  {errors.address2}
                </div>
              ) : (
                <div
                  className="input-feedback help feedback-hidden"
                  data-testid="errors-accessCode"
                />
              )}

              <label className="label has-text-dark" htmlFor="input-city">
                City *
              </label>
              <input
                id="input-city"
                name="city"
                className={
                  errors.city && touched.city ? "input error" : "input"
                }
                type="text"
                placeholder="Patient's city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.city && touched.city ? (
                <div
                  className="input-feedback help has-text-right"
                  data-testid="errors-city"
                >
                  {errors.city}
                </div>
              ) : (
                <div
                  className="input-feedback help feedback-hidden"
                  data-testid="errors-accessCode"
                />
              )}

              <label className="label has-text-dark" htmlFor="input-state">
                State *
              </label>
              <div className="select">
                <select
                  id="input-state"
                  name="state"
                  value={values.state}
                  className={
                    errors.state && touched.state ? "input error" : "input"
                  }
                  onChange={(opt) => setFieldValue("state", opt.target.value)}
                  onBlur={() => setFieldTouched("state", true)}
                >
                  <option defaultValue value="-">
                    --
                  </option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
              {errors.state && touched.state ? (
                <div className="input-feedback help" data-testid="errors-state">
                  {errors.state}
                </div>
              ) : (
                <div
                  className="input-feedback help feedback-hidden"
                  data-testid="errors-accessCode"
                />
              )}

              <label className="label has-text-dark" htmlFor="input-zipCode">
                Zip Code *
              </label>
              <input
                id="input-zipCode"
                name="zipCode"
                className={
                  errors.zipCode && touched.zipCode ? "input error" : "input"
                }
                type="text"
                placeholder="Patient's zip code"
                value={values.zipCode}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength="11"
              />
              {errors.zipCode && touched.zipCode ? (
                <div
                  className="input-feedback help has-text-right"
                  data-testid="errors-zipCode"
                >
                  {errors.zipCode}
                </div>
              ) : (
                <div
                  className="input-feedback help feedback-hidden"
                  data-testid="errors-accessCode"
                />
              )}

              <div className="block">
                <label className="label has-text-dark" htmlFor="input-race">
                  Race
                </label>
                <div className="select">
                  <select
                    id="input-race"
                    name="race"
                    value={values.race}
                    className={
                      errors.race && touched.race ? "input error" : "input"
                    }
                    onChange={(opt) => setFieldValue("race", opt.target.value)}
                    onBlur={() => setFieldTouched("race", true)}
                  >
                    <option defaultValue value="-">
                      --
                    </option>
                    <option value="american-indian-or-alaska-native">
                      American Indian or Alaska Native
                    </option>
                    <option value="asian">Asian</option>
                    <option value="black">Black or African American</option>
                    <option value="native-hawaiian-or-pacific-islander">
                      Native Hawaiian or Pacific Islander
                    </option>
                    <option value="white">White</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>

              <div className="block">
                <label className="label has-text-dark" htmlFor="input-maritalStatus">
                  Marital Status
                </label>
                <div className="select">
                  <select
                    id="input-maritalStatus"
                    name="maritalStatus"
                    value={values.maritalStatus}
                    className={
                      errors.maritalStatus && touched.maritalStatus
                        ? "input error"
                        : "input"
                    }
                    onChange={(opt) =>
                      setFieldValue("maritalStatus", opt.target.value)
                    }
                    onBlur={() => setFieldTouched("maritalStatus", true)}
                  >
                    <option defaultValue value="-">
                      --
                    </option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="separated">Separated</option>
                    <option value="divorced">Divorced</option>
                    <option value="widowed">Widowed</option>
                    <option value="unknown">Unknown</option>
                  </select>
                </div>
              </div>

              <div className="block">
                <label
                  className="label has-text-dark"
                  htmlFor="input-responsiblePartyRelationship"
                >
                  Relationship to Responsible Party
                </label>
                <div className="select">
                  <select
                    id="input-responsiblePartyRelationship"
                    name="responsiblePartyRelationship"
                    value={values.responsiblePartyRelationship}
                    className={
                      errors.responsiblePartyRelationship &&
                      touched.responsiblePartyRelationship
                        ? "input error"
                        : "input"
                    }
                    onChange={(opt) =>
                      setFieldValue(
                        "responsiblePartyRelationship",
                        opt.target.value
                      )
                    }
                    onBlur={() =>
                      setFieldTouched("responsiblePartyRelationship", true)
                    }
                  >
                    <option defaultValue value="self">
                      Self
                    </option>
                    <option value="child">Child</option>
                    <option value="legal-guardian">Legal Guardian</option>
                    <option value="spouse">Spouse</option>
                    <option value="none">None</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>

              {values.responsiblePartyRelationship !== "self" ? (
                <React.Fragment>
                  <br />
                  <h4 className="title is-4">Responsible Party Information</h4>
                  <br />
                  <div>
                    <label
                      className="label has-text-dark"
                      htmlFor="input-responsiblePartyFirstName"
                    >
                      First Name
                    </label>
                    <input
                      id="input-responsiblePartyFirstName"
                      name="responsiblePartyFirstName"
                      className={
                        errors.responsiblePartyFirstName &&
                        touched.responsiblePartyFirstName
                          ? "input error"
                          : "input"
                      }
                      type="text"
                      placeholder="Responsible party's first name"
                      value={values.responsiblePartyFirstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.responsiblePartyFirstName &&
                    touched.responsiblePartyFirstName ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-responsiblePartyFirstName"
                      >
                        {errors.responsiblePartyFirstName}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}

                    <label
                      className="label has-text-dark"
                      htmlFor="input-responsiblePartyMiddleName"
                    >
                      Middle Name
                    </label>
                    <input
                      id="input-responsiblePartyMiddleName"
                      name="responsiblePartyMiddleName"
                      className={
                        errors.responsiblePartyMiddleName &&
                        touched.responsiblePartyMiddleName
                          ? "input error"
                          : "input"
                      }
                      type="text"
                      placeholder="(Optional) Responsible party's middle name"
                      value={values.responsiblePartyMiddleName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.responsiblePartyMiddleName &&
                    touched.responsiblePartyMiddleName ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-responsiblePartyMiddleName"
                      >
                        {errors.responsiblePartyMiddleName}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}

                    <label
                      className="label has-text-dark"
                      htmlFor="input-responsiblePartyLastName"
                    >
                      Last Name
                    </label>
                    <input
                      id="input-responsiblePartyLastName"
                      name="responsiblePartyLastName"
                      className={
                        errors.responsiblePartyLastName &&
                        touched.responsiblePartyLastName
                          ? "input error"
                          : "input"
                      }
                      type="text"
                      placeholder="Responsible party's last name"
                      value={values.responsiblePartyLastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.responsiblePartyLastName &&
                    touched.responsiblePartyLastName ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-responsiblePartyLastName"
                      >
                        {errors.responsiblePartyLastName}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}

                    <label
                      className="label has-text-dark"
                      htmlFor="input-responsiblePartyEmail"
                    >
                      Email
                    </label>
                    <input
                      id="input-responsiblePartyEmail"
                      name="responsiblePartyEmail"
                      className={
                        errors.responsiblePartyEmail &&
                        touched.responsiblePartyEmail
                          ? "input error"
                          : "input"
                      }
                      type="responsiblePartyEmail"
                      placeholder="Responsible party's email address"
                      value={values.responsiblePartyEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.responsiblePartyEmail &&
                    touched.responsiblePartyEmail ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-responsiblePartyEmail"
                      >
                        {errors.responsiblePartyEmail}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}

                    <label
                      className="label has-text-dark"
                      htmlFor="input-responsiblePartyPhone"
                    >
                      Phone
                    </label>
                    <input
                      id="input-responsiblePartyPhone"
                      name="responsiblePartyPhone"
                      className={
                        errors.responsiblePartyPhone &&
                        touched.responsiblePartyPhone
                          ? "input error"
                          : "input"
                      }
                      type="phone"
                      placeholder="Responsible Party's phone number"
                      value={values.responsiblePartyPhone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      pattern="[2-9][0-9]{9}"
                      maxLength="10"
                    />
                    {errors.responsiblePartyPhone &&
                    touched.responsiblePartyPhone ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-responsiblePartyPhone"
                      >
                        {errors.responsiblePartyPhone}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}

                    <div className="field">
                      <label
                        className="label has-text-dark"
                        htmlFor="input-responsiblePartySex"
                      >
                        Gender
                      </label>
                      <div className="select">
                        <select
                          id="input-responsiblePartySex"
                          name="responsiblePartySex"
                          value={values.responsiblePartySex}
                          className={
                            errors.responsiblePartySex &&
                            touched.responsiblePartySex
                              ? "input error"
                              : "input"
                          }
                          onChange={(opt) =>
                            setFieldValue(
                              "responsiblePartySex",
                              opt.target.value
                            )
                          }
                          onBlur={() =>
                            setFieldTouched("responsiblePartySex", true)
                          }
                        >
                          <option defaultValue value="-">
                            --
                          </option>
                          <option value="female">Female</option>
                          <option value="male">Male</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label has-text-dark" htmlFor="input-birthday">
                        Birthday
                      </label>
                      <div className="select">
                        <select
                          id="input-responsiblePartyBirthMonth"
                          name="responsiblePartyBirthMonth"
                          value={values.responsiblePartyBirthMonth}
                          className={
                            errors.responsiblePartyBirthMonth &&
                            touched.responsiblePartyBirthMonth
                              ? "input error"
                              : "input"
                          }
                          onChange={(opt) =>
                            setFieldValue(
                              "responsiblePartyBirthMonth",
                              opt.target.value
                            )
                          }
                          onBlur={() =>
                            setFieldTouched("responsiblePartyBirthMonth", true)
                          }
                        >
                          <option defaultValue value="-">
                            MM
                          </option>
                          <option value="01">(01) January</option>
                          <option value="02">(02) February</option>
                          <option value="03">(03) March</option>
                          <option value="04">(04) April</option>
                          <option value="05">(05) May</option>
                          <option value="06">(06) June</option>
                          <option value="07">(07) July</option>
                          <option value="08">(08) August</option>
                          <option value="09">(09) September</option>
                          <option value="10">(10) October</option>
                          <option value="11">(11) November</option>
                          <option value="12">(12) December</option>
                        </select>
                      </div>
                      {" / "}
                      <div className="select">
                        <select
                          id="input-responsiblePartyBirthDay"
                          name="responsiblePartyBirthDay"
                          value={values.responsiblePartyBirthDay}
                          className={
                            errors.responsiblePartyBirthDay &&
                            touched.responsiblePartyBirthDay
                              ? "input error"
                              : "input"
                          }
                          onChange={(opt) =>
                            setFieldValue(
                              "responsiblePartyBirthDay",
                              opt.target.value
                            )
                          }
                          onBlur={() =>
                            setFieldTouched("responsiblePartyBirthDay", true)
                          }
                        >
                          <option defaultValue value="-">
                            DD
                          </option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                        </select>
                      </div>
                      {" / "}
                      <div className="select">
                        <select
                          id="input-responsiblePartyBirthYear"
                          name="responsiblePartyBirthYear"
                          value={values.responsiblePartyBirthYear}
                          className={
                            errors.responsiblePartyBirthYear &&
                            touched.responsiblePartyBirthYear
                              ? "input error"
                              : "input"
                          }
                          onChange={(opt) =>
                            setFieldValue(
                              "responsiblePartyBirthYear",
                              opt.target.value
                            )
                          }
                          onBlur={() =>
                            setFieldTouched("responsiblePartyBirthYear", true)
                          }
                        >
                          <option defaultValue value="-">
                            YYYY
                          </option>
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <label
                      className="label has-text-dark"
                      htmlFor="input-responsiblePartyAddress1"
                    >
                      Address 1
                    </label>
                    <input
                      id="input-responsiblePartyAddress1"
                      name="responsiblePartyAddress1"
                      className={
                        errors.responsiblePartyAddress1 &&
                        touched.responsiblePartyAddress1
                          ? "input error"
                          : "input"
                      }
                      type="text"
                      placeholder="Responsible Party's street address"
                      value={values.responsiblePartyAddress1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.responsiblePartyAddress1 &&
                    touched.responsiblePartyAddress1 ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-responsiblePartyAddress1"
                      >
                        {errors.responsiblePartyAddress1}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}

                    <label
                      className="label has-text-dark"
                      htmlFor="input-responsiblePartyAddress2"
                    >
                      Address 2
                    </label>
                    <input
                      id="input-responsiblePartyAddress2"
                      name="responsiblePartyAddress2"
                      className={
                        errors.responsiblePartyAddress2 &&
                        touched.responsiblePartyAddress2
                          ? "input error"
                          : "input"
                      }
                      type="text"
                      placeholder="(Optional) Responsible party's street address continued"
                      value={values.responsiblePartyAddress2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.responsiblePartyAddress2 &&
                    touched.responsiblePartyAddress2 ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-responsiblePartyAddress2"
                      >
                        {errors.responsiblePartyAddress2}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}

                    <label
                      className="label has-text-dark"
                      htmlFor="input-responsiblePartyCity"
                    >
                      City
                    </label>
                    <input
                      id="input-responsiblePartyCity"
                      name="responsiblePartyCity"
                      className={
                        errors.responsiblePartyCity &&
                        touched.responsiblePartyCity
                          ? "input error"
                          : "input"
                      }
                      type="text"
                      placeholder="Responsible party's city"
                      value={values.responsiblePartyCity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.responsiblePartyCity &&
                    touched.responsiblePartyCity ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-responsiblePartyCity"
                      >
                        {errors.responsiblePartyCity}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}

                    <label
                      className="label has-text-dark"
                      htmlFor="input-responsiblePartyState"
                    >
                      State
                    </label>
                    <div className="select">
                      <select
                        id="input-responsiblePartyState"
                        name="responsiblePartyState"
                        value={values.responsiblePartyState}
                        className={
                          errors.responsiblePartyState &&
                          touched.responsiblePartyState
                            ? "input error"
                            : "input"
                        }
                        onChange={(opt) =>
                          setFieldValue(
                            "responsiblePartyState",
                            opt.target.value
                          )
                        }
                        onBlur={() =>
                          setFieldTouched("responsiblePartyState", true)
                        }
                      >
                        <option defaultValue value="-">
                          --
                        </option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>
                    {errors.responsiblePartyState &&
                    touched.responsiblePartyState ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-responsiblePartyState"
                      >
                        {errors.responsiblePartyState}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}

                    <label
                      className="label has-text-dark"
                      htmlFor="input-responsiblePartyZipCode"
                    >
                      Zip Code
                    </label>
                    <input
                      id="input-responsiblePartyZipCode"
                      name="responsiblePartyZipCode"
                      className={
                        errors.responsiblePartyZipCode &&
                        touched.responsiblePartyZipCode
                          ? "input error"
                          : "input"
                      }
                      type="text"
                      placeholder="Responsible party's zip code"
                      value={values.responsiblePartyZipCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.responsiblePartyZipCode &&
                    touched.responsiblePartyZipCode ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-responsiblePartyZipCode"
                      >
                        {errors.responsiblePartyZipCode}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}
                  </div>
                </React.Fragment>
              ) : null}

              <br />
              <br />

              <h4 className="title is-4">Insurance Information</h4>

              <div>
                <div className="control mb-4">
                  <label className="radio">
                    <Field
                      className="mr-1"
                      type="radio"
                      name="patientInsurance"
                      value="insurance"
                    />
                    Patient Insurance
                  </label>
                  <label className="radio">
                    <Field
                      className="ml-3 mr-1"
                      type="radio"
                      name="patientInsurance"
                      value="patient-paid"
                    />
                    Self-Paid
                  </label>
                </div>

                {values.patientInsurance === "insurance" ? (
                  <React.Fragment>
                    <div className="field">
                      <label
                        className="label has-text-dark"
                        htmlFor="input-primaryInsuranceCompany"
                      >
                        Insurance Company
                      </label>
                      <input
                        id="input-primaryInsuranceCompany"
                        name="primaryInsuranceCompany"
                        className={
                          errors.primaryInsuranceCompany &&
                          touched.primaryInsuranceCompany
                            ? "input error"
                            : "input"
                        }
                        type="text"
                        placeholder="Insurance company name"
                        value={values.primaryInsuranceCompany}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.primaryInsuranceCompany &&
                        touched.primaryInsuranceCompany && (
                          <div
                            className="input-feedback help has-text-right"
                            data-testid="errors-primaryInsuranceCompany"
                          >
                            {errors.primaryInsuranceCompany}
                          </div>
                        )}
                    </div>

                    <label
                      className="label has-text-dark"
                      htmlFor="input-primaryInsurancePolicyNumber"
                    >
                      Policy Number
                    </label>
                    <input
                      id="input-primaryInsurancePolicyNumber"
                      name="primaryInsurancePolicyNumber"
                      className={
                        errors.primaryInsurancePolicyNumber &&
                        touched.primaryInsurancePolicyNumber
                          ? "input error"
                          : "input"
                      }
                      type="text"
                      placeholder="Insurance policy number"
                      value={values.primaryInsurancePolicyNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.primaryInsurancePolicyNumber &&
                    touched.primaryInsurancePolicyNumber ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-primaryInsurancePolicyNumber"
                      >
                        {errors.primaryInsurancePolicyNumber}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}

                    <label
                      className="label has-text-dark"
                      htmlFor="input-primaryInsuranceGroupNumber"
                    >
                      Group Number (enter "n/a" if none)
                    </label>
                    <input
                      id="input-primaryInsuranceGroupNumber"
                      name="primaryInsuranceGroupNumber"
                      className={
                        errors.primaryInsuranceGroupNumber &&
                        touched.primaryInsuranceGroupNumber
                          ? "input error"
                          : "input"
                      }
                      type="text"
                      placeholder="Insurance group number"
                      value={values.primaryInsuranceGroupNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.primaryInsuranceGroupNumber &&
                    touched.primaryInsuranceGroupNumber ? (
                      <div
                        className="input-feedback help has-text-right"
                        data-testid="errors-primaryInsuranceGroupNumber"
                      >
                        {errors.primaryInsuranceGroupNumber}
                      </div>
                    ) : (
                      <div
                        className="input-feedback help feedback-hidden"
                        data-testid="errors-accessCode"
                      />
                    )}

                    <div className="field">
                      <label
                        className="label has-text-dark"
                        htmlFor="input-primaryInsuranceSubscriberRelationship"
                      >
                        Subscriber Relationship
                      </label>
                      <div className="select">
                        <select
                          id="input-primaryInsuranceSubscriberRelationship"
                          name="primaryInsuranceSubscriberRelationship"
                          value={values.primaryInsuranceSubscriberRelationship}
                          className={
                            errors.primaryInsuranceSubscriberRelationship &&
                            touched.primaryInsuranceSubscriberRelationship
                              ? "input error"
                              : "input"
                          }
                          onChange={(opt) =>
                            setFieldValue(
                              "primaryInsuranceSubscriberRelationship",
                              opt.target.value
                            )
                          }
                          onBlur={() =>
                            setFieldTouched(
                              "primaryInsuranceSubscriberRelationship",
                              true
                            )
                          }
                        >
                          <option defaultValue value="self">
                            Self
                          </option>
                          <option value="child">Child</option>
                          <option value="legal-guardian">Legal Guardian</option>
                          <option value="spouse">Spouse</option>
                          <option value="none">None</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>

                    <hr />

                    <div>
                      <h5 className="title is-5">Secondary Insurance (optional)</h5>

                      <div className="field">
                        <label className="label has-text-dark" htmlFor="input-secondaryInsuranceCompanyName">
                          Insurance Company
                        </label>
                        <input
                          id="input-secondaryInsuranceCompanyName"
                          name="secondaryInsuranceCompanyName"
                          className={errors.secondaryInsuranceCompanyName && touched.secondaryInsuranceCompanyName ? "input error" : "input"}
                          type="text"
                          placeholder="Secondary insurance company name"
                          value={values.secondaryInsuranceCompanyName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.secondaryInsuranceCompanyName && touched.secondaryInsuranceCompanyName && (
                          <div className="input-feedback help has-text-right" data-testid="errors-secondaryInsuranceCompanyName">
                            {errors.secondaryInsuranceCompanyName}
                          </div>
                        )}
                      </div>

                      <label className="label has-text-dark" htmlFor="input-secondaryInsurancePolicyNumber">
                        Policy Number
                      </label>
                      <input
                        id="input-secondaryInsurancePolicyNumber"
                        name="secondaryInsurancePolicyNumber"
                        className={errors.secondaryInsurancePolicyNumber && touched.secondaryInsurancePolicyNumber ? "input error" : "input"}
                        type="text"
                        placeholder="Secondary insurance policy number"
                        value={values.secondaryInsurancePolicyNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.secondaryInsurancePolicyNumber && touched.secondaryInsurancePolicyNumber && (
                        <div className="input-feedback help has-text-right" data-testid="errors-secondaryInsurancePolicyNumber">
                          {errors.secondaryInsurancePolicyNumber}
                        </div>
                      )}

                      <label className="label has-text-dark" htmlFor="input-secondaryInsuranceGroupNumber">
                        Group Number (enter "n/a" if none)
                      </label>
                      <input
                        id="input-secondaryInsuranceGroupNumber"
                        name="secondaryInsuranceGroupNumber"
                        className={errors.secondaryInsuranceGroupNumber && touched.secondaryInsuranceGroupNumber ? "input error" : "input"}
                        type="text"
                        placeholder="Secondary insurance group number"
                        value={values.secondaryInsuranceGroupNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.secondaryInsuranceGroupNumber && touched.secondaryInsuranceGroupNumber && (
                        <div className="input-feedback help has-text-right" data-testid="errors-secondaryInsuranceGroupNumber">
                          {errors.secondaryInsuranceGroupNumber}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                ) : null}
              </div>

              <div className="mt-6">
                <input
                  type="submit"
                  className="button is-primary"
                  value="Submit"
                  disabled={isSubmitting}
                />
                {loading ? <ButtonLoading /> : null}

                <button
                  className="button has-text-danger-dark is-pulled-right"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

const NewPatientButton = () => {
  return (
    <Link to={ROUTES.PATIENT_NEW} className="button is-info is-pulled-right">
      <span className="icon is-small">
        <i className="fas fa-plus-circle" aria-hidden="true"></i>
      </span>
      <span>Create New Patient</span>
    </Link>
  );
};

export default Patient;

export { NewPatientButton, PatientList, PatientPage };
