import React from 'react';
import { Link } from 'react-router-dom';

import PendingOrders from './PendingOrders';
import CompletedOrders from './CompletedOrders';
import ViewToxicologyResults from './ViewToxicologyResults';
import BatchDownloadReports from './BatchDownloadReports';
import PatientSignatureCapture from './PatientSignatureCapture';
import CreateToxicologyOrder, { CreateToxicologyOrderButton } from './CreateToxicologyOrder';
import ToxicologyOrderDetails from './ToxicologyOrderDetails';

import { useAuth } from '../auth';
import * as ROUTES from '../../constants/routes';

const Dashboard = ({ activeTab }) => {
  // Require auth
  const authUser = useAuth({ messageType: 'info', messageText: 'Please log in' });

  return (
    <section className="section">
      <div className="container">
        {/*<div className="my-6">*/}
        {/*  <CreateToxicologyOrderButton />*/}
        {/*</div>*/}

        {/*<div className="tabs is-boxed">*/}
        {/*  <ul>*/}
        {/*    <li className={activeTab === "pending_orders" ? "is-active" : null}>*/}
        {/*      <Link to={ROUTES.DASHBOARD_PENDING_ORDERS}>*/}
        {/*        <span className="icon is-small"><i className="fas fa-clipboard-list" aria-hidden="true" /></span>*/}
        {/*        <span>Pending Orders</span>*/}
        {/*      </Link>*/}
        {/*    </li>*/}
        {/*    <li className={activeTab === "completed_orders" ? "is-active" : null}>*/}
        {/*      <Link to={ROUTES.DASHBOARD_COMPLETED_ORDERS}>*/}
        {/*        <span className="icon is-small"><i className="fas fa-clipboard-check" aria-hidden="true" /></span>*/}
        {/*        <span>Completed Orders</span>*/}
        {/*      </Link>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</div>*/}

        {activeTab === 'pending_orders' ? <PendingOrders authUser={authUser} /> : <CompletedOrders authUser={authUser} />}
      </div>
    </section>
  );
};

export default Dashboard;

export {
  CompletedOrders,
  CreateToxicologyOrder,
  PendingOrders,
  ViewToxicologyResults,
  BatchDownloadReports,
  PatientSignatureCapture,
  ToxicologyOrderDetails,
};
