import React, { Component } from 'react';
import styled from 'styled-components';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const PrintingScreen = styled(Table)`
  && {
    margin-top: 20px;
    display: none;
    @media print {
      display: block;
      width: 100%;
    }

    .MuiTableCell-root {
      border-bottom: none;
    }
  }
`;

const PresentationWrapper = styled(TableRow)`
  && {
    height: ${props => (props.lastpage === 'true' ? '100%' : '100vh')};
  }
`;

// const PresentationWrapper = styled(TableRow)`
//   && {
//     height: 100vh;
//   }
// `;

const LEProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProfileElement = styled.span`
  margin-bottom: 13px;
`;

// const Disclaimer = styled.div`
//   color: #666;
//   font-size: 13px;
//   margin-bottom: 13px;
// `;

const MailingBox = styled.div`
  margin-left: 6rem;
  width: 80vh;
`;

const PrintTemplate = ({ physician, lastpage }) => {
  return (
    <PresentationWrapper lastpage={lastpage}>
      <TableCell>&nbsp;</TableCell>
      <TableCell>
        <div>
          {/* <!-- START CENTERED WHITE CONTAINER --> */}
          <Table role='presentation'>
            {/* <!-- START MAIN CONTENT AREA --> */}
            <TableBody>
              <TableRow>
                <TableCell>
                  <Table
                    role='presentation'
                    border='0'
                    cellPadding='0'
                    cellSpacing='0'
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <p>
                            Please sign this card and mail to:<br />
                            Radeas<br />
                            Attn: Kevin<br />
                            907 Gateway Commons Circle<br />
                            Suite 100<br />
                            Wake Forest, NC 27587
                          </p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <hr />
                          <MailingBox>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                          </MailingBox>
                          <hr />
                          <b>{physician.physicianName}, {physician.physicianSuffix}</b>
                          <br />
                          <p>NPI: {physician.physicianNPI}</p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            </TableBody>
            {/* <!-- END MAIN CONTENT AREA --> */}
          </Table>

          {/* <!-- END CENTERED WHITE CONTAINER --> */}
        </div>
      </TableCell>
      <TableCell>&nbsp;</TableCell>
    </PresentationWrapper>
  );
};

class PrintSignatureCard extends Component {
  // componentDidMount() {
  //   console.log('PrintSignatureCard physicians:', this.props);
  // }

  render() {
    const { physicians } = this.props;

    const pages = [];
    let counter = 0;
    let lastPage = 'false';

    for (const physician of physicians) {
      counter++;

      if (counter === physicians.length) {
        lastPage = 'true';
      }

      const template = <PrintTemplate physician={physician} lastpage={lastPage} />;

      pages.push(template);
    }

    return (
      <PrintingScreen
        size="medium"
        role="presentation"
        border="0"
        cellPadding="0"
        cellSpacing="0"
      >
        {pages.map((page, index) => (
          <TableBody key={index}>{page}</TableBody>
        ))}
      </PrintingScreen>
    );
  }
}

export default PrintSignatureCard;
