import React, { Fragment, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Formik, Field, useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';

import Loading, { ButtonLoading } from '../Loading';

import '../../styles/form.css';
import 'react-datepicker/dist/react-datepicker.css';

export const GET_ORDERS_AND_PICKUPS = gql`
  query ToxicologyTestProfile {
    toxicologyTestProfile {
      id
      clinicCode
      startDate
      estimatedVolume
      estimatedVolumeOther
      initialSuppliesNeeded
      suppliesAutoShipped
      initialSuppliesDate
      initialSuppliesShipSpeed
      collectionDevices
      collectionDevicesOralSwabsAmount
      temporarySpecimenPickupsNeeded
      specimenPickupSchedule
    }
  }
`;

export const UPDATE_ORDERS_AND_PICKUPS = gql`
  mutation UpdateToxicologyTestProfile(
    $clinicCode: String!,
    $startDate: String,
    $estimatedVolume: String,
    $estimatedVolumeOther: String,
    $initialSuppliesNeeded: Boolean,
    $suppliesAutoShipped: Boolean,
    $initialSuppliesDate: String,
    $initialSuppliesShipSpeed: String,
    $collectionDevices: [String],
    $collectionDevicesOralSwabsAmount: String,
    $temporarySpecimenPickupsNeeded: Boolean,
    $specimenPickupSchedule: [String],
  ) {
    updateToxicologyTestProfile(
      data: {
        clinicCode: $clinicCode,
        startDate: $startDate,
        estimatedVolume: $estimatedVolume,
        estimatedVolumeOther: $estimatedVolumeOther,
        initialSuppliesNeeded: $initialSuppliesNeeded,
        suppliesAutoShipped: $suppliesAutoShipped,
        initialSuppliesDate: $initialSuppliesDate,
        initialSuppliesShipSpeed: $initialSuppliesShipSpeed,
        collectionDevices: $collectionDevices,
        collectionDevicesOralSwabsAmount: $collectionDevicesOralSwabsAmount,
        temporarySpecimenPickupsNeeded: $temporarySpecimenPickupsNeeded,
        specimenPickupSchedule: $specimenPickupSchedule,
      }
    ) {
      id
      clinicCode
      startDate
      estimatedVolume
      estimatedVolumeOther
      initialSuppliesNeeded
      suppliesAutoShipped
      initialSuppliesDate
      initialSuppliesShipSpeed
      collectionDevices
      collectionDevicesOralSwabsAmount
      temporarySpecimenPickupsNeeded
      specimenPickupSchedule
    }
  }
`;

const OrdersAndPickupRequests = ({ authUser, nextStep }) => {
  return (
    <div className="container">
      <h3 className="title is-3 ml-5">Orders & Pickup Requests</h3>
      <br />


      <OrdersAndPickupRequestsForm nextStep={nextStep} />
    </div>
  );
};

const OrdersAndPickupRequestsForm = ({ nextStep }) => {
  const [useOtherVolume, setUseOtherVolume] = useState(false);
  const [useAutoShip, setUseAutoShip] = useState(true);
  const [useOralSwabs, setUseOralSwabs] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useQuery(
    GET_ORDERS_AND_PICKUPS,
    {
      fetchPolicy: 'network-only',
      onCompleted({ toxicologyTestProfile }) {
        setData(toxicologyTestProfile);
        setLoading(false);
      },
      onError(error) {
        // TODO: show error notification
        console.log('Test Profile - Clinic Info Error:', error);

        setLoading(false);
      }
    }
  );

  const [updateClinicInfo, { loading: loadingMutation }] = useMutation(
    UPDATE_ORDERS_AND_PICKUPS,
    {
      onCompleted({ updateToxicologyTestProfile }) {
        // Move to next step
        nextStep();
      },
      onError(error) {
        // TODO: use notificaiton
        console.log('Update Orders and Pickup Requests mutation error:', error);
      }
    }
  );

  if (loading) return <Loading />;

  let startDate = new Date();
  if (data.startDate !== "") {
    try {
      startDate = new Date(data.startDate);
    } catch {
      startDate = new Date();
    }
  }

  return (
    <Formik
      initialValues={{
        startDate: startDate,
        volume: data.estimatedVolume || '',
        otherVolume: data.estimatedVolumeOther || '',
        initialSupplies: (!!data.initialSuppliesNeeded ? 'yes' : 'no') || '',
        autoShip: (!!data.suppliesAutoShipped ? 'yes' : 'no') || '',
        sendInitialSuppliesDate: data.initialSuppliesDate || new Date(),
        initialSuppliesShipingSpeed: data.initialSuppliesShipSpeed || '',
        collectionDevices: data.collectionDevices || [],
        oralSwabsAmount: data.collectionDevicesOralSwabsAmount || '',
        temporaryPickups: (!!data.temporarySpecimenPickupsNeeded ? 'yes' : 'no') || '',
        specimenPickups: data.specimenPickupSchedule || [],
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const {
          startDate,
          volume: estimatedVolume,
          otherVolume: estimatedVolumeOther,
          sendInitialSuppliesDate: initialSuppliesDate,
          initialSuppliesShipingSpeed: initialSuppliesShipSpeed,
          collectionDevices,
          oralSwabsAmount: collectionDevicesOralSwabsAmount,
          specimenPickups: specimenPickupSchedule,
        } = values;

        const initialSuppliesNeeded = values.initialSupplies === 'yes' ? true : false;
        const suppliesAutoShipped = values.autoShip === 'yes' ? true : false;
        const temporarySpecimenPickupsNeeded = values.temporaryPickups === 'yes' ? true : false;

        // Execute the mutation
        updateClinicInfo({
          variables: {
            clinicCode: data.clinicCode,
            startDate,
            estimatedVolume,
            estimatedVolumeOther,
            initialSuppliesNeeded,
            suppliesAutoShipped,
            initialSuppliesDate,
            initialSuppliesShipSpeed,
            collectionDevices,
            collectionDevicesOralSwabsAmount,
            temporarySpecimenPickupsNeeded,
            specimenPickupSchedule,
          }
        });

        // resetForm();
        setSubmitting(false);
        setUseOtherVolume(false);
        setUseAutoShip(true);
      }}
    >
      {(formProps) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = formProps;

        return (
          <div className="columns ml-4">
            <div className="column is-two-thirds">
              <form onSubmit={handleSubmit}>
                <div className="block">
                  <label className="label" htmlFor="input-startDate">
                    Anticipated Start Date <br/>
                    <span className="has-text-weight-light">(This is the day you plan to start sending samples)</span>
                  </label>
                  <DatePickerField id="input-startDate" name="startDate" />
                </div>
                <br/>

                <div className="block">
                  <label className="label" htmlFor="input-volume">
                    Estimated Volume <br/>
                    <span className="has-text-weight-light">(This helps estimate auto-ship quantities)</span>
                  </label>
                  <label className="radio">
                    <Field type="radio" name="volume" value="100" onClick={() => setUseOtherVolume(false)} />
                    <span className="ml-2">100 per month</span>
                  </label>
                  <br />
                  <label className="radio">
                    <Field type="radio" name="volume" value="200" onClick={() => setUseOtherVolume(false)} />
                    <span className="ml-2">200 per month</span>
                  </label>
                  <br />
                  <label className="radio">
                    <Field type="radio" name="volume" value="300" onClick={() => setUseOtherVolume(false)} />
                    <span className="ml-2">300 per month</span>
                  </label>
                  <br />
                  <label className="radio">
                    <Field type="radio" name="volume" value="400" onClick={() => setUseOtherVolume(false)} />
                    <span className="ml-2">400 per month</span>
                  </label>
                  <br />
                  <label className="radio">
                    <Field type="radio" name="volume" value="500" onClick={() => setUseOtherVolume(false)} />
                    <span className="ml-2">500 per month</span>
                  </label>
                  <br />
                  <label className="radio">
                    <Field type="radio" name="volume" value="other" onClick={() => setUseOtherVolume(true)} />
                    <span className="ml-2">Other volume{" "}</span>
                  </label>
                </div>
                <br/>

                {useOtherVolume ? (
                  <div className="block">
                    <label className="label" htmlFor="input-otherVolume">
                      Other Volume
                    </label>
                    <input
                      id="input-otherVolume"
                      name="otherVolume"
                      className={errors.otherVolume && touched.otherVolume ? "input error": "input"}
                      type="text"
                      placeholder="e.g. 1000"
                      value={values.otherVolume}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <br/>
                  </div>
                ) : null}

                <div className="block">
                  <label className="label" htmlFor="input-initialSupplies">
                    Do you need initial supples?
                  </label>
                  <label className="radio">
                    <Field type="radio" name="initialSupplies" value="yes" />
                    <span className="ml-2">Yes</span>
                  </label>
                  <label className="radio">
                    <Field type="radio" name="initialSupplies" value="no" className="ml-6" />
                    <span className="ml-2">No</span>
                  </label>
                </div>
                <br/>

                <div className="block">
                  <label className="label" htmlFor="input-autoShip">
                    Do you want supplies auto-shipped?
                  </label>
                  <label className="radio">
                    <Field type="radio" name="autoShip" value="yes" onClick={() => setUseAutoShip(true)} />
                    <span className="ml-2">Yes</span>
                  </label>
                  <label className="radio">
                    <Field type="radio" name="autoShip" value="no" className="ml-6" onClick={() => setUseAutoShip(false)} />
                    <span className="ml-2">No</span>
                  </label>
                </div>
                <br/>

                {!useAutoShip ? (
                  <div className="block">
                    <label className="label" htmlFor="input-sendInitialSuppliesDate">
                      If you don’t want auto-ship but still need supplies, when would you like us to ship supplies out and how fast?
                    </label>
                    <DatePickerField id="input-sendInitialSuppliesDate" name="sendInitialSuppliesDate" />
                    <br />
                    <label className="radio">
                      <Field type="radio" name="initialSuppliesShipingSpeed" value="priority-overnight" className="mt-4" />
                      <span className="ml-2">Priority Overnight - arrives by 10:30AM</span>
                    </label>
                    <br />
                    <label className="radio">
                      <Field type="radio" name="initialSuppliesShipingSpeed" value="next-day" />
                      <span className="ml-2">Next Day</span>
                    </label>
                    <br />
                    <label className="radio">
                      <Field type="radio" name="initialSuppliesShipingSpeed" value="two-day" />
                      <span className="ml-2">2 Day</span>
                    </label>
                    <br />
                    <label className="radio">
                      <Field type="radio" name="initialSuppliesShipingSpeed" value="ground" />
                      <span className="ml-2">Ground</span>
                    </label>
                    <br/>
                  </div>
                ) : null}

                <div className="block">
                  <label className="label" htmlFor="input-collectionDevices">
                    What type of collection devices do you need? <br/>
                    <span className="has-text-weight-light">(Please note: we do not auto-ship oral swabs unless it's the clinic's only collection device)</span>
                  </label>

                  {/* TODO: Show oral swabs quantity if oral-swabs in DB */}

                  {!useOralSwabs ? (
                    <Fragment>
                      <label className="checkbox">
                        <Field type="checkbox" name="collectionDevices" value="urine-cups" />
                        <span className="ml-2">Standard Urine Cups</span>
                      </label>
                      <br />
                      <label className="checkbox">
                        <Field type="checkbox" name="collectionDevices" value="vacutainer-cups" />
                        <span className="ml-2">BD Vacutainer Collection Cups</span>
                      </label>
                      <br />
                      <label className="checkbox">
                        <Field type="checkbox" name="collectionDevices" value="oral-swabs" onClick={() => setUseOralSwabs(true)} />
                        <span className="ml-2">Oral Swabs</span>
                      </label>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <label className="checkbox">
                        <Field type="checkbox" name="collectionDevices" value="oral-swabs" onClick={() => setUseOralSwabs(false)} checked />
                        <span className="ml-2">Oral Swabs</span>
                      </label>
                      <label className="label" htmlFor="input-oralSwabsAmount">
                        How many oral swabs?
                      </label>
                      <input
                        id="input-oralSwabsAmount"
                        name="oralSwabsAmount"
                        className={errors.oralSwabsAmount && touched.oralSwabsAmount ? "input error" : "input"}
                        type="text"
                        placeholder="e.g. 250"
                        value={values.oralSwabsAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Fragment>
                  )}
                </div>
                <br/>

                <div className="block">
                  <label className="label" htmlFor="input-temporaryPickups">
                    Do you need temporary specimen pickups until regular pickups is established?
                  </label>
                  <label className="radio">
                    <Field type="radio" name="temporaryPickups" value="yes" />
                    <span className="ml-2">Yes</span>
                  </label>
                  <label className="radio">
                    <Field type="radio" name="temporaryPickups" value="no" className="ml-6" />
                    <span className="ml-2">No</span>
                  </label>
                </div>
                <br/>

                <div className="block">
                  <label className="label" htmlFor="input-specimenPickups">
                    Please provide Radeas with a two-hour time block along with weekdays for specimen pickups <span className="is-size-7 has-text-weight-bold">(If left blank, no pickups will be scheduled)</span>
                  </label>
                  <br/>
                  <label className="label">
                    Monday:
                  </label>
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="monday-12-2" />
                    <span className="ml-2">12:00-2:00 PM</span>
                  </label>
                  <br />
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="monday-2-4" />
                    <span className="ml-2">2:00 - 4:00 PM</span>
                  </label>
                  <br />
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="monday-3-5" />
                    <span className="ml-2">3:00 - 5:00 PM</span>
                  </label>

                  <label className="label">
                    Tuesday:
                  </label>
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="tuesday-12-2" />
                    <span className="ml-2">12:00-2:00 PM</span>
                  </label>
                  <br />
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="tuesday-2-4" />
                    <span className="ml-2">2:00 - 4:00 PM</span>
                  </label>
                  <br />
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="tuesday-3-5" />
                    <span className="ml-2">3:00 - 5:00 PM</span>
                  </label>

                  <label className="label">
                    Wednesday:
                  </label>
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="wednesday-12-2" />
                    <span className="ml-2">12:00-2:00 PM</span>
                  </label>
                  <br />
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="wednesday-2-4" />
                    <span className="ml-2">2:00 - 4:00 PM</span>
                  </label>
                  <br />
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="wednesday-3-5" />
                    <span className="ml-2">3:00 - 5:00 PM</span>
                  </label>

                  <label className="label">
                    Thursday:
                  </label>
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="thursday-12-2" />
                    <span className="ml-2">12:00-2:00 PM</span>
                  </label>
                  <br />
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="thursday-2-4" />
                    <span className="ml-2">2:00 - 4:00 PM</span>
                  </label>
                  <br />
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="thursday-3-5" />
                    <span className="ml-2">3:00 - 5:00 PM</span>
                  </label>

                  <label className="label">
                    Friday:
                  </label>
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="friday-12-2" />
                    <span className="ml-2">12:00-2:00 PM</span>
                  </label>
                  <br />
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="friday-2-4" />
                    <span className="ml-2">2:00 - 4:00 PM</span>
                  </label>
                  <br />
                  <label className="checkbox">
                    <Field className="ml-5" type="checkbox" name="specimenPickups" value="friday-3-5" />
                    <span className="ml-2">3:00 - 5:00 PM</span>
                  </label>

                  <p className="is-size-6 mt-6">
                    (Please be advised that these two-hour time windows are estimated times for pickups and may vary. We will do our best to accommodate your requested pickup times. )
                  </p>
                </div>

                <div className="mt-6">
                  <input
                    type="submit"
                    className="button is-primary"
                    value="Save and Continue"
                    disabled={isSubmitting}
                  />
                  {loading ? <ButtonLoading /> : null}
                </div>
              </form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => setFieldValue(field.name, val)}
    />
  );
};

export default OrdersAndPickupRequests;
