import React, { useEffect, useRef, useState } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { useHistory, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import download from 'downloadjs';
import _ from 'lodash';
import dateFormat from 'dateformat';

import DownloadOrderPDFButton from './DownloadOrderPDFButton';
import { createNotification } from '../Notification';
import { GET_DRUG_METABOLITES } from '../../constants/gql';
import DrugReport from '../../drug-report';
import Loading, { ButtonLoading } from '../Loading';
import { TOXICOLOGY_REPORT_FLAGS } from '../../constants/flags';
import * as ROUTES from '../../constants/routes';

// const GENERATE_REPORT_PDF_URL = 'https://us-central1-radeaslis-dev.cloudfunctions.net/create-report-python';
// const REFRESH_DELAY = 1500;
const REFRESH_DELAY = 3000;

export const GET_TOXICOLOGY_COMPLETE_ORDER = gql`
  query GetToxicologyOrder(
    $id: String!
  ) {
    getToxicologyOrder(
      id: $id
    ) {
      id
      clinicCode
      clinicName
      providerName
      patientId
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone
      specimenId
      specimenType
      clinicalPurpose
      medicationList
      medicationListTags
      medicationConsistentReview
      medicationInconsistentNotPrescribedReview
      medicationInconsistentRxNotFoundReview
      radeasAnalyst0
      radeasAnalyst1
      createdDate
      resultsUploadedDate
      specimenPH
      specimenSpecificGravity
      specimenCreatinine
      specimenRunDate
      labUploadedDate
      hasRetest
      retestResultId
      retestOriginalOrderId
      urineValidationReporting
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        pos
        neg
        scrn
        result
        cutoff
        c_i
        flag
      }
      resultsReview {
        drugName
        drugClass
        drugBrand
        scrn
        result
        cutoff
        c_i
        flag
      }
      etgTests
      etgResultsReview {
        drugName
        drugClass
        drugBrand
        result
        posNeg
        cutoff
        c_i
        flag
      }
      testStripsResultsReview {
        drugName
        drugClass
        drugBrand
        scrnAvailable
        isActive
        scrn
        cutoffs {
          urineDefault
          urineCustom
          oralFluidDefault
          oralFluidCustom
        }
        c_i
        flag
      }
      customTestResultsReview {
        drugName
        drugClass
        drugBrand
        result
        posNeg
        cutoff
        c_i
        flag
      }
      additionalFlags
    }
  }
`;

const ViewToxicologyResults = () => {
  const { id } = useParams();
  const history = useHistory();

  const [drugReport, setDrugReport] = useState(null);
  const [orderDetails, setOrderDetails] = useState({});
  const [customTestResults, setCustomTestResults] = useState([]);
  const [medicationComparisonSummary, setMedicationComparisonSummary] = useState({});
  const [filter, setFilter] = useState('');
  const [generatingReport, setGeneratingReport] = useState(false);
  const [additionalFlags, setAdditionalFlags] = useState([]);

  const [loading, setLoading] = useState(true);

  // TODO: page reload hack
  const [init, setInit] = useState(false);
  const [timeOut, setTimeOut] = useState(null);

  const filterRef = useRef(null);

  // Reload page if it doesn't load in 5 seconds
  // TODO: Fix this. This is a hack to fix the page not loading issue
  // that happens when someone navigates away from this page back to the
  // pending orders list, and then clicks on a new specimen to enter results.
  // Possible graphql cache issue?
  useEffect(() => {
    // Set timeout of 5 seconds to force a page reload.
    // Gets canceled if "init" variable is set to true.
    const t = setTimeout(() => {
      window.location.reload();
    }, REFRESH_DELAY);

    setTimeOut(t);

    return () => {
      // Clear timeout interval on component unmount
      clearTimeout(timeOut);
    };
  }, []);

  // Cancel reload if page loads properly
  useEffect(() => {
    if (init) {
      clearTimeout(timeOut);
    }
  }, [init]);

  useEffect(() => {
    filterRef.current && filterRef.current.focus();
  }, []);

  // Get the order details
  useQuery(
    GET_TOXICOLOGY_COMPLETE_ORDER,
    {
      fetchPolicy: 'network-only',
      variables: { id },
      onCompleted({ getToxicologyOrder }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Query getToxicologyOrder');
          console.log({ getToxicologyOrder });
        }

        // Format specimen run date
        // const [year, month, day] = getToxicologyOrder.specimenRunDate.split('T')[0].split('-');
        let year, month, day;
        if (getToxicologyOrder.specimenRunDate) {
          [year, month, day] = getToxicologyOrder.specimenRunDate.split('T')[0].split('-');
        } else {
          let runDate = new Date(parseInt(getToxicologyOrder.labUploadedDate ? getToxicologyOrder.labUploadedDate : getToxicologyOrder.resultsUploadedDate));
          runDate = dateFormat(runDate, "mm/dd/yyyy");
          [month, day, year] = runDate.split('/');
          // [month, day, year] = new Date().toLocaleDateString().split('/');
        }

        const data = _.cloneDeep(getToxicologyOrder);
        data.specimenRunDate = `${month}/${day}/${year}`;
        data.results = data.resultsReview;

        setOrderDetails(data);
        setCustomTestResults(getToxicologyOrder.customTestResultsReview);
        setMedicationComparisonSummary({
          medicationConsistent: getToxicologyOrder.medicationConsistentReview,
          medicationInconsistentNotPrescribed: getToxicologyOrder.medicationInconsistentNotPrescribedReview,
          medicationInconsistentRxNotFound: getToxicologyOrder.medicationInconsistentRxNotFoundReview,
        });

        if ('additionalFlags' in getToxicologyOrder) {
          setAdditionalFlags(getToxicologyOrder.additionalFlags);
        }

        getDrugMetabolitesQuery();
      },
      onError(error) {
        console.log('Get toxicology results error:', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      }
    }
  );

  // Get drug metabolites list
  const [getDrugMetabolitesQuery] = useLazyQuery(
    GET_DRUG_METABOLITES,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ drugMetabolites }) {
        const drugReport = new DrugReport({
          drugMetabolites,
          // medicationList: orderDetails.medicationList && DrugReport.splitDrugList(orderDetails.medicationList),
          medicationList: orderDetails.medicationListTags,
          toxicologyTests: orderDetails.toxicologyTests,
        });

        setDrugReport(drugReport);

        // TODO: Part of page reload hack
        setInit(true);

        setLoading(false);
      },
      onError(error) {
        console.log('Get drug metabolites:', error);
        createNotification('danger', 'Sorry, could not retrieve drug metabolites.');
      }
    }
  );

  const generateTable = () => {
    if (!orderDetails.results) return null;

    if (filter === '') {
      return generateRows(orderDetails.results);
    } else {
      // Filter the results
      const filtered = orderDetails.results.filter((test, i) => {
        // Skip "All" and "Urine Validation"
        if (test.drugName === 'All (G0483; 80307)' || test.drugName === 'Urine Validation Testing') {
          return false;
        }

        return (
          test.drugName.toLowerCase().includes(filter.toLowerCase())
        );
      });

      return generateRows(filtered);
    }
  };

  const generateRows = (rows) => {
    return (
      rows.filter((test) => (
        !(test.drugName === 'All (G0483; 80307)' || test.drugName === 'Urine Validation Testing')
      )).map((test, i) => {
        const result = drugReport.evaluate({
          drugName: test.drugName,
          cutoff: test.cutoff,
          value: test.result,
        });

        // if (test.c_i !== result.cI) {
        //   mergeResults(test, {
        //     c_i: result.cI,
        //     flag: result.flags.join(', '),
        //     posNeg: result.posNeg,
        //   });
        // }

        return (
          <tr
            key={i}
            className={test.result !== "ND" ? "has-text-danger" : null}
            style={{ cursor: 'default' }}
          >
            <td><span className="has-text-weight-semibold has-text-dark">{test.drugName}</span></td>
            <td className="has-text-dark">{test.drugBrand}</td>
            <td className="has-text-dark">
              {test.scrn === '+' || test.scrn === '-' ?
                (<React.Fragment>
                    <span className="ml-2">{test.scrn}</span>
                  </React.Fragment>
                ) :
                null}
            </td>
            <td className="has-text-dark">{test.cutoff}</td>
            <td className="has-text-dark">{result.posNeg}</td>
            <td className="has-text-dark">{result.cI}</td>
            <td>
              <input
                name="result"
                type="text"
                className="input has-text-dark"
                value={formatResult(test.result, test.drugName, orderDetails.specimenType, 'PAIN')}
                style={{ cursor: 'default' }}
                disabled
              />
            </td>
            <td className="has-text-dark">
              {/* <input */}
              {/*   name="flag" */}
              {/*   type="text" */}
              {/*   className="input" */}
              {/*   value={result.flags.join(', ')} */}
              {/*   style={{ cursor: 'default' }} */}
              {/*   disabled */}
              {/* /> */}
              {test.flag}
            </td>
          </tr>
        );
      })
    );
  };

  const generateTestStrips = () => {
    if (orderDetails.testStripsResultsReview?.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Test Strips</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Drug Name</th>
              <th>Drug Class</th>
              <th>Drug Brand</th>
              <th>Scrn</th>
              <th>Cutoff (ng/ml)</th>
              <th>C/I</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateTestStripsRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateTestStripsRows = () => {
    return (
      orderDetails.testStripsResultsReview.map((test, i) => {
        const cutoff = test.cutoffs.urineCustom ? test.cutoffs.urineCustom : test.cutoffs.urineDefault;

        return (
          <tr key={i}>
            <td><span className="has-text-weight-semibold has-text-dark">{test.drugName}</span></td>
            <td className="has-text-dark">{test.drugClass}</td>
            <td className="has-text-dark">{test.drugBrand}</td>
            <td className="has-text-dark">
              <span className="ml-2">{test.scrn ? '+' : '-'}</span>
            </td>
            <td className="has-text-dark">{cutoff}</td>
            <td className="has-text-dark">{test.c_i ? test.c_i : ""}</td>
            <td className="has-text-dark">{test.flag ? test.flag : ""}</td>
          </tr>
        );
      })
    );
  };

  const generateEtg = () => {
    if (orderDetails.etgResultsReview?.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Alcohol(s)</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Test Performed</th>
              <th>Name</th>
              <th>Cutoff (ng/ml)</th>
              <th>POS/NEG</th>
              <th>C/I</th>
              <th>Result</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateEtgRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateEtgRows = () => {
    return (
      orderDetails.etgResultsReview.map((row, i) => (
        <tr key={i}>
          <td className="has-text-dark">{row.drugName}</td>
          <td className="has-text-dark">{row.drugBrand}</td>
          <td className="has-text-dark">{row.cutoff}</td>
          <td className="has-text-dark">{row.posNeg}</td>
          <td className="has-text-dark">{row.c_i}</td>
          <td>
            <input
              name="result"
              type="text"
              className="input has-text-dark"
              value={formatResult(row.result, row.drugName, orderDetails.specimenType, 'ETG')}
              onChange={null}
              style={{ cursor: 'default' }}
              disabled
            />
          </td>
          <td className="has-text-dark">
            {row.flag}
          </td>
        </tr>
      ))
    );
  };

  const generateCustomTests = () => {
    if (customTestResults.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Additional Tests</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Drug Name</th>
              <th>Drug Class</th>
              <th>Drug Brand</th>
              <th>Cutoff (ng/ml)</th>
              <th>POS/NEG</th>
              <th>C/I</th>
              <th>Result</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateCustomTestRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateCustomTestRows = () => {
    return (
      customTestResults.map((row, i) => (
        <tr key={i}>
          <td>
            <input
              name="drugName"
              type="text"
              className="input has-text-dark"
              value={row.drugName}
              onChange={null}
              style={{ cursor: 'default' }}
              disabled
            />
          </td>
          <td>
            <input
              name="drugClass"
              type="text"
              className="input has-text-dark"
              value={row.drugClass}
              onChange={null}
              style={{ cursor: 'default' }}
              disabled
            />
          </td>
          <td>
            <input
              name="drugBrand"
              type="text"
              className="input has-text-dark"
              value={row.drugBrand}
              onChange={null}
              style={{ cursor: 'default' }}
              disabled
            />
          </td>
          <td>
            <input
              name="cutoff"
              type="text"
              className="input has-text-dark"
              value={row.cutoff}
              onChange={null}
              style={{ cursor: 'default' }}
              disabled
            />
          </td>
          <td>
            <input
              name="posNeg"
              type="text"
              className="input has-text-dark"
              value={row.posNeg}
              onChange={null}
              style={{ cursor: 'default' }}
              disabled
            />
          </td>
          <td>
            <input
              name="c_i"
              type="text"
              className="input has-text-dark"
              value={row.c_i}
              onChange={null}
              style={{ cursor: 'default' }}
              disabled
            />
          </td>
          <td>
            <input
              name="result"
              type="text"
              className="input has-text-dark"
              value={formatResult(row.result, row.drugName, orderDetails.specimenType)}
              onChange={null}
              style={{ cursor: 'default' }}
              disabled
            />
          </td>
          <td className="has-text-dark">
            {row.flag}
          </td>
        </tr>
      ))
    );
  };

//   const mergeResults = (test, result) => {
//     const newToxicologyTests = _.cloneDeep(orderDetails.toxicologyTests);
//     const newVal = _.cloneDeep(test);
//     const index = _.findIndex(newToxicologyTests, { drugName: test.drugName });
//
//     newToxicologyTests.splice(index, 1, { ...newVal, ...result });
//
//     setOrderDetails({ ...orderDetails, results: newToxicologyTests });
//   };

  const formatResult = (result, drugName, specimenType, panel='PAIN') => {
    let r;
    const fent = ['FENTANYL', 'NORFENTANYL'];

    if (result.trim()[0] === '>') {
      r = parseFloat(result.split('>')[1].trim());
    } else {
      r = parseFloat(result.trim());
    }

    // Check EtG
    if (panel === 'ETG') {
      if (r >= 10000) {
        return '> 10000';
      }

      return result.trim();
    }

    // Urine
    if (specimenType.toUpperCase() === 'URINE') {
      // Fentanyl/Norfentanyl
      if (fent.includes(drugName.toUpperCase())) {
        if (r > 200) {
          return '> 200';
        } else if (r < 0.2) {
          return '< 0.2';
        }
      }

      if (r > 2000) {
        return '> 2000';
      } else if (r < 2) {
        return '< 2';
      }

    // Oral Fluid
    } else if (specimenType.toUpperCase() === 'ORAL FLUID') {
      // Fentanyl/Norfentanyl
      if (fent.includes(drugName.toUpperCase())) {
        if (r > 600) {
          return '> 600';
        } else if (r < 0.6) {
          return '< 0.6';
        }
      }

      if (r > 6000) {
        return '> 6000';
      } else if (r < 6) {
        return '< 6';
      }
    }

    // if (r > 2000) {
    //   return '> 2000';
    // } else if (r < 2) {
    //   return '< 2';
    // }

    return result.trim();
  };

  const generateReportPdf = async () => {
    let createdDate = new Date(Number(orderDetails.createdDate));
    createdDate = dateFormat(createdDate, "mm/dd/yyyy");

    const data = {
      patientFirstName: orderDetails.patientFirstName,
      patientLastName: orderDetails.patientLastName,
      patientId: orderDetails.patientId && orderDetails.patientId.length > 0 ? orderDetails.patientId : 'Not Provided',
      patientBirthDate: `${orderDetails.patientBirthMonth}/${orderDetails.patientBirthDay}/${orderDetails.patientBirthYear}`,
      patientEmail: orderDetails.patientEmail,
      patientPhone: orderDetails.patientPhone,
      clinicCode: orderDetails.clinicCode,
      clinicName: orderDetails.clinicName,
      providerName: orderDetails.providerName,
      sampleAcquisitionDate: createdDate,
      specimenId: orderDetails.specimenId,
      specimenType: orderDetails.specimenType[0].toUpperCase() + orderDetails.specimenType.substring(1),
      analystId1: orderDetails.radeasAnalyst0,
      analystId2: orderDetails.radeasAnalyst1,
      runDate: orderDetails.specimenRunDate,
      medicationList: orderDetails.medicationListTags,
      medicationComparisonSummary: {
        'Consistent': medicationComparisonSummary.medicationConsistent,
        'Inconsistent-NotPrescribed': medicationComparisonSummary.medicationInconsistentNotPrescribed,
        'Inconsistent-RxNotFound': medicationComparisonSummary.medicationInconsistentRxNotFound,
      },
      toxicologyTestResults: orderDetails.results
        .filter(({ drugName }) => (drugName === 'All (G0483; 80307)' || drugName === 'Urine Validation Testing' ? false : true))
        .map(({
          drugName,
          drugClass,
          drugBrand,
          scrn,
          result,
          cutoff,
          c_i,
          flag,
        }) => ({
          drugName,
          drugClass,
          drugBrand,
          scrn,
          result: formatResult(result, drugName, orderDetails.specimenType, 'PAIN'),
          cutoff,
          posNeg: drugReport.evaluate({ drugName, cutoff, value: result }).posNeg,
          cI: c_i,
          flag,
        })
      ),
    };

    // Add EtG to data if necessary
    if (orderDetails.etgTests.length > 0) {
      const results = orderDetails.etgResultsReview.map(({
        drugName,
          drugClass,
          drugBrand,
          result,
          cutoff,
          posNeg,
          c_i,
          flag,
      }) => ({
        drugName,
        drugClass,
        drugBrand,
        result: formatResult(result, drugName, orderDetails.specimenType, 'ETG'),
        cutoff,
        posNeg,
        cI: c_i,
        flag,
        scrn: null,
      }));

      data.toxicologyTestResults.push(...results);
    }

    // Add test strips if necessary
    if (orderDetails.testStripsResultsReview.length > 0) {
      const strips = orderDetails.testStripsResultsReview.map(({
        drugName,
        drugClass,
        drugBrand,
        scrn,
        cutoffs,
      }) => {
        const cutoff = cutoffs.urineCustom ? cutoffs.urineCustom : cutoffs.urineDefault;

        return {
          drugName,
          drugClass,
          drugBrand,
          scrn: scrn ? '+' : '-',
          result: 'N/A',
          cutoff: `${cutoff}`,
          posNeg: scrn ? 'POS' : 'NEG',
          cI: 'C',
          flag: '',
        };
      });

      data.toxicologyTestResults.push(...strips);
    }

    // Add custom tests if necessary
    if (customTestResults.length > 0) {
      const cResults = customTestResults.map(({
        drugName,
        drugClass,
        drugBrand,
        result,
        cutoff,
        posNeg,
        c_i,
        flag,
      }) => ({
        drugName,
        drugClass,
        drugBrand,
        result: formatResult(result, drugName, orderDetails.specimenType),
        cutoff,
        posNeg,
        cI: c_i,
        flag,
        scrn: null,
      }));

      data.toxicologyTestResults.push(...cResults);
    }

    // Add additional flags if necessary
    if (additionalFlags.length > 0) {
      data.additionalFlags = additionalFlags;
    }

    // Add PH, specific gravity, and creatinine if urine validation test reporting is requested
    const urineValidation = orderDetails.toxicologyTests
      .filter(({ drugName }) => (drugName.includes('Urine Validation')));

    if ((urineValidation.length !== 0 && urineValidation[0].pos) || (orderDetails.urineValidationReporting)) {
      data['ph'] = orderDetails.specimenPH;
      data['sg'] = orderDetails.specimenSpecificGravity;
      data['cr'] = orderDetails.specimenCreatinine;
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('Generate Report data:', data);
    }

    try {
      // Download the PDF report
      const response = await axios.post(process.env.REACT_APP_GENERATE_REPORT_PDF_URL, data);
      const contentType = response.headers['content-type'];
      download(response.data, `${orderDetails.specimenId}.pdf`, contentType);
    } catch (error) {
      console.log('Report Generation', error);
      createNotification('danger', 'Error generating report.');
    }

    setGeneratingReport(false);
  };

  if (loading) return <Loading />;

  return (
    <section className="section">
      <div className="container">
        {orderDetails?.hasRetest && orderDetails?.retestResultId?.length > 0 && (
          <div className="is-pulled-left">
            <Link
              to={ROUTES.DASHBOARD_ORDER_RESULTS.replace(':id', orderDetails.retestResultId)}
              className="button is-primary"
              disabled={orderDetails?.retestResultId === "PENDING"}
              data-tooltip={orderDetails?.retestResultId === "PENDING" ? "Re-test ordered, results pending..." : null}
            >
              View Re-test Result
            </Link>
          </div>
        )}
        {orderDetails?.specimenId.slice(-3) === '-RT' && orderDetails?.retestOriginalOrderId?.length > 0 && (
          <div className="is-pulled-left">
            <Link
              to={ROUTES.DASHBOARD_ORDER_RESULTS.replace(':id', orderDetails.retestOriginalOrderId)}
              className="button is-primary"
              data-tooltip="This is a complete re-test of a previous order"
            >
              View Original Result
            </Link>
          </div>
        )}

        <div className="is-pulled-left">
          <DownloadOrderPDFButton specimenId={orderDetails.specimenId} text="Download Requisition Form" />
        </div>

        <div className="is-pulled-right">
          {generatingReport ? <ButtonLoading /> : null}

          <button
            className="button is-info ml-6"
            disabled={generatingReport}
            onClick={() => {
              setGeneratingReport(true);
              generateReportPdf();
            }}
          >
            Download Report
          </button>

          <button
            className="button is-info ml-3"
            onClick={() => {
              // history.push(ROUTES.DASHBOARD_COMPLETED_ORDERS);
              history.goBack();
            }}
          >
            Back
          </button>
        </div>

        <br />
        <br />
        <br />

        <h3 className="title is-3">Order Details</h3>

        <div>
          <div style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-hoverable">
              <thead>
              <tr>
                <th>Patient Name</th>
                <th>Patient ID</th>
                <th>Clinic Code</th>
                <th>Specimen ID</th>
                <th>Specimen Type</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td className="has-text-dark">{orderDetails.patientLastName}, {orderDetails.patientFirstName}</td>
                <td className="has-text-dark">{orderDetails.patientId}</td>
                <td className="has-text-dark">{orderDetails.clinicCode}</td>
                <td className="has-text-dark">{orderDetails.specimenId}</td>
                <td className="has-text-dark">{orderDetails.specimenType}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <hr />

          <div className="columns my-3">
            <div className="column has-text-dark">
              {orderDetails.specimenPH && `Specimen PH: ${orderDetails.specimenPH}`}
            </div>

            <div className="column has-text-dark">
              {orderDetails.specimenSpecificGravity && `Specimen Specific Gravity: ${orderDetails.specimenSpecificGravity}`}
            </div>

            <div className="column has-text-dark">
              {orderDetails.specimenCreatinine && `Specimen Creatinine: ${orderDetails.specimenCreatinine}`}
            </div>
          </div>

          <div className="my-3">
            <p className="has-text-dark">
              <span className="has-text-weight-bold">
                Clinical Purpose:{" "}
              </span>
              {orderDetails.clinicalPurpose ? orderDetails.clinicalPurpose.replace('::', ' ') : "Not specified"}
            </p>
          </div>

          {orderDetails.medicationListTags.join('').length > 0 && (
            <>
              <div className="my-5">
                <p className="has-text-dark">
                  <span className="has-text-weight-bold">Medication List:</span>
                  {" "}
                  {orderDetails.medicationListTags.join(', ')}
                </p>
              </div>

              <div className="my-5">
                <div className="columns">
                  <div className="column is-one-third">
                    <div className="field">
                      <label className="label has-text-dark" htmlFor="input-medicationConsistent">
                        Consistent
                      </label>
                      <textarea
                        id="input-medicationConsistent"
                        name="medicationConsistent"
                        className="textarea has-text-dark"
                        placeholder=""
                        value={medicationComparisonSummary.medicationConsistent.join(', ')}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="column is-one-third">
                    <div className="field">
                      <label className="label has-text-dark" htmlFor="input-medicationInconsistentNotPrescribed">
                        Inconsistent - Not Prescribed
                      </label>
                      <textarea
                        id="input-medicationInconsistentNotPrescribed"
                        name="medicationInconsistentNotPrescribed"
                        className="textarea has-text-dark"
                        placeholder=""
                        value={medicationComparisonSummary.medicationInconsistentNotPrescribed.join(', ')}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="column is-one-third">
                    <div className="field">
                      <label className="label has-text-dark" htmlFor="input-medicationInconsistentRxNotFound">
                        Inconsistent - RX Not Found
                      </label>
                      <textarea
                        id="input-medicationInconsistentRxNotFound"
                        name="medicationInconsistentRxNotFound"
                        className="textarea has-text-dark"
                        placeholder=""
                        value={medicationComparisonSummary.medicationInconsistentRxNotFound.join(', ')}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <hr />
        {/* <br /> */}

        <h4 className="title is-4">Toxicology</h4>

        <div className="my-5">
          <div className="columns">
            <div className="column is-one-third">
              <div className="field is-grouped">
                <div className="control is-expanded">
                  <input
                    ref={filterRef}
                    name="filter"
                    type="text"
                    className="input"
                    placeholder="Filter by drug name"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </div>
                <div className="control">
                  <button
                    className="button is-info"
                    onClick={() => setFilter('')}
                    disabled={filter === '' ? true : false}
                  >
                    <span className="icon is-small mr-2">
                      <i className="fas fa-times-circle" />
                    </span>
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-hoverable">
            <thead>
            <tr>
              <th>Drug Name</th>
              <th>Drug Brand</th>
              <th>Scrn</th>
              <th>Cutoff (ng/ml)</th>
              <th>POS/NEG</th>
              <th>C/I</th>
              <th>Result</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
            {generateTable()}
            </tbody>
          </table>
        </div>

        {generateEtg()}
        {generateTestStrips()}
        {generateCustomTests()}

        <hr />

        <div className="my-5">
          <h4 className="title is-4">Additional Flags</h4>

          <div className="content">
            <ul>
              {additionalFlags.map((row) => (
                <li key={row} className="has-text-dark">
                  {row}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewToxicologyResults;
