import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import ChangePassword from './ChangePassword';
import { SignInForm } from './SignIn';
import { SignUpLink } from './SignUp';
import { ForgotPasswordLink } from './ForgotPassword'

import { authUserVar } from '../../cache';
import { createNotification } from '../Notification';
import { AUTH_USER_QUERY } from '../../constants/gql';
import { decodeJwt } from '../../utils';
import * as ROUTES from '../../constants/routes';

// Custom auth hook
const useAuth = ({ messageType, messageText }, redirect = true) => {
  // Get router history object
  const history = useHistory();

  // Get auth user
  const { data } = useQuery(AUTH_USER_QUERY);

  // Look for auth token in localStorage (TODO: change to cookie)
  let authUser = localStorage.getItem('token');
  if (!authUser) {
    // Get authUser from graphql data
    ({ authUser } = data);
  }

  // Require auth
  if (!authUser && redirect) {
    // Create notification
    if (process.env.REACT_APP_ENV === 'development') {
      console.log(`Setting notification - type: ${messageType}, text: ${messageText}`);
    }

    createNotification(messageType, messageText);

    // Redirect to login page
    history.push(ROUTES.SIGN_IN);
  }

  const { authUserId, roles, exp, iat } = decodeJwt(authUser);

  const current = Math.floor(Date.now() / 1000);

  // Check if token is expired
  if (current - exp > 0) {
    localStorage.removeItem('token');
    authUserVar(null);

    createNotification(messageType, messageText);

    // Redirect to login page
    history.push(ROUTES.SIGN_IN);
  }

  return authUser;
};

export {
  useAuth,
  ChangePassword,
  ForgotPasswordLink,
  SignInForm,
  SignUpLink,
};
