import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';

import Loading from '../Loading';
import { NewPatientButton } from '../Patient';
import { createNotification } from '../Notification';

export const GET_CLINIC_CODE = gql`
  query ClinicCode {
    meClinicUser {
      clinicCode
    }
  }
`;

export const PATIENTS_BY_CLINIC = gql`
  query PatientsByClinic(
    $clinicCode: String!
  ) {
    patientsByClinic(
      clinicCode: $clinicCode
    ) {
      id
      patientId
      firstName
      lastName
      birthMonth
      birthDay
      birthYear
      email
      phone
    }
  }
`;

const SelectPatient = ({ nextStep, selectPatient }) => {
  const [clinicCode, setClinicCode] = useState('');
  const [patients, setPatients] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(true);

  // Get clinic code
  useQuery(
    GET_CLINIC_CODE,
    {
      fetchPolicy: 'network-only',
      onCompleted({ meClinicUser }) {
        setClinicCode(meClinicUser.clinicCode);
        getPatients({ variables: { clinicCode: meClinicUser.clinicCode } });
      },
      onError(error) {
        createNotification('danger', 'Sorry, unable to retrieve patients.');
        console.log('Error getting clinic code:', error);
      }
    }
  );

  const [getPatients, { loading: loadingPatients, data: patientsData }] = useLazyQuery(
    PATIENTS_BY_CLINIC,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    const patientsByClinic = patientsData?.patientsByClinic;

    if (patientsByClinic) {
      setPatients(patientsByClinic);
      setLoading(false);
    }
  }, [patientsData]);

  const generatePatients = () => {
    if (filter.length === 0) {
      return generateRows(patients);
    } else {
      const filtered = patients.filter((patient) => {
        const birthday = `${patient.birthMonth}/${patient.birthDay}/${patient.birthYear}`;
        const birthdayNoZeros = `${parseInt(patient.birthMonth)}/${parseInt(patient.birthDay)}/${parseInt(patient.birthYear)}`;

        return (
          patient.patientId.toUpperCase().includes(filter.toUpperCase()) ||
          patient.firstName.toUpperCase().includes(filter.toUpperCase()) ||
          patient.lastName.toUpperCase().includes(filter.toUpperCase()) ||
          patient.email.toUpperCase().includes(filter.toUpperCase()) ||
          patient.phone.includes(filter) ||
          patient.phone.replace(/-/g, '').includes(filter) ||
          birthday.includes(filter) ||
          birthdayNoZeros.includes(filter)
        );
      });

      return generateRows(filtered);
    }
  };

  const generateRows = (p) => {
    return (
      p.map((patient) => (
        <tr key={patient.id}>
          <td className="has-text-dark" style={{ fontSize: "0.9rem" }}>{patient.lastName}, {patient.firstName}</td>
          <td className="has-text-dark" style={{ fontSize: "0.8rem" }}>{patient.patientId}</td>
          <td className="has-text-dark" style={{ fontSize: "0.8rem" }}>{patient.birthMonth}/{patient.birthDay}/{patient.birthYear}</td>
          <td className="has-text-dark" style={{ fontSize: "0.8rem" }}>{patient.email}</td>
          <td className="has-text-dark" style={{ fontSize: "0.8rem" }}>{patient.phone}</td>
          <td style={{textAlign: "right"}}>
            <button
              className="button has-text-info is-small"
              onClick={() => {
                selectPatient({ ...patient });
                nextStep();
              }}
            >
              Select
            </button>
          </td>
        </tr>
      ))
    );
  };

  // Show loading spinner
  if (loading) return <Loading />;

  return (
    <React.Fragment>
      <div className="page">
        <NewPatientButton/>
        <h3 className="title is-4 is-inline-block mt-1">New Toxicology Order</h3><br/>
        <span className="subtitle">Step 1: Select an existing patient or create a new patient</span>


        {/*      <div className="mb-5">
        <p>Note: if the a newly created patient does not appear below, {" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.location.reload();
            }}
          >
            click here.
          </a>
        </p>
      </div>*/}

        <div className="my-5">
          <div className="columns">
            <div className="column is-one-third">
              <div className="field is-grouped">
                <div className="control is-expanded">
                  <input
                    name="filter"
                    type="text"
                    className="input"
                    placeholder="Search patients"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </div>
                <div className="control">
                  <button
                    className="button is-info"
                    onClick={() => setFilter('')}
                    disabled={filter === '' ? true : false}
                  >
                    <span className="icon is-small mr-2">
                      <i className="fas fa-times-circle" />
                    </span>
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6" style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr>
                <th>Patient Name</th>
                <th>Patient ID</th>
                <th>Birthday</th>
                <th>Email</th>
                <th>Phone</th>
                <th />
              </tr>
            </thead>

            <tbody>
            {generatePatients()}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SelectPatient;
