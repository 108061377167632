import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import DownloadOrderPDFButton from './DownloadOrderPDFButton';
import Loading from '../Loading';

import { createNotification } from '../Notification';
import { ME_CLINIC_USER } from '../Admin/ClinicUsersList';
import { useAuth } from '../auth';
import { getPrinters, printLabel } from '../../utils';
import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/status';

export const PENDING_TOXICOLOGY_ORDERS = gql`
  query PendingToxicologyOrders(
    $clinicCode: String!
  ) {
    pendingToxicologyOrders(
      clinicCode: $clinicCode
    ) {
      id
      clinicCode
      patientForeignKey
      patientId
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone
      specimenId
      specimenType
      medicationList
      clinicUserId
      createdDate
      status
      labStatus
      clinicStatus
    }
  }
`;

const PendingOrders = ({ authUser }) => {
  const [orders, setOrders] = useState([]);
  const [clinicCode, setClinicCode] = useState('');
  const [printers, setPrinters] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  // Require auth
  if (!authUser) {
    createNotification('info', 'Please log in.');
    history.push(ROUTES.SIGN_IN);
  }

  // Get connected printers
  useEffect(() => {
    (async () => {
      const printers = await getPrinters();

      setPrinters(printers);
    })();
  }, []);

  // Get user's clinic code
  useQuery(
    ME_CLINIC_USER,
    {
      onCompleted({ meClinicUser }) {
        const { clinicCode } = meClinicUser;

        setClinicCode(clinicCode);
        getPendingOrders();
      },
      onError(error) {
        console.log('meClinicUser query', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      }
    }
  );

  // Get pending orders
  const [getPendingOrders, { loading: loadingToxicologyOrders }] = useLazyQuery(
    PENDING_TOXICOLOGY_ORDERS,
    {
      fetchPolicy: 'network-only',
      variables: {
        clinicCode: clinicCode
      },
      onCompleted({ pendingToxicologyOrders }) {
        const filtered = pendingToxicologyOrders.filter(({ status }) => status !== STATUS.ARCHIVE);

        setOrders(_.orderBy(filtered, 'createdDate', 'desc'));
        setLoading(false);
      },
      onError(error) {
        console.log('Pending toxicology orders error:', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');

        setLoading(false);
      }
    }
  );

  const handlePrintLabel = (clinicCode, specimenId, patientInitials, patientDOB) => {
    if (printers.length > 0) {
      printLabel(printers[0], clinicCode, specimenId, patientInitials, patientDOB);
    } else {
      // Default to DYMO LabelWriter 450 Turbo
      console.log('Defaulting to printing to DYMO LabelWriter 450 Turbo.');
      printLabel('DYMO LabelWriter 450 Turbo', clinicCode, specimenId, patientInitials, patientDOB);
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="page">
      <div>
        <h4 className="title is-4 is-inline-block mt-1">Pending Orders</h4>
      </div>
      <br />

      {loading ? (
        <Loading />
      ) : (
        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr>
                <th>Patient Name</th>
                <th>Specimen ID</th>
                <th>Specimen Type</th>
                <th>Collection Date</th>
                <th />
              </tr>
            </thead>

            <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td
                  className="has-text-dark"
                  style={{ fontSize: "0.8rem" }}
                >
                  {order.patientLastName}, {order.patientFirstName}
                </td>
                <td
                  className="is-clickable has-text-dark"
                  onClick={() => history.push(ROUTES.DASHBOARD_ORDER_DETAILS.replace(':id', order.id))}
                  style={{ fontSize: "0.8rem" }}
                >
                  {order.specimenId}
                </td>
                <td className="has-text-dark" style={{ fontSize: "0.8rem" }}>{order.specimenType}</td>
                <td className="has-text-dark" style={{ fontSize: "0.8rem" }}>
                  {new Date(parseInt(order.createdDate))
                    .toLocaleString([], {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                  }
                </td>
                <td style={{ textAlign: "right" }}>
                  <span className="mr-4">
                    <DownloadOrderPDFButton specimenId={order.specimenId} outline={true} />
                  </span>

                  <button
                    className="button has-text-info is-small"
                    // onClick={() => printLabel(clinicCode, order.specimenId, `${order.patientFirstName.charAt(0).toUpperCase()}${order.patientLastName.charAt(0).toUpperCase()}`, `${order.patientBirthMonth}/${order.patientBirthDay}/${order.patientBirthYear}`)}
                    onClick={() => handlePrintLabel(clinicCode, order.specimenId, `${order.patientFirstName.charAt(0).toUpperCase()}${order.patientLastName.charAt(0).toUpperCase()}`, `${order.patientBirthMonth}/${order.patientBirthDay}/${order.patientBirthYear}`)}
                  >
                    Print Specimen Label
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PendingOrders;
