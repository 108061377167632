import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Authorization from './Authorization';
import Billing from './Billing';
import ClinicInfo from './ClinicInfo';
import OrdersAndPickupRequests from './OrdersAndPickupRequests';
import PhysicianInfo from './PhysicianInfo';
import ToxicologyTests from './ToxicologyTests';
import SignatureCard from './SignatureCard';
import PhysicianSignatureCapture from './PhysicianSignatureCapture';

import { useAuth } from '../auth';
import * as ROUTES from '../../constants/routes';

import '../../styles/test-profile.css';

const TestProfile = () => {
  const history = useHistory();

  // Require auth
  const authUser = useAuth({ messageType: 'info', messageText: 'Please log in' });

  const [currentStep, setCurrentStep] = useState(0);
  // const [stepsStatus, setStepsStatus] = useState({
  //   0: 'incomplete',
  //   1: 'complete',
  //   2: 'error',
  //   3: 'incomplete',
  // });
  const [stepsStatus, setStepsStatus] = useState({
    0: 'incomplete',
    1: 'incomplete',
    2: 'incomplete',
    3: 'incomplete',
  });

  const generateNavIcon = (step) => {
    switch (stepsStatus[step]) {
      case 'incomplete':
        return (
          <span className="icon is-small ml-1">
            <i className="far fa-circle" aria-hidden="true" />
          </span>
        );
      case 'complete':
        return (
          <span className="icon is-small ml-1 has-text-primary">
            <i className="fas fa-check-circle" aria-hidden="true" />
          </span>
        );
      case 'error':
        return (
          <span className="icon is-small ml-1 has-text-warning">
            <i className="fas fa-exclamation-circle" aria-hidden="true" />
          </span>
        );
      default:
        return (
          <span className="icon is-small ml-1">
            <i className="far fa-circle" aria-hidden="true" />
          </span>
        );
    }
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep+1);
  };

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return <ClinicInfo authUser={authUser} nextStep={handleNextStep} />;
      case 1:
        return <Billing authUser={authUser} nextStep={handleNextStep} />;
      case 2:
        return <OrdersAndPickupRequests authUser={authUser} nextStep={handleNextStep} />;
      case 3:
        return <PhysicianInfo authUser={authUser} nextStep={handleNextStep} />;
      case 4:
        // Skip test section
        setCurrentStep(currentStep+1);

        return;
        // return <ToxicologyTests authUser={authUser} nextStep={handleNextStep} />;
      case 5:
        return <Authorization authUser={authUser} nextStep={handleNextStep} />;
      default:
        // Reset current step
        // setCurrentStep(0);
        // return <ClinicInfo nextStep={handleNextStep} />;

        return history.push(ROUTES.CLINIC_ADMIN);
    }
  };

  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-one-quarter">
            <div style={{ position: 'sticky', top: '30px' }} >
              <h4 className="title is-4">Sections</h4>

              <ul>
                <li onClick={() => (setCurrentStep(0))} className="li-nav">
                  <span className="icon is-small">
                    <i
                      className="fas fa-caret-right"
                      aria-hidden="true"
                      style={{ visibility: currentStep === 0 ? "visible" : "hidden" }}
                    />
                  </span>

                  {generateNavIcon(0)}

                  <span className="ml-2">Clinic Info</span>
                </li>

                <li onClick={() => (setCurrentStep(1))} className="li-nav">
                  <span className="icon is-small">
                    <i
                      className="fas fa-caret-right"
                      aria-hidden="true"
                      style={{ visibility: currentStep === 1 ? "visible" : "hidden" }}
                    />
                  </span>

                  {generateNavIcon(0)}

                  <span className="ml-2">Billing</span>
                </li>

                <li onClick={() => (setCurrentStep(2))} className="li-nav">
                  <span className="icon is-small">
                    <i
                      className="fas fa-caret-right"
                      aria-hidden="true"
                      style={{ visibility: currentStep === 2 ? "visible" : "hidden" }}
                    />
                  </span>

                  {generateNavIcon(1)}

                  <span className="ml-2">Orders & Pickup Requests</span>
                </li>

                <li onClick={() => (setCurrentStep(3))} className="li-nav">
                  <span className="icon is-small">
                    <i
                      className="fas fa-caret-right"
                      aria-hidden="true"
                      style={{ visibility: currentStep === 3 ? "visible" : "hidden" }}
                    />
                  </span>

                  {generateNavIcon(2)}

                  <span className="ml-2">Physician(s) Info</span>
                </li>

{/*                 <li onClick={() => (setCurrentStep(4))} className="li-nav"> */}
{/*                   <span className="icon is-small"> */}
{/*                     <i */}
{/*                       className="fas fa-caret-right" */}
{/*                       aria-hidden="true" */}
{/*                       style={{ visibility: currentStep === 4 ? "visible" : "hidden" }} */}
{/*                     /> */}
{/*                   </span> */}
{/*  */}
{/*                   {generateNavIcon(3)} */}
{/*  */}
{/*                   <span className="ml-2">Tests</span> */}
{/*                 </li> */}

                <li onClick={() => (setCurrentStep(5))} className="li-nav">
                  <span className="icon is-small">
                    <i
                      className="fas fa-caret-right"
                      aria-hidden="true"
                      style={{ visibility: currentStep === 5 ? "visible" : "hidden" }}
                    />
                  </span>

                  {generateNavIcon(3)}

                  <span className="ml-2">Authorization</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="column" style={{ borderLeft: "1px solid black" }}>
            {renderStep(currentStep)}
          </div>
        </div>
      </div>
    </section>
  );
};

const EditTestProfileButton = () => {
  return (
    <Link
      to={ROUTES.CLINIC_TEST_PROFILE}
      className="button"
    >
      <span className="icon is-small has-text-grey"><i className="fas fa-cog" aria-hidden="true"></i></span>
      <span>Clinic Settings</span>
    </Link>
  );
};

export default TestProfile;

export { EditTestProfileButton, PhysicianSignatureCapture, SignatureCard };
