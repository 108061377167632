import React, { useState } from 'react';
import axios from 'axios';
import download from 'downloadjs';

import { createNotification } from '../Notification';

const DownloadOrderPDFButton = ({ specimenId, outline, text }) => {
  const [generating, setGenerating] = useState(false);

  const outlineClass = "button has-text-info is-small";
  const infoClass = "button is-info";

  const handleClick = async () => {
    console.log(`Generating order form for: ${specimenId}`);

    setGenerating(true);

    try {
      const data = { specimenId };
      const headers = {
        Authorization: `apikey ${process.env.REACT_APP_GENERATE_ORDER_PDF_API_KEY}`,
      };

      const response = await axios.post(process.env.REACT_APP_GENERATE_ORDER_PDF_URL, data, { headers });
      const contentType = response.headers['content-type'];
      download(response.data, `Req-${specimenId}.pdf`, contentType);
    } catch (error) {
      console.log('Order Form Generation', error);
      createNotification('danger', 'Error generating order PDF.');
    }

    setGenerating(false);
  };

  return (
    <button
      className={outline ? outlineClass : infoClass}
      onClick={handleClick}
      disabled={generating}
    >
      {text ? text : "Order Form"}
    </button>
  );
};

export default DownloadOrderPDFButton;
