import React, { useEffect, useRef, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import SignaturePad from 'react-signature-canvas';
import ReactToPrint from 'react-to-print';
import { nanoid } from 'nanoid';
import _ from 'lodash';

import PrintSignatureCard from './PrintSignatureCard';
import Loading, { ButtonLoading } from '../Loading';
import { sendText } from '../../utils';
import * as ROUTES from '../../constants/routes';

import '../../styles/form.css';
import './signature.css';

export const GET_PHYSICIAN_INFO = gql`
  query ToxicologyTestProfile {
    toxicologyTestProfile {
      id
      clinicCode
      physicianInfo {
        id
        physicianName
        physicianSuffix
        physicianNPI
        signature
      }
    }
  }
`;

export const UPDATE_PHYSICIANS_INFO = gql`
  mutation UpdateToxicologyTestProfile(
    $clinicCode: String!,
    $physicianInfo: [PhysicianCardInput]
  ) {
    updateToxicologyTestProfile(
      data: {
        clinicCode: $clinicCode,
        physicianInfo: $physicianInfo
      }
    ) {
      id
      clinicName
      clinicCode
      physicianInfo {
        id
        physicianName
        physicianSuffix
        physicianNPI
      }
    }
  }
`;

export const SAVE_PHYSICIAN_SIGNATURE = gql`
  mutation SetSignature(
    $foreignKey: String!
    $type: String
    $signature: String
  ) {
    setSignature(
      data: {
        foreignKey: $foreignKey,
        type: $type,
        signature: $signature
      }
    ) {
      foreignKey
    }
  }
`;

const PhysicianInfo = ({ authUser, nextStep }) => {
  return (
    <div className="container">
      <h3 className="title is-3 ml-5">Physician Information</h3>
      <p className="ml-5">Add each physician that can place orders. At least one is required.<br/>
        Be sure to print the signature card and email it, or mail it to us.</p>
      <br />
      <button className="button is-light ml-5" onClick={()=> window.open("https://storage.googleapis.com/static-files-for-report/Provider%20Signature%20Cards.pdf", "_blank")}>
        <i className="fas fa-print mr-2 has-text-grey"></i> Print Signature Card
      </button>
      <br/><br/>
      <PhysicianInfoForm nextStep={nextStep} />
    </div>
  );
};

const PhysicianInfoForm = ({ nextStep }) => {
  const [physicians, setPhysicians] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [clinicCode, setClinicCode] = useState('');
  const [signatureCardPrinted, setSignatureCardPrinted] = useState(true);
  const [sigImages, setSigImages] = useState([]);
  const [phoneInput, setPhoneInput] = useState(null);
  const [textsSent, setTextsSent] = useState([]);

  const componentRef = useRef();

  // Generate refs for physician signature cards
  const signatureRef = useRef([]);

  useEffect(() => {
    signatureRef.current = signatureRef.current.slice(0, physicians.length);
  }, [physicians.length]);

  // TODO: debug
  // useEffect(() => {
  //   console.log('Signatures:', sigImages);
  // }, [sigImages]);

  useQuery(
    GET_PHYSICIAN_INFO,
    {
      fetchPolicy: 'network-only',
      onCompleted({ toxicologyTestProfile }) {
        if (toxicologyTestProfile.physicianInfo.length === 0) {
          setPhysicians([{}]);
        } else {
          setPhysicians(toxicologyTestProfile.physicianInfo);
        }

        setSigImages(toxicologyTestProfile.physicianInfo.map(({ signature }) => signature))
        setClinicCode(toxicologyTestProfile.clinicCode);
        setLoading(false);
      },
      onError(error) {
        // TODO: show error notification
        console.log('Test Profile - Clinic Info Error:', error);

        setLoading(false);
      }
    }
  );

  const [updateClinicInfo, { loading: loadingMutation }] = useMutation(
    UPDATE_PHYSICIANS_INFO,
    {
      onCompleted({ updateToxicologyTestProfile: data }) {
        // Move to next step
        nextStep();
      },
      onError(error) {
        // TODO: Use notification
        console.log('Update Physician Info mutation', error);
      },
    },
  );

  const [saveSignatureMutation, { loading: savingSignature }] = useMutation(
    SAVE_PHYSICIAN_SIGNATURE,
    {
      onCompleted({ setSignature }) {
        console.log(`Signature saved:`, setSignature);
      },
      onError(error) {
        // TODO: Use notification
        console.log('Save signature', error);
      }
    },
  );

  const handleChange = (e, i, physician) => {
    const physiciansCopy = _.cloneDeep(physicians);
    const newData = { ...physicians[i], [e.target.name]: e.target.value };

    physiciansCopy.splice(i, 1, newData);

    setPhysicians(physiciansCopy);
  };

  const handleDelete = (physician, i) => {
    // const filtered = physicians.filter((p) => p.id !== physician.id);
    const filtered = _.cloneDeep(physicians);
    filtered.splice(i, 1);

    // Delete signature if present
    const sigs = _.cloneDeep(sigImages);
    sigs.splice(i, 1);
    setSigImages(sigs);

    setPhysicians(filtered);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const input = physicians
      .map(({ id, physicianNPI, physicianName, physicianSuffix }) => ({
        id: id ? id : nanoid(),
        physicianName,
        physicianSuffix,
        physicianNPI,
      }));

    setPhysicians(input);

    // Remove empty elements
    const filtered = input.filter((p) => (p.physicianName));

    // Save the signatures
    sigImages.forEach((sig, i) => {
      const variables = {
        foreignKey: input[i].id,
        type: 'Physician',
        signature: sig,
      };

      if (!textsSent.includes(input[i].id)) {
        saveSignatureMutation({ variables });
      }
    });

    // Execute the mutation
    updateClinicInfo({
      variables: {
        clinicCode,
        physicianInfo: filtered,
      }
    });
  };

  const clearSignature = (i) => {
    signatureRef.current[i].clear();
  };

  const saveSignature = (i, close) => {
    const images = _.cloneDeep(sigImages);

    images.splice(i, 1, signatureRef.current[i].getTrimmedCanvas().toDataURL('image/png'));

    setSigImages(images);
    setPhoneInput(null);
    close();
  };

  const deleteSignature = (i, close) => {
    const images = _.cloneDeep(sigImages);
    const updatedPhysicians = _.cloneDeep(physicians);

    images.splice(i, 1, null);
    updatedPhysicians[i].signature = null;

    setSigImages(images);
    setPhysicians(updatedPhysicians);
    setPhoneInput(null);
    close && close();
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="columns ml-4">
        <div className="column is-two-thirds">

          <form onSubmit={handleSubmit}>
            <button
              className="button mb-3"
              onClick={(e) => {
                e.preventDefault();
                const addLine = [...physicians, { id: nanoid() }];
                const addSig = [...sigImages, null];

                setPhysicians(addLine);
                setSigImages(addSig);
              }}
            >
              <i className="fas fa-plus mr-2 has-text-grey" />
              Add Another Physician
            </button>

            {physicians.map((physician, i) => (
              <div className="card mb-6" key={physician.id || i}>
                <div className="card-content">
                  <i
                    className="is-pulled-right fas fa-trash-alt"
                    onClick={() => handleDelete(physician, i)}
                  />

                  <div className="field">
                    <label className="label" htmlFor={`${i}-physicianName`}>
                      Physician Name
                    </label>
                    <input
                      id={`${i}-physicianName`}
                      name="physicianName"
                      className="input"
                      type="text"
                      placeholder="Name"
                      value={physician.physicianName}
                      onChange={(e) => handleChange(e, i, physician)}
                    />
                  </div>

                  <div className="control">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="physicianSuffix"
                        value="MD"
                        checked={physician.physicianSuffix === "MD"}
                        onChange={(e) => handleChange(e, i, physician)}
                      />
                      <span className="ml-1 mr-3 has-text-weight-semibold">MD</span>
                    </label>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="physicianSuffix"
                        value="DO"
                        checked={physician.physicianSuffix === "DO"}
                        onChange={(e) => handleChange(e, i, physician)}
                      />
                      <span className="ml-1 mr-3 has-text-weight-semibold">DO</span>
                    </label>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="physicianSuffix"
                        value="NP"
                        checked={physician.physicianSuffix === "NP"}
                        onChange={(e) => handleChange(e, i, physician)}
                      />
                      <span className="ml-1 mr-3 has-text-weight-semibold">NP</span>
                    </label>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="physicianSuffix"
                        value="PA"
                        checked={physician.physicianSuffix === "PA"}
                        onChange={(e) => handleChange(e, i, physician)}
                      />
                      <span className="ml-1 mr-3 has-text-weight-semibold">PA</span>
                    </label>
                  </div>

                  <div className="field mt-5">
                    <label className="label" htmlFor={`${i}-physicianNPI`}>
                      Physician's NPI
                    </label>
                    {/* <input */}
                    {/*   id={`${i}-physicianNPI`} */}
                    {/*   name="physicianNPI" */}
                    {/*   className="input" */}
                    {/*   type="text" */}
                    {/*   placeholder="NPI" */}
                    {/*   pattern="[0-9]{10}" */}
                    {/*   value={physician.physicianNPI} */}
                    {/*   onChange={(e) => handleChange(e, i, physician)} */}
                    {/* /> */}
                    <input
                      id={`${i}-physicianNPI`}
                      name="physicianNPI"
                      className="input"
                      type="text"
                      placeholder="NPI"
                      value={physician.physicianNPI}
                      onChange={(e) => handleChange(e, i, physician)}
                    />
                  </div>

                  {/*
                  <Link
                    to={ROUTES.SIGNATURE_CARD}
                    className="button is-primary"
                  >
                    Add Signature
                  </Link>
                  */}

                  {/*
                  {physicians.length  > 1 && i !== physicians.length-1 ? (<div className="mt-6"><hr /></div>) : null}
                  */}

                  {sigImages[i] && (
                    <p
                      className="button is-danger is-outlined is-pulled-right"
                      onClick={() => deleteSignature(i)}
                    >
                      Delete Signature
                    </p>
                  )}

                  <Popup
                    modal
                    trigger={<p className="button is-info is-outlined">Capture Signature</p>}
                    closeOnDocumentClick={false}
                  >
                    {(close) => (
                      <>
                        <SignaturePad
                          // ref={signatureRef.current[i]}
                          ref={(el) => signatureRef.current[i] = el}
                          // ref={(el) => (signatureRef.current = [...signatureRef.current, el])}
                          canvasProps={{ className: "signatureCanvas" }}
                        />

                        <div className="p-3" style={{ border: "1px solid black" }}>
                          {/* <p> */}
                            {/* Send via text:{" "} */}
                          <p>
                            Send via text:
                          </p>

                          <div className="field has-addons">
                            <div className="control">
                              <input
                                className="input"
                                type="text"
                                placeholder="e.g. 5555555555"
                                name={`${i}-phoneNumber`}
                                value={phoneInput}
                                onChange={(e) => setPhoneInput(e.target.value)}
                              />
                            </div>
                            <div className="control">
                              <a
                                className="button is-info"
                                onClick={(e) => {
                                  e.preventDefault();

                                  if (!phoneInput || phoneInput.length < 10) {
                                    return;
                                  }

                                  const url = `${process.env.REACT_APP_BASE_URL}${ROUTES.PHYSICIAN_SIGNATURE_CAPTURE.replace(':id', physician.id)}`;
                                  const msg = `Physician signature requested ${url}`;

                                  sendText(phoneInput, msg);
                                  setTextsSent([ ...textsSent, physician.id ]);
                                  setPhoneInput(null);
                                  close();
                                }}
                              >
                                Send Text
                              </a>
                            </div>
                          </div>
                          {/* </p> */}
                        </div>

                        <div
                          className="p-3"
                          style={{ border: "1px solid black" }}
                        >
                          <button
                            className="button is-info mr-4"
                            onClick={() => saveSignature(i, close)}
                          >
                            Save
                          </button>
                          <button
                            className="button is-info is-outlined mr-4"
                            onClick={() => clearSignature(i)}
                          >
                            Clear
                          </button>
                          <button
                            className="button is-danger"
                            onClick={() => {
                              setPhoneInput(null);
                              close();
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                  </Popup>

                  <br />
                  <br />

                  {sigImages[i] ? (
                    <img
                      src={sigImages[i]}
                      alt="Signature"
                      style={{
                        display: "block",
                        margin: "0 auto",
                        border: "1px solid black",
                        width: "100%",
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ))}

            <div className="mb-5">
              {/*<ReactToPrint
              trigger={() => (
                <button
                  className="button is-primary mr-3"
                  onClick={() => setSignatureCardPrinted(true)}
                >
                  Print Signature Card{physicians.length === 1 ? null : "s"}
                </button>
              )}
              content={() => (componentRef.current)}
            />*/}
            </div>

            <br/><br/>
            <input
              type="submit"
              className="button is-primary"
              value="Save and Continue"
              disabled={!signatureCardPrinted}
            />

            {loadingMutation ? <ButtonLoading /> : null}

            {!signatureCardPrinted ? <p>Print signature card(s) to continue.</p> : null}

          </form>
        </div>
      </div>

      <PrintSignatureCard ref={componentRef} physicians={physicians} />
    </>
  );
};

export default PhysicianInfo;
