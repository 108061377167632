import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';

import SelectPatient from './SelectPatient';
import ToxicologyTestOrderDetails from './ToxicologyTestOrderDetails';
import Loading, { ButtonLoading } from '../Loading';

import { createNotification } from '../Notification';
import { ME_CLINIC_USER } from '../Admin/ClinicUsersList';
import { useAuth } from '../auth';
import { getPrinters, printLabel } from '../../utils';
import * as ROUTES from '../../constants/routes';

import '../../styles/form.css';

export const CREATE_TOXICOLOGY_ORDER = gql`
  mutation CreateToxicologyOrder(
    $clinicCode: String!,
    $patientForeignKey: String!,
    $patientId: String!,
    $patientFirstName: String!,
    $patientLastName: String!,
    $patientBirthMonth: String!,
    $patientBirthDay: String!,
    $patientBirthYear: String!,
    $patientEmail: String!,
    $patientPhone: String!,
    $specimenId: String!,
    $specimenType: String!,
    $clinicalPurpose: String,
    $medicationList: String!,
    $medicationListTags: [String],
    $icd10Codes: String!,
    $icd10CodeTags: [String],
    $providerId: String,
    $providerName: String!,
    $providerSuffix: String,
    $providerNPI: String,
    $toxicologyTests: [ToxicologyTestInput]!
    $customTests: String,
    $pocTests: [POCTestInput]!
    $etgTests: [String]
    $testStrips: [TestStripInput]
    $urineValidationReporting: Boolean
    $specimenCollectionDate: String
  ) {
    createToxicologyOrder(
      data: {
        clinicCode: $clinicCode,
        patientForeignKey: $patientForeignKey,
        patientId: $patientId,
        patientFirstName: $patientFirstName,
        patientLastName: $patientLastName,
        patientBirthMonth: $patientBirthMonth,
        patientBirthDay: $patientBirthDay,
        patientBirthYear: $patientBirthYear,
        patientEmail: $patientEmail,
        patientPhone: $patientPhone,
        specimenId: $specimenId,
        specimenType: $specimenType,
        clinicalPurpose: $clinicalPurpose,
        medicationList: $medicationList,
        medicationListTags: $medicationListTags,
        icd10Codes: $icd10Codes,
        icd10CodeTags: $icd10CodeTags,
        providerId: $providerId,
        providerName: $providerName,
        providerSuffix: $providerSuffix,
        providerNPI: $providerNPI,
        toxicologyTests: $toxicologyTests,
        customTests: $customTests
        pocTests: $pocTests,
        etgTests: $etgTests,
        testStrips: $testStrips,
        urineValidationReporting: $urineValidationReporting,
        specimenCollectionDate: $specimenCollectionDate
      }
    ) {
      id
    }
  }
`;

const CreateToxicologyOrder = (props) => {
  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [patient, setPatient] = useState({});
  const [printers, setPrinters] = useState([]);
  const [saving, setSaving] = useState(false);

  // Get user's clinic code
  const { data: meClinicUser } = useQuery(ME_CLINIC_USER);
  const authUserId = meClinicUser?.meClinicUser?.id;
  const clinicCode = meClinicUser?.meClinicUser?.clinicCode;

  // Set clinic and patient if passed in as props
  useEffect(() => {
    if (props.location.state) {
      if ('patient' in props.location.state) {
        const { patient } = props.location.state;

        // setSelectedClinicCode(patient.clinicCode);
        setPatient(patient);
        setCurrentStep(1);
      }
    }
  }, []);

  // Get connected printers
  useEffect(() => {
    (async () => {
      const printers = await getPrinters();

      setPrinters(printers);
    })();
  }, []);

  // Create order mutation
  const [createOrder, { loading }] = useMutation(
    CREATE_TOXICOLOGY_ORDER,
    {
      onCompleted({ createToxicologyOrder }) {
        if (createToxicologyOrder) {
          createNotification('info', 'Toxicology order created successfully');
          setSaving(false);

          // Redirect after order creation
          history.push(ROUTES.DASHBOARD_PENDING_ORDERS);
        }
      },
      onError(error) {
        console.log('Create toxicology order error:', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');

        setSaving(false);
      }
    }
  );

  const handleNextStep = () => {
    setCurrentStep(currentStep+1);
  };

  const handleSelectPatient = (p) => {
    setPatient(p);
  };

  const generateToxicologyTests = (values) => {
    const tests = values.tests.map(({
      drugName,
      drugClass,
      drugBrand,
      pos,
      neg,
      scrn,
      result,
      cutoff,
      c_i,
      flag,
    }) => ({ drugName, drugClass, drugBrand, pos, neg, scrn, result, cutoff, c_i, flag }));

    // Remove urine validation testing if oral fluid specimen
    if (values.specimenType.toUpperCase() === 'ORAL FLUID') {
      const idx = _.findIndex(tests, { drugName: 'Urine Validation Testing'});

      tests[idx].pos = false;
    }

    return tests;
  };

  const handleTestDetails = (details) => {
    setSaving(true);

    // Handle test strips
    const testStrips = [];
    if (details.testStrips.length > 0) {
      details.testStrips.forEach((drug) => {
        const idx = _.findIndex(details.tests, ({ drugName }) => drugName.toUpperCase() === drug.drugName.toUpperCase());

        if (idx > -1) {
          const { cutoffs, drugBrand, drugClass, drugName, isActive, scrnAvailable, selected } = drug;

          if (selected) {
            testStrips.push({
              cutoffs: {
                urineDefault: cutoffs.urineDefault,
                urineCustom: cutoffs.urineCustom,
                oralFluidDefault: cutoffs.oralFluidDefault,
                oralFluidCustom: cutoffs.oralFluidCustom,
              },
              drugBrand,
              drugClass,
              drugName,
              isActive,
              scrnAvailable,
              scrn: false,
            });
          }

          // Remove from values.tests
          details.tests.splice(idx, 1);
        } else {
          const { cutoffs, drugBrand, drugClass, drugName, isActive, scrnAvailable, selected } = drug;

          if (selected) {
            testStrips.push({
              cutoffs: {
                urineDefault: cutoffs.urineDefault,
                urineCustom: cutoffs.urineCustom,
                oralFluidDefault: cutoffs.oralFluidDefault,
                oralFluidCustom: cutoffs.oralFluidCustom,
              },
              drugBrand,
              drugClass,
              drugName,
              isActive,
              scrnAvailable,
              scrn: false,
            });
          }
        }
      });
    }

    const data = {
      clinicCode,
      patientForeignKey: patient.id,
      patientId: patient.patientId,
      patientFirstName: patient.firstName,
      patientLastName: patient.lastName,
      patientBirthMonth: patient.birthMonth,
      patientBirthDay: patient.birthDay,
      patientBirthYear: patient.birthYear,
      patientEmail: patient.email,
      patientPhone: patient.phone,
      specimenId: details.specimenId,
      specimenType: details.specimenType,
      clinicalPurpose: details.clinicalPurpose === "Other" ? details.clinicalPurposeOther : details.clinicalPurpose,
      icd10Codes: details.icd10Codes,
      icd10CodeTags: details.icd10CodeTags,
      medicationList: details.medicationList,
      medicationListTags: details.medicationListTags,
      providerId: details.providerId,
      providerName: details.providerName,
      providerSuffix: details.providerSuffix,
      providerNPI: details.providerNPI,
      urineValidationReporting: details.urineValidationReporting,
      pocTests: details.pocTests,
      etgTests: details.specimenType.toUpperCase() === 'ORAL FLUID' ? [] : details.etgTests,
      customTests: details.customTests,
      toxicologyTests: (details.tests.length > 0) && ('isActive' in details.tests[0]) && !_.isNull(details.tests[0].isActive) ? details.tests : generateToxicologyTests(details),
      testStrips,
      specimenCollectionDate: details.specimenCollectionDate,    // new Date().toISOString()
    };

    if (process.env.NODE_ENV === 'development') {
      console.log('createOrder:', data);
    }

    createOrder({ variables: { ...data } });

    // Print sample ID label
    const patientInitials = patient.firstName.charAt(0).toUpperCase() + patient.lastName.charAt(0).toUpperCase();
    const patientDOB = `${patient.birthMonth}/${patient.birthDay}/${patient.birthYear}`;

    if (printers.length > 0) {
      const labelResult = printLabel(printers[0], clinicCode, details.specimenId, patientInitials, patientDOB);

      // TODO: This isn't showing up because of other notifications (order successfully created)
      if (!labelResult) createNotification('danger', 'Error printing specimen label.');
    } else {
      // Default to DYMO LabelWriter 450 Turbo
      console.log('Defaulting to printing to DYMO LabelWriter 450 Turbo.');
      const labelResult = printLabel('DYMO LabelWriter 450 Turbo', clinicCode, details.specimenId, patientInitials, patientDOB);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <SelectPatient nextStep={handleNextStep} selectPatient={handleSelectPatient} />;
      case 1:
        return <ToxicologyTestOrderDetails nextStep={handleNextStep} testDetails={handleTestDetails} patientDetails={patient} />;
      default:
        // Reset current step
        setCurrentStep(0);
        return <SelectPatient nextStep={handleNextStep} selectPatient={handleSelectPatient} />;
    }
  };

  if (saving) return <Loading />;

  return (
    <section className="section">
      <div className="container">
        {renderStep()}
      </div>
    </section>
  );
};

const CreateToxicologyOrderButton = () => {
  return (
    <Link
      to={ROUTES.DASHBOARD_CREATE_TOXICOLOGY_ORDER}
      className="button is-primary"
    >
      <span className="icon is-small"><i className="fas fa-plus" aria-hidden="true"></i></span>
      <span>New Toxicology Order</span>
    </Link>
  );
};

export default CreateToxicologyOrder;

export { CreateToxicologyOrderButton };
