const defaultClinicSettings = [
  {
    name: 'enableMedicationList',
    label: 'Enable Medication List',
    value: true,
  },
  {
    name: 'enableMedicationSummary',
    label: 'Enable Medication Comparison Summary',
    value: true,
  },
  {
    name: 'enableScreen',
    label: 'Enable Screen',
    value: true,
  },
];

export default defaultClinicSettings;
