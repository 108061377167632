import { gql } from '@apollo/client';

export const AUTH_USER_QUERY = gql`
  query AuthUser {
    authUser @client {
      id
      roles
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query Notification {
    notification @client {
      messageType
      messageText
    }
  }
`;

export const GET_DRUG_METABOLITES = gql`
  query DrugMetabolites {
    drugMetabolites {
      drugName
      parents
      metabolites
    }
  }
`;

export const GET_DRUG_NAMES = gql`
  query DrugNames {
    drugNames
  }
`;

export const SEARCH_ICD_10_CODES = gql`
  query SearchIcd10Codes(
    $searchTerm: String!
  ) {
    searchIcd10Codes(
      searchTerm: $searchTerm
    ) {
      code
      description
      createdDate
    }
  }
`;
