import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link, useHistory, useParams } from 'react-router-dom';

import ToxicologyOrderDetailsForm from './ToxicologyOrderDetailsForm';
import DownloadOrderPDFButton from './DownloadOrderPDFButton';
import Loading from '../Loading';
import { useAuth } from '../auth';
import { createNotification } from '../Notification';
import * as ROUTES from '../../constants/routes';
import { TOXICOLOGY_CLINIC_CREATED } from '../../constants/status';

export const GET_TOXICOLOGY_ORDER_BY_ID = gql`
  query GetToxicologyOrderById(
    $id: String!
  ) {
    getToxicologyOrder(
      id: $id
    ) {
      id
      clinicCode
      clinicName

      providerId
      providerName
      providerSuffix

      clinicalPurpose

      icd10Codes
      icd10CodeTags

      patientForeignKey
      patientId
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone

      specimenId
      specimenType
      specimenRunDate
      specimenLabNotes
      specimenAnomaly
      specimenAlcoholBiomarker
      specimenIsValid
      specimenLabMachineName
      specimenRunLabId

      batchIds
      batches {
        id
        batchId
        testPanel
      }
      storageTrayLocation

      specimenPH
      specimenSpecificGravity
      specimenCreatinine

      specimenReceivedLabUserId
      specimenReceivedLabDate

      specimenReRunRequestedAnalystId
      specimenReRunRequestedAnalystName
      specimenReRunRequestedAnalystNotes
      specimenReRunRequestedAnalystDate

      specimenReRunLabDate
      specimenReRunLabNotes
      specimenReRunLabTechId

      analystReserved {
        radeasUserId
        firstName
        lastName
        email
      }

      medicationList
      medicationListTags
      clinicUserId
      radeasUserId
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        pos
        scrn
        result
        cutoff
        c_i
        flag
      }
      etgTests
      etgTestPanel {
        drugName
        drugClass
        drugBrand
        result
        cutoff
        posNeg
        c_i
        flag
      }
      pocTests {
        code
        name
        result
      }
      additionalTests {
        name
        result
      }
      customTests

      status
      prevStatus
      labStatus
      clinicStatus

      createdById
      createdDate
      updatedDate

      rejectSpecimen
      rejectSpecimenComment
      rejectSpecimenDate
      rejectSpecimenLabTechId
    }
  }
`;

export const UPDATE_TOXICOLOGY_ORDER = gql`
  mutation UpdateToxicologyOrder(
    $id: String!
    $specimenType: String
    $clinicalPurpose: String
    $medicationList: String
    $medicationListTags: [String]
    $icd10Codes: String
    $icd10CodeTags: [String]
    $providerId: String
    $providerName: String
    $providerSuffix: String
    $toxicologyTests: [UpdateToxicologyTestInput]
    $etgTests: [String]
    $customTests: String
    $pocTests: [POCTestInput]
  ) {
    updateToxicologyOrder(
      id: $id,
      data: {
        specimenType: $specimenType,
        clinicalPurpose: $clinicalPurpose,
        medicationList: $medicationList,
        medicationListTags: $medicationListTags,
        icd10Codes: $icd10Codes,
        icd10CodeTags: $icd10CodeTags,
        providerId: $providerId,
        providerName: $providerName,
        providerSuffix: $providerSuffix,
        toxicologyTests: $toxicologyTests,
        etgTests: $etgTests,
        customTests: $customTests,
        pocTests: $pocTests,
      }
    ) {
      id
      clinicCode
      clinicName

      providerId
      providerName
      providerSuffix

      clinicalPurpose

      icd10Codes
      icd10CodeTags

      patientForeignKey
      patientId
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone

      specimenId
      specimenType
      specimenRunDate
      specimenLabNotes
      specimenAnomaly
      specimenAlcoholBiomarker
      specimenIsValid
      specimenLabMachineName
      specimenRunLabId

      batchIds
      batches {
        id
        batchId
        testPanel
      }
      storageTrayLocation

      specimenPH
      specimenSpecificGravity
      specimenCreatinine

      specimenReceivedLabUserId
      specimenReceivedLabDate

      specimenReRunRequestedAnalystId
      specimenReRunRequestedAnalystName
      specimenReRunRequestedAnalystNotes
      specimenReRunRequestedAnalystDate

      specimenReRunLabDate
      specimenReRunLabNotes
      specimenReRunLabTechId

      analystReserved {
        radeasUserId
        firstName
        lastName
        email
      }

      medicationList
      medicationListTags
      clinicUserId
      radeasUserId
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        pos
        scrn
        result
        cutoff
        c_i
        flag
      }
      etgTests
      etgTestPanel {
        drugName
        drugClass
        drugBrand
        result
        cutoff
        posNeg
        c_i
        flag
      }
      pocTests {
        code
        name
        result
      }
      additionalTests {
        name
        result
      }
      customTests

      status
      prevStatus
      labStatus
      clinicStatus

      createdById
      createdDate
      updatedDate

      rejectSpecimen
      rejectSpecimenComment
      rejectSpecimenDate
      rejectSpecimenLabTechId
    }
  }
`;

const ToxicologyOrderDetails = () => {
  const [orderDetails, setOrderDetails] = useState({});
  const [editOrderDetails, setEditOrderDetails] = useState(false);
  const [loading, setLoading] = useState(true);

  // Get order ID from URL
  const { id } = useParams();

  const history = useHistory();

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  // Get order details
  useQuery(
    GET_TOXICOLOGY_ORDER_BY_ID,
    {
      variables: { id },
      onCompleted({ getToxicologyOrder }) {
        if (process.env.NODE_ENV === 'development') console.log('getToxicologyOrder:', getToxicologyOrder);

        setOrderDetails(getToxicologyOrder);
        setLoading(false);
      },
      onError(error) {
        console.log('Get toxicology order details', error);
        createNotification('danger', 'Sorry, could not get order details.');
      },
    },
  );

  const [updateToxicologyOrder, { loading: loadingMutation }] = useMutation(
    UPDATE_TOXICOLOGY_ORDER,
    {
      onCompleted({ updateToxicologyOrder }) {
        // console.log('updateToxicologyOrder:', updateToxicologyOrder);

        setOrderDetails(updateToxicologyOrder);
        setEditOrderDetails(false);
      },
      onError(error) {
        // TODO: create notification
        console.log('Update toxicology order mutation', error);
      }
    }
  );

  const handleSubmit = (values) => {
    const variables = {
      id: orderDetails.id,
      specimenType: values.specimenType,
      clinicalPurpose: values.clinicalPurpose === "Other" ? values.clinicalPurposeOther : values.clinicalPurpose,
      medicationList: values.medicationList,
      medicationListTags: values.medicationListTags,
      icd10Codes: values.icd10Codes,
      icd10CodeTags: values.icd10CodeTags,
      providerId: values.providerId,
      providerName: values.providerName,
      providerSuffix: values.providerSuffix,
      toxicologyTests: values.tests.map(({
        drugName,
        drugClass,
        drugBrand,
        pos,
        scrn,
        result,
        cutoff,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, pos, scrn, result, cutoff, c_i, flag })),
      etgTests: values.etgTests,
      customTests: values.customTests,
      pocTests: values.pocTests,
    };

    // Execute mutation
    updateToxicologyOrder({ variables });
  };

  const handleCancel = () => {
    setEditOrderDetails(false);
  };

  const handleEdit = () => {





    // TODO: Uncomment

    // setEditOrderDetails(!editOrderDetails);







  };

  const getCollectionDate = () => {
    let d = orderDetails.createdDate;

    if (typeof(d) === 'string') {
      d = parseInt(d);
    }

    const cd = new Date(d);

    return `${cd.getMonth() + 1}/${cd.getDate()}/${cd.getFullYear()}`
  };

  return (
    <section className="section">
      {/* <h1 className="title is-size-4">Toxicology Order Details</h1> */}

      <div className="columsn">
        <div className="column is-two-thirds-desktop is-two-thirds-tablet">
          <div className="is-pulled-right">
            <button
              className="button is-info"
              onClick={() => history.goBack()}
            >
              Back
            </button>
          </div>

          <div className="is-pulled-left">
            <DownloadOrderPDFButton specimenId={orderDetails.specimenId} />
          </div>

          <br />
          <br />
          <br />

          <h1 className="title is-size-4">Toxicology Order Details</h1>

          <div className="page">
            {loading ? (
              <Loading />
            ) : (
              <>
                {/* {orderDetails.status === TOXICOLOGY_CLINIC_CREATED && ( */}
                {/*   <button */}
                {/*     className="button is-ghost is-pulled-right" */}
                {/*   > */}
                {/*     <span */}
                {/*       className="icon is-cobalt" */}
                {/*       className={"has-tooltip-arrow icon is-cobalt"} */}
                {/*       data-tooltip="Modify order" */}
                {/*       title="Edit order details" */}
                {/*       onClick={handleEdit} */}
                {/*     > */}
                {/*         <i className="fas fa-edit" /> */}
                {/*     </span> */}
                {/*   </button> */}
                {/* )} */}

            <h1 className="title has-text-primary is-size-2 mt-3">{orderDetails.specimenId}</h1>
                <span className="sublabel is-4 mr-3">
                  Clinic
                </span>
                <span className="has-text-grey-dark">
                  {orderDetails.clinicName}
                </span>

                <br />
                <br />

                <span className="sublabel is-4 mr-3">
                  Clinic Code
                </span>
                <span className="has-text-grey-dark">
                  {orderDetails.clinicCode}
                </span>

                <br />
                <br />

                <span className="sublabel is-4 mr-3">
                  Specimen ID
                </span>
                <span className="has-text-grey-dark">
                  {orderDetails.specimenId}
                </span>

                <br />
                <br />

                <span className="sublabel is-4 mr-3">
                  Collection Date
                </span>
                <span className="has-text-grey-dark">
                  {getCollectionDate()}
                </span>

                <br />
                <br />

                <span className="sublabel is-4 mr-3">
                  Patient
                </span>
                <span className="has-text-grey-dark">
                  <Link
                    to={`${ROUTES.PATIENT_DETAILS.replace(':id', orderDetails.patientForeignKey)}`}
                    target="_blank"
                  >
                    {orderDetails.patientLastName}, {orderDetails.patientFirstName}
                  </Link>
                </span>

                <br />
                <br />

                <ToxicologyOrderDetailsForm
                  orderDetails={orderDetails}
                  isEditing={editOrderDetails}
                  isUpdating={loadingMutation}
                  testDetails={handleSubmit}
                  handleCancel={handleCancel}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ToxicologyOrderDetails;
