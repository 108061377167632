import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth, ForgotPasswordLink, SignInForm, SignUpLink } from './auth';
import * as ROUTES from '../constants/routes';

import RadeasLogo from '../images/radeas-logo.png';

const Landing = (props) => {
  const history = useHistory();

  // If already logged in, redirect to dashboard
  const authUser = useAuth({ messageType: '', messageText: '' }, false);

  if (authUser) {
    history.push(ROUTES.DASHBOARD_PENDING_ORDERS);
  }

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <SignInForm title="Please Log In" />
            <br />
            <ForgotPasswordLink />
            <SignUpLink />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Landing;
