export const TOXICOLOGY_CLINIC_CREATED = 'toxicology:clinic:created';
export const TOXICOLOGY_CLINIC_COMPLETE = 'toxicology:clinic:complete';

export const TOXICOLOGY_LAB_RECEIVED = 'toxicology:lab:received';
export const TOXICOLOGY_LAB_REJECTED = 'toxicology:lab:specimen-rejected';
export const TOXICOLOGY_LAB_BATCHED = 'toxicology:lab:batched';
export const TOXICOLOGY_LAB_BATCHED_RUNNING = 'toxicology:lab:batched:running';
export const TOXICOLOGY_LAB_BATCHED_COMPLETE = 'toxicology:lab:batched:complete';
export const TOXICOLOGY_LAB_COMPLETE = 'toxicology:lab:complete';
export const TOXICOLOGY_LAB_RERUN = 'toxicology:lab:rerun';
export const TOXICOLOGY_LAB_IN_PROCESS = 'toxicology:lab:in-process';

export const TOXICOLOGY_ANALYST_PENDING = 'toxicology:analyst:pending';
export const TOXICOLOGY_ANALYST_PENDING_IN_PROCESS = 'toxicology:analyst:pending:in-process';
export const TOXICOLOGY_ANALYST_REVIEW = 'toxicology:analyst:review';
export const TOXICOLOGY_ANALYST_REVIEW_IN_PROCESS = 'toxicology:analyst:review:in-process';
export const TOXICOLOGY_ANALYST_IN_PROCESS = 'toxicology:analyst:in-process';
export const TOXICOLOGY_ANALYST_COMPLETE = 'toxicology:analyst:complete';

export const ARCHIVE = 'ARCHIVE';

// export const TOXICOLOGY_CLINIC_CREATED = 'toxicology:clinic:created';
// export const TOXICOLOGY_CLINIC_COMPLETE = 'toxicology:clinic:complete';
//
// export const TOXICOLOGY_LAB_RECEIVED = 'toxicology:lab:received';
// export const TOXICOLOGY_LAB_REJECTED = 'toxicology:lab:specimen-rejected';
// export const TOXICOLOGY_LAB_BATCHED = 'toxicology:lab:batched';
// export const TOXICOLOGY_LAB_COMPLETE = 'toxicology:lab:complete';
// export const TOXICOLOGY_LAB_RERUN = 'toxicology:lab:rerun';
// export const TOXICOLOGY_LAB_IN_PROCESS = 'toxicology:lab:in-process';
//
// export const TOXICOLOGY_ANALYST_PENDING = 'toxicology:analyst:pending';
// export const TOXICOLOGY_ANALYST_IN_PROCESS = 'toxicology:analyst:in-process';
// export const TOXICOLOGY_ANALYST_REVIEW = 'toxicology:analyst:review';
// export const TOXICOLOGY_ANALYST_COMPLETE = 'toxicology:analyst:complete';
