import React, { useEffect, useState } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { customAlphabet } from 'nanoid';
import DatePicker from 'react-datepicker';
import Autosuggest from 'react-autosuggest';
import Popup from 'reactjs-popup';
import QRCode from 'react-qr-code';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import TestMenuTable from './TestMenuTable';

import defaultClinicSettings from '../../constants/defaultClinicSettings';
import { createNotification } from '../Notification';
import Loading, { ButtonLoading } from '../Loading';
import { filterTests } from '../../utils';
import { GET_DRUG_NAMES, SEARCH_ICD_10_CODES } from '../../constants/gql';
import CLINICAL_PURPOSE from '../../constants/clinicalPurpose';
import * as ROUTES from '../../constants/routes';

import '../../styles/form.css';
import './ToxicologyTestOrderDetails.css';

export const GET_TOXICOLOGY_TEST_PROFILE = gql`
  query ToxicologyTestProfile {
    toxicologyTestProfile {
      id
      clinicCode
      clinicShortCode
      isComplete
      etgTests
      physicianInfo {
        id
        physicianName
        physicianSuffix
        physicianNPI
      }
      clinicSettings {
        name
        value
      }
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        pos
        scrn
        result
        cutoff
        c_i
        flag

        isActive
        scrnAvailable
        parents
        cutoffs {
          urineDefault
          urineCustom
          oralFluidDefault
          oralFluidCustom
        }
      }
      customTests
      testStrips {
        drugName
        drugClass
        drugBrand
        scrnAvailable
        cutoffs {
          urineDefault
          urineCustom
          oralFluidDefault
          oralFluidCustom
        }
        isActive
        isSelectedOnDefaultPanels
      }
    }
  }
`;

export const GET_LAST_TOXICOLOGY_ORDER_BY_PATIENT_ID = gql `
  query GetToxicologyOrdersByPatientId(
    $id: String!
  ) {
    getToxicologyOrdersByPatientId(
      id: $id
      limit: 1
    ) {
      id
      medicationList
      medicationListTags
      createdDate
    }
  }
`;

const TEST_MENU_OPTIONS = {
  confirmationWithScreens: 'confirmation-panel-with-screens',
  confirmationOnly: 'confirmation-panel-only',
  screenOnly: 'screen-panel-only',
  custom: 'custom-panel',
};

const ToxicologyTestOrderDetails = ({ nextStep, testDetails, patientDetails }) => {
  const [testProfile, setTestProfile] = useState({});
  const [tests, setTests] = useState([]);
  const [etgTests, setEtgTests] = useState([]);
  const [etgCheckbox, setEtgCheckbox] = useState(false);
  const [testStrips, setTestStrips] = useState([]);
  const [showTests, setShowTests] = useState(false);          // Show/hide Default Test Profile
  const [showPOCTests, setShowPOCTests] = useState(false);    // Show/hide Point of Care Tests
  const [clinicCode, setClinicCode] = useState('');
  const [providers, setProviders] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [clinicShortCode, setClinicShortCode] = useState('');
  const [specimenId, setSpecimenId] = useState('');
  const [medicationTags, setMedicationTags] = useState([]);
  const [validDrugNames, setValidDrugNames] = useState([]);
  const [customTests, setCustomTests] = useState('');
  const [specimenCollectionDate, setSpecimenCollectionDate] = useState(new Date());

  const [icd10Autocomplete, setIcd10Autocomplete] = useState([]);
  const [icd10ValidTags, setIcd10ValidTags] = useState([]);
  const [icd10Suggestions, setIcd10Suggestions] = useState([]);
  const [diagnosticCodes, setDiagnosticCodes] = useState([]);
  const [icd10Code, setIcd10Code] = useState('');
  const [icd10CodeTags, setIcd10CodeTags] = useState([]);

  const [clinicSettings, setClinicSettings] = useState({});
  const [urineValidationReporting, setUrineValidationReporting] = useState(true);
  const [customTestPanel, setCustomTestPanel] = useState([]);

  const [isV2, setIsV2] = useState(false);
  const [selectedTestMenuOptions, setSelectedTestMenuOptions] = useState('');

  const [testProfileLoading, setTestProfileLoading] = useState(true);
  const [patientLoading, setPatientLoading] = useState(true);
  const [querying, setQuerying] = useState(true);

  const history = useHistory();

  const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 8);

  // Generate specimen ID
  useEffect(() => {
    if (clinicShortCode !== '') {
      const id = `${clinicShortCode}-${nanoid()}`;

      setSpecimenId(id);
    }
  }, [clinicShortCode]);

  // Handle loading state
  useEffect(() => {
    if (!testProfileLoading && !patientLoading) {
      setQuerying(false);
    }
  }, [testProfileLoading, patientLoading]);

  // Check if v2
  useEffect(() => {
    if (!isV2) {
      if (tests.length > 0 && 'isActive' in tests[0] && !_.isNull(tests[0].isActive)) {
        setIsV2(true);
      }
    }
  }, [tests]);

  // Set default selected test panel
  useEffect(() => {
    if (isV2 && clinicSettings.enableScreen) {
      setSelectedTestMenuOptions(TEST_MENU_OPTIONS.confirmationWithScreens);
    } else if (isV2 && !clinicSettings.enableScreen) {
      setSelectedTestMenuOptions(TEST_MENU_OPTIONS.confirmationOnly);
    } else {
      setSelectedTestMenuOptions('-');
    }
  }, [isV2, clinicSettings.enableScreen]);

  // Retrieve clinic test profile
  useQuery(
    GET_TOXICOLOGY_TEST_PROFILE,
    {
      // fetchPolicy: 'network-only',
      onCompleted({ toxicologyTestProfile }) {
        if (process.env.NODE_ENV === 'development') {
          console.log({ toxicologyTestProfile });
        }

        // Test profile incomplete, cannot create order
        if (!toxicologyTestProfile.isComplete) {
          console.log('Incomplete test profile, redirecting...');
          createNotification('danger', 'Please complete your profile before placing an order');

          history.push(ROUTES.CLINIC_TEST_PROFILE);
        }

        if (toxicologyTestProfile.toxicologyTests.length > 0) {
          // TODO: Merge with initialState
//           const activeTestStrips = toxicologyTestProfile.testStrips.filter((test) => {
//             return test.isActive && test.isSelectedOnDefaultPanels;
//           }).map((test) => ({
//             ...test,
//             selected: test.isActive && test.isSelectedOnDefaultPanels,
//           }));
//
//           setTests([ ...toxicologyTestProfile.toxicologyTests, ...activeTestStrips ]);
          setTests(toxicologyTestProfile.toxicologyTests);

          // setTests([ ...toxicologyTestProfile.toxicologyTests, ...toxicologyTestProfile.testStrips ]);
        }

        const physicianInfo = toxicologyTestProfile.physicianInfo.map((p) => ({
          ...p,
          label: `${p.physicianName}, ${p.physicianSuffix}`,
        }));

        const settings = generateOrRenderSettings(toxicologyTestProfile.clinicSettings);
        setClinicSettings(settings);

        // Remove screen if clinicSettings["enableScreen"] is false
        if (!settings.enableScreen) {
          const noScreen = _.cloneDeep(toxicologyTestProfile.toxicologyTests);
          noScreen.forEach((test) => test.scrn = null);

          setTests(noScreen);
        }

        const strips = toxicologyTestProfile.testStrips.filter(({ isActive }) => isActive).map((strip) => ({
          ...strip,
          selected: strip.isActive && strip.isSelectedOnDefaultPanels,
        }));

        setCustomTests(toxicologyTestProfile.customTests);
        setEtgTests(toxicologyTestProfile.etgTests);
        setEtgCheckbox(toxicologyTestProfile.etgTests.length > 0);
        setProviders(physicianInfo);
        setSelectedProvider(physicianInfo[0]);
        setClinicCode(toxicologyTestProfile.clinicCode);
        setClinicShortCode(toxicologyTestProfile.clinicShortCode);
        setTestStrips(strips);
        setTestProfile(toxicologyTestProfile);

        // resetForm();
        setTestProfileLoading(false);
      },
      onError(error) {
        // TODO: show error notification
        console.log('Toxicology test order details', error);
      },
    },
  );

  // Retrieve latest medication list for patient
  useQuery(
    GET_LAST_TOXICOLOGY_ORDER_BY_PATIENT_ID,
    {
      variables: { id: patientDetails.id },
      onCompleted({ getToxicologyOrdersByPatientId }) {
        if (getToxicologyOrdersByPatientId.length > 0) {
          setMedicationTags(getToxicologyOrdersByPatientId[0].medicationListTags);
        }

        setPatientLoading(false);
      },
      onError(error) {
        // TODO: show error notification
        console.log('Patient medication list query', error);
      },
    },
  );

  useQuery(
    GET_DRUG_NAMES,
    {
      onCompleted({ drugNames }) {
        setValidDrugNames(drugNames);
      },
      onError(error) {
        // TODO: show error notification
        console.log('Drug Names query', error);
      },
    },
  );

  const [searchIcd10Codes, { loading: searching }] = useLazyQuery(
    SEARCH_ICD_10_CODES,
    {
      onCompleted({ searchIcd10Codes }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Fetched search results:', searchIcd10Codes);
        }

        setIcd10Autocomplete(searchIcd10Codes);

        // Save codes as valid tags
        const validTags = searchIcd10Codes.filter((code) => {
          return !icd10ValidTags.includes(code);
        });
        setIcd10ValidTags([...icd10ValidTags, ...validTags]);
      },
      onError(error) {

      },
    },
  );

  // TODO: use mutation
  const loading = false;

  const generateOrRenderSettings = (settings) => {
    const missing = defaultClinicSettings.filter((setting) => {
      const idx = _.findIndex(settings, (e) => e.name === setting.name);

      return idx < 0;
    });

    const allSettings = {};

    settings.forEach((setting) => allSettings[setting.name] = setting.value);
    missing.forEach((setting) => allSettings[setting.name] = setting.value);

    return allSettings;
  };

  const handleClick = (i, val) => {
    const newTests = _.cloneDeep(tests);

    if (i === 0) {
      // Preserve last row
      const lastRow = { ...newTests[newTests.length-1] };

      // Toggle entire column
      if (!tests[0][val]) {
        // false -> true
        newTests.forEach((row) => row[val] = true);
      } else {
        // true -> false
        newTests.forEach((row) => row[val] = false);
      }

      // Restore last row
      newTests[newTests.length-1] = lastRow;
    } else {
      // Toggle row
      newTests[i][val] = !newTests[i][val];

      // Uncheck first row ("All") if it's on and this turns off a row
      if (newTests[0][val] && i !== newTests.length-1 && !newTests[i][val]) {
        newTests[0][val] = false;
      }
    }

    // Check if whole array is true and set All accordingly
    const posResult = newTests.filter((row, i) => {
      if (i === 0 || i === newTests.length-1) return false;

      return row.pos === false;
    });
    const negResult = newTests.filter((row, i) => {
      if (i === 0 || i === newTests.length-1) return false;

      return row.neg === false;
    });

    if (posResult.length === 0) {
      newTests[0].pos = true;
    } else {
      newTests[0].pos = false;
    }

    if (negResult.length === 0) {
      newTests[0].neg = true;
    } else {
      newTests[0].neg = false;
    }

    setTests(newTests);
  };

  const onKeyDown = (e) => {
    // Prevent "Enter" key from submitting form
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
    }
  };

  const onDeleteMedicationTag = (med) => {
    const newMedicationTags = medicationTags.filter((drugName) => drugName !== med);

    setMedicationTags(newMedicationTags);
  };

  const onDeleteIcd10CodeTag = (tag) => {
    const newIcd10CodeTags = icd10CodeTags.filter((name) => name !== tag);

    setIcd10CodeTags(newIcd10CodeTags);
  }

  /***********************************************/
  /********** React Autosuggest methods **********/
  /***********************************************/

  // Split search term
  // const splitAutosuggestSearchTerm = (value) => {
  //   return value && value.split(',').map((m) => m.trim().toUpperCase()).pop();
  // };

  // Get autosuggest values for input
  const getSuggestions = (value) => {
    const inputValue = value.trim().toUpperCase();
    const inputLength = inputValue.length;

    // TODO: debug

    // return inputLength > 0 ? icd10Autocomplete.filter((code) =>
    //   code.code.toUpperCase().slice(0, inputLength) === inputValue
    // ) : [];

    return icd10Autocomplete.filter((code) =>
      code.code.toUpperCase().slice(0, inputLength) === inputValue);
  };

  // Get suggestion value
  const getSuggestionValue = (suggestion) => {
    return suggestion.code;
  };

  // Render suggestions
  const renderSuggestion = (suggestion) => (
    <div className="suggestion">
      {suggestion.code}: {suggestion.description}
    </div>
  );

  // Autosuggest onChange handler
  const autosuggestOnChange = (e, { newValue }) => {
    // TODO: debug

    // if (e.target.value && e.target.value.length >= 2) {
    //   searchIcd10Codes({ variables: { searchTerm: e.target.value.toUpperCase() } });
    // }

    e.target.value && searchIcd10Codes({ variables: { searchTerm: e.target.value.toUpperCase() } });

    // console.log('autosuggestOnChange:', e.target.value);

    // const tags = e.target.value && e.target.value.split(',')
    //   .map((code) => code.trim())
    //   .filter((code) => code.length > 0);     // Ignore empty string

    setIcd10Code(newValue);
    // setIcd10CodeTags(tags && tags.length > 0 ? tags : []);
  };

  // Called every time suggestion updates are needed
  const onSuggestionsFetchRequested = ({ value }) => {
    if (value.length >= 2) {
      setIcd10Suggestions(getSuggestions(value));
    } else {
      setIcd10Suggestions([]);
    }
  };

  // Called every time suggestions need to be cleared
  const onSuggestionsClearRequested = () => {
    setIcd10Suggestions([]);
  };

  // Autosuggest required props
  const inputProps = {
    placeholder: icd10CodeTags.length > 0 ? 'ICD-10 code' : '(Required) ICD-10 code',
    value: icd10Code,
    onChange: autosuggestOnChange,
  };

  /***************************************************/
  /********** End React Autosuggest methods **********/
  /***************************************************/

  const addIcd10Tag = (tag) => {
//     console.log('addIcd10Tag:', tag);
//
//     const valid = !!icd10Autocomplete.filter(({ code }) => code === tag);
//
//     console.log('tag valid?', !!valid);

    if (!icd10CodeTags.includes(tag.toUpperCase())) {
      setIcd10CodeTags([...icd10CodeTags, tag]);
    }
  };

  const handleAutocompleteKeyUp = (e) => {
    if (e.key === 'Enter') {
      if (icd10Code !== '') {
        addIcd10Tag(icd10Code.toUpperCase());
        setIcd10Code('');
      }
    }
  };

  const handleKeyUp = ({ key }, drugName, setFieldValue) => {
    if (key === 'Enter') {
      setFieldValue('medicationList', '');

      if (drugName.length > 0 && !medicationTags.map((d) => d.toUpperCase()).includes(drugName.toUpperCase())) {
        setMedicationTags([...medicationTags, drugName]);
      }
    }
  };

  const handleMedicationListOnChange = (e, handleChange) => {
    // Call built-in change handler
    handleChange(e);

    const meds = e.target.value.split(',')
      .map((m) => m.trim())
      .filter((m) => m.length > 0);   // Ignore empty string

    // setMedicationTags(meds);
  };

  const drugIsValid = (name) => {
    return validDrugNames.map((n) => n.toUpperCase()).includes(name.toUpperCase());
  };

  const icd10CodeIsValid = (name) => {
    const result = icd10ValidTags.filter(({ code }) => code.toUpperCase() === name.toUpperCase());

    return result.length > 0;
  };

  const generateTag = (value, type) => {
    const generateTagClass = (val, type) => {
      if (type === 'medicationList') {
        if (drugIsValid(val)) {
          return 'tag is-medium is-primary';
        } else {
          return 'tag is-medium';
        }
      } else if (type === 'icd10Code') {
        if (icd10CodeIsValid(val)) {
          return 'tag is-medium is-primary';
        } else {
          return 'tag is-medium is-danger';
        }
      }
    };

    switch (type) {
      case 'medicationList':
        return (
          <span
            key={`${value}-${Date.now()}`}
            className={generateTagClass(value, type)}
          >
            {value}
            <button
              className="delete ml-2"
              onClick={(e) => {
                e.preventDefault();

                onDeleteMedicationTag(value);
              }}
            />
          </span>
        );
      case 'icd10Code':
        return (
          <span
            key={`${value}-${Date.now()}`}
            className={generateTagClass(value, type)}
          >
            {value}
            <button
              className="delete ml-2"
              onClick={(e) => {
                e.preventDefault();

                onDeleteIcd10CodeTag(value);
              }}
            />
          </span>
        );
      default:
        break;
    }
  };

  const renderTestMenu = (values, errors) => {
    const v2TestMenu = _.isNull(tests[0]?.pos) && !_.isNull(tests[0]?.isActive);

    return (
      <>
        <h4 className="title is-5">Requested Tests</h4>

        <div className="mb-4">
          {v2TestMenu ? generateV2TestMenu(values, errors) : generateV1TestMenu()}
        </div>
      </>
    );
  };

  const generateV1TestMenu = () => {
    return (
      <>
        <p>
          Default Test Profile
          <a
            href="#"
            className="ml-4"
            onClick={(e) => {
              e.preventDefault();
              setShowTests(!showTests)
            }}
          >
            Show/edit
          </a>
        </p>

        {showTests && (
          <>
            <div style={{ overflowX: "auto" }}>
              <table className="table is-bordered is-hoverable mb-6">
                <thead>
                  <tr className="has-background-info-dark">
                    <th className="has-text-white-ter">Order Test, Confirming</th>
                    <th className="has-text-white-ter"><i className="fas fa-plus" /></th>
                  </tr>
                </thead>
                <tbody>
                  {tests.map((row, i) => (
                    <tr
                      key={i}
                      className={i === 0 || i === tests.length-1 ? "has-background-white-ter" : ""}
                    >
                      <td className={row.indent && `pl-${4+row.indent}`}>
                    <span className={i === 0 || i === tests.length-1 ? "has-text-weight-semibold" : ""}>
                      {row.drugName}
                    </span>
                      </td>
                      <td onClick={() => handleClick(i, 'pos')}>
                    <span className="icon is-small">
                      <i
                        className="fas fa-check"
                        style={{ visibility: row.pos ? 'visible' : 'hidden' }}
                      />
                    </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="columns">
              <div className="column is-5">
                <label className="label" htmlFor="input-etgCheckbox">
                  Alcohol Biomarker (EtG/EtS)
                </label>
              </div>

              <div className="column">
                <label className="checkbox">
                  <input
                    id="input-etgCheckbox"
                    name="etgCheckbox"
                    type="checkbox"
                    checked={etgCheckbox}
                    onChange={() => setEtgCheckbox(!etgCheckbox)}
                  />
                </label>
              </div>
            </div>

            <div className="block">
              <label className="label" htmlFor="input-customTests">
                Additional Tests (test names, separated by commas)
              </label>
              <textarea
                id="input-customTests"
                name="customTests"
                className="textarea"
                placeholder="(Optional)"
                value={customTests}
                onChange={(e) => setCustomTests(e.target.value)}
              />
            </div>
          </>
        )}
      </>
    )
  };

  const generateV2TestMenu = (values, errors) => {
    // Filter active tests and remove v1 data
    const activeTests = tests.filter((test) => test.isActive).map((test) => ({
      drugName: test.drugName,
      drugClass: test.drugClass,
      drugBrand: test.drugBrand,
      isActive: test.isActive,
      scrnAvailable: test.scrnAvailable,
      parents: test.parents,
      cutoffs: test.cutoffs,
      scrn: test.scrn,
    }));

    return (
      <>
        <div>
          <div>
            {clinicSettings.enableScreen && (
              <>
                <label className="has-text-dark">
                  <Field
                    type="radio"
                    name="selectedTestMenu"
                    value={TEST_MENU_OPTIONS.confirmationWithScreens}
                    className="mr-4"
                  />
                  Confirmation Panel with Screens
                </label>

                <br />
              </>
            )}

            <label className="has-text-dark">
              <Field
                type="radio"
                name="selectedTestMenu"
                value={TEST_MENU_OPTIONS.confirmationOnly}
                className="mr-4"
              />
              Confirmation Panel Only
            </label>

            <br />

            {clinicSettings.enableScreen && (
              <>
                <label className="has-text-dark">
                  <Field
                    type="radio"
                    name="selectedTestMenu"
                    value={TEST_MENU_OPTIONS.screenOnly}
                    className="mr-4"
                  />
                  Screen Panel Only
                </label>

                <br />
              </>
            )}

            <label className="has-text-dark">
              <Field
                type="radio"
                name="selectedTestMenu"
                value={TEST_MENU_OPTIONS.custom}
                className="mr-4"
              />
              Custom Panel - Select specific tests below
            </label>
          </div>

          {values.selectedTestMenu === TEST_MENU_OPTIONS.custom && (
            <div className="mt-4">
              <TestMenuTable
                tests={activeTests}
                testStrips={testStrips}
                clinicSettings={clinicSettings}
                handleChange={handleTestMenuChange}
                handleTestStripChange={handleTestStripChange}
              />
            </div>
          )}
        </div>

        <div className="field mt-6">
          <input
            id="urine-validation"
            name="urine-validation"
            className="switch"
            type="checkbox"
            onChange={() => setUrineValidationReporting(!urineValidationReporting)}
            checked={urineValidationReporting}
          />
          <label htmlFor="urine-validation" className="has-text-gray">
            Urine Validation Reporting (if applicable)
          </label>
        </div>
      </>
    );
  };

  const handleTestMenuChange = ({ tests }) => {
    setCustomTestPanel(tests);
  };

  const handleTestStripChange = ({ tests }) => {
    setTestStrips(tests);
  };

  const handleV2Submit = (details, selectedTestMenu) => {
    // TEST_MENU_OPTIONS = {
    //   confirmationWithScreens: 'confirmation-panel-with-screens',
    //   confirmationOnly: 'confirmation-panel-only',
    //   screenOnly: 'screen-panel-only',
    //   custom: 'custom-panel',
    // };

    switch (selectedTestMenu) {
      case TEST_MENU_OPTIONS.confirmationWithScreens:
        v2ConfirmationWithScreens(details);
        break;
      case TEST_MENU_OPTIONS.confirmationOnly:
        v2ConfirmationOnly(details);
        break;
      case TEST_MENU_OPTIONS.screenOnly:
        v2ScreenOnly(details);
        break;
      case TEST_MENU_OPTIONS.custom:
        v2Custom(details);
        break;
      default:
        v2ConfirmationWithScreens(details);
        break;
    }

    v2CheckETG(details);
    v2CheckCustomTests(details);
  };

  const v2ConfirmationWithScreens = (details) => {
    details.tests = tests.filter((test) => (test.isActive)).map((test) => ({
      drugName: test.drugName,
      drugClass: test.drugClass,
      drugBrand: test.drugBrand,
      isActive: test.isActive,
      scrnAvailable: test.scrnAvailable,
      parents: test.parents,
      cutoffs: {
        urineDefault: test.cutoffs.urineDefault,
        urineCustom: test.cutoffs.urineCustom,
        oralFluidDefault: test.cutoffs.oralFluidDefault,
        oralFluidCustom: test.cutoffs.oralFluidCustom,
      },
      scrn: test.scrnAvailable ? "-" : null,
    }));
  };

  const v2ConfirmationOnly = (details) => {
    details.tests = tests.filter((test) => (test.isActive)).map((test) => ({
      drugName: test.drugName,
      drugClass: test.drugClass,
      drugBrand: test.drugBrand,
      isActive: test.isActive,
      scrnAvailable: false,
      parents: test.parents,
      cutoffs: {
        urineDefault: test.cutoffs.urineDefault,
        urineCustom: test.cutoffs.urineCustom,
        oralFluidDefault: test.cutoffs.oralFluidDefault,
        oralFluidCustom: test.cutoffs.oralFluidCustom,
      },
      scrn: null,
    }));

    details.testStrips = [];
  };

  const v2ScreenOnly = (details) => {
    details.tests = tests.filter((test) => test.scrnAvailable).map((test) => ({
      drugName: test.drugName,
      drugClass: test.drugClass,
      drugBrand: test.drugBrand,
      isActive: test.isActive,
      scrnAvailable: test.scrnAvailable,
      parents: test.parents,
      cutoffs: {
        urineDefault: test.cutoffs.urineDefault,
        urineCustom: test.cutoffs.urineCustom,
        oralFluidDefault: test.cutoffs.oralFluidDefault,
        oralFluidCustom: test.cutoffs.oralFluidCustom,
      },
      scrn: test.scrnAvailable ? "-" : null,
    }));
  };

  const v2Custom = (details) => {
    details.tests = customTestPanel.filter((test) => test.selected).map((test) => ({
      drugName: test.drugName,
      drugClass: test.drugClass,
      drugBrand: test.drugBrand,
      isActive: test.isActive,
      scrnAvailable: test.scrnAvailable,
      parents: test.parents,
      cutoffs: {
        urineDefault: test.cutoffs.urineDefault,
        urineCustom: test.cutoffs.urineCustom,
        oralFluidDefault: test.cutoffs.oralFluidDefault,
        oralFluidCustom: test.cutoffs.oralFluidCustom,
      },
      scrn: test.scrnAvailable ? "-" : null,
    }));
  };

  const v2CheckETG = (details) => {
    details.etgTests = [];

    if (_.findIndex(details.tests, (test) => test.drugName.toUpperCase().includes('ETHYL GLUCURONIDE')) > -1) {
      details.etgTests.push('ETHYL GLUCURONIDE');
    }

    if (_.findIndex(details.tests, (test) => test.drugName.toUpperCase().includes('ETHYL SULFATE')) > -1) {
      details.etgTests.push('ETHYL SULFATE');
    }
  };

  // const v2CheckETG = (details) => {
  //   if (_.findIndex(details.tests, (test) => test.drugClass.toUpperCase().includes('ALCOHOL')) > -1) {
  //     details.etgTests = ['ETG'];
  //   } else {
  //     details.etgTests = [];
  //   }
  // };

  const v2CheckCustomTests = (details) => {
    const customTests = details.customTests?.split(',').map((test) => test.trim());

    customTests.forEach((testName) => {
      if (_.findIndex(details.tests, ({ drugName }) => drugName.toUpperCase() === testName.toUpperCase()) > -1) {
        _.pull(customTests, testName);
      }
    });

    details.customTests = customTests.join(', ');
  };

  if (querying) return <Loading />;

  return (
    <React.Fragment>
      <div className="columns">
        <div className="column is-full">
          <div className="page">
            <h3 className="title is-4 is-inline-block mt-1">Toxicology Order</h3><br/>
            <span className="subtitle">Enter the order details</span><br/><br/>
            <span className="is-4 has-text-weight-bold">Patient: {patientDetails.lastName}, {patientDetails.firstName}</span>
            <br />
            <span className="is-4 has-text-weight-bold">Radeas Specimen ID: {specimenId}</span>
            <Formik
              enableReinitialize={true}
              initialValues={{
                // provider: providers ? providers[0].physicianName : '',
                provider: providers[0] && providers[0].label || '',
                specimenType: 'Urine',
                medicationList: '',
                icd10Codes: '',
                clinicalPurpose: '',
                clinicalPurposeOther: '',
                selectedTestMenu: selectedTestMenuOptions,
                // testStrips: false,
                // analyzer: false,
                AMP: 'n/a',
                BAR: 'n/a',
                BUP: 'n/a',
                BZO: 'n/a',
                COC: 'n/a',
                mAMP: 'n/a',
                MDMA: 'n/a',
                MTS: 'n/a',
                OPI: 'n/a',
                OXY: 'n/a',
                PCP: 'n/a',
                PPX: 'n/a',
                TCA: 'n/a',
                THC: 'n/a',
                otherPOCDrugName0: '',
                otherPOCDrug0: 'n/a',
                otherPOCDrugName1: '',
                otherPOCDrug1: 'n/a',
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                // If oral fluid, update THC
                let updatedTests;
                if (values.specimenType.toUpperCase() === 'ORAL FLUID') {
                  const thcIdx = _.findIndex(tests, { drugName: 'Carboxy-THC' });

                  if (thcIdx > -1) {
                    const updatedThc = {
                      ...tests[thcIdx],
                      drugName: 'THC',
                    };

                    updatedTests = _.cloneDeep(tests);
                    updatedTests[thcIdx] = updatedThc;
                  }
                }

                // Filter the tests, "pos" only
                // const posTestsOnly = filterTests(tests);
                const pocTests = [
                  {
                    code: 'AMP',
                    name: 'Amphetamines',
                    result: values.AMP,
                  },
                  {
                    code: 'BAR',
                    name: 'Barbiturates',
                    result: values.BAR,
                  },
                  {
                    code: 'BUP',
                    name: 'Buprenorphine',
                    result: values.BUP,
                  },
                  {
                    code: 'BZO',
                    name: 'Benzodiazepines',
                    result: values.BZO,
                  },
                  {
                    code: 'COC',
                    name: 'Cocaine',
                    result: values.COC,
                  },
                  {
                    code: 'mAMP',
                    name: 'Methamphetamine',
                    result: values.mAMP,
                  },
                  {
                    code: 'MDMA',
                    name: 'Ecstasy',
                    result: values.MDMA,
                  },
                  {
                    code: 'MTS',
                    name: 'Methadone',
                    result: values.MTS,
                  },
                  {
                    code: 'OPI',
                    name: 'Opiates',
                    result: values.OPI,
                  },
                  {
                    code: 'OXY',
                    name: 'Oxycodone',
                    result: values.OXY,
                  },
                  {
                    code: 'PCP',
                    name: 'Phencyclidine',
                    result: values.PCP,
                  },
                  {
                    code: 'PPX',
                    name: 'Propoxyphene',
                    result: values.PPX,
                  },
                  {
                    code: 'TCA',
                    name: 'Tricyclic Antidepressants',
                    result: values.TCA,
                  },
                  {
                    code: 'THC',
                    name: 'Marijuana',
                    result: values.THC,
                  },
                  {
                    code: 'OTHER_0',
                    name: values.otherPOCDrugName0,
                    result: values.otherPOCDrug0,
                  },
                  {
                    code: 'OTHER_1',
                    name: values.otherPOCDrugName1,
                    result: values.otherPOCDrug1,
                  },
                ];

                const clinicalPurposeString = values.clinicalPurpose.includes("Other") ?
                  values.clinicalPurpose.join("::").replaceAll('"', '').replace("Other", `Other: ${values.clinicalPurposeOther}`) :
                  values.clinicalPurpose.join("::").replaceAll('"', '');

                const strips = testStrips.filter(({ selected }) => selected);

                const details = {
                  ...values,
                  // tests: posTestsOnly,
                  tests: values.specimenType.toUpperCase() === 'ORAL FLUID' ? updatedTests : tests,
                  pocTests,
                  etgTests: etgCheckbox ? ['ETG'] : [],
                  specimenId,
                  icd10Codes: icd10CodeTags.join(', '),
                  icd10CodeTags,
                  medicationList: clinicSettings.enableMedicationList ? medicationTags.join(', ') : '',
                  medicationListTags:clinicSettings.enableMedicationList ? medicationTags : [],
                  clinicalPurpose: clinicalPurposeString,
                  clinicalPurposeOther: values.clinicalPurpose.includes("Other") ? values.clinicalPurposeOther : '',
                  providerId: selectedProvider.id,
                  providerName: selectedProvider.physicianName,
                  providerSuffix: selectedProvider.physicianSuffix,
                  providerNPI: 'physicianNPI' in selectedProvider ? selectedProvider.physicianNPI : '',
                  customTests,
                  testStrips: strips,
                  specimenCollectionDate: specimenCollectionDate.toISOString(),
                };

                if (values.selectedTestMenu.length > 1) {
                  if (process.env.NODE_ENV === 'development') {
                    console.log('Handling v2 submission...');
                  }

                  handleV2Submit(details, values.selectedTestMenu);
                  details.urineValidationReporting = details.specimenType.toUpperCase() === 'URINE' ? urineValidationReporting : false;
                }

                testDetails(details);

                // resetForm();
                // setSubmitting(false);
              }}
              validationSchema={Yup.object().shape({
                provider: Yup.string()
                  .required("Please select a provider"),
                specimenType: Yup.string()
                  .required("Specimen type is required"),
                medicationList: Yup.string(),
                icd10Codes: Yup.string(),
                clinicalPurpose: Yup.array().of(Yup.string())
                  .required("Clinical purpose is required"),
                clinicalPurposeOther: Yup.string()
                  .when('clinicalPurpose', {
                    is: (clinicalPurpose) => clinicalPurpose?.includes("Other"),
                    then: Yup.string().required("Clinical purpose is required"),
                  }),
                selectedTestMenu: Yup.string()
                  .required("Please select a test option"),
              })}
            >
              {(formProps) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue
                } = formProps;

                return (
                  <form className="mt-4"
                    onKeyDown={onKeyDown}
                    onSubmit={handleSubmit}
                    style={{paddingLeft:"0"}}
                  >
                    <div className="block">
                      <label className="label has-text-dark" htmlFor="input-provider">
                        Provider Name
                      </label>
                      <div className="select">
                        <select
                          id="input-provider"
                          name="provider"
                          className={errors.provider && touched.provider ? "input error" : "input"}
                          value={values.provider}
                          onChange={(e) => {
                            handleChange(e);

                            const physician = providers.filter((p) => p.id === e.target.value);
                            setSelectedProvider(physician[0]);
                          }}
                          onBlur={handleBlur}
                        >
                          {providers && providers.map((p) => (
                            <option
                              key={p.id}
                              value={p.id}
                              label={p.label}
                            />
                          ))}
                        </select>
                      </div>
                      {errors.provider && touched.provider && (
                        <div className="input-feedback" data-testid="errors-provider">
                          {errors.provider}
                        </div>
                      )}
                    </div>

                    <br/>

                    <div className="block">
                      <label className="label has-text-dark" htmlFor="input-specimenType">
                        Specimen Type
                      </label>
                      <div className="select">
                        <select
                          id="input-specimenType"
                          name="specimenType"
                          className={errors.specimenType && touched.specimenType ? "input error" : "input"}
                          value={values.specimenType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="Urine" label="Urine" />
                          <option value="Oral Fluid" label="Oral Fluid" />
                        </select>
                      </div>
                      {errors.specimenType && touched.specimenType && (
                        <div className="input-feedback" data-testid="errors-specimenType">
                          {errors.specimenType}
                        </div>
                      )}
                    </div>

                    <br/>

                    <div className="block">
                      <label className="label has-text-dark" htmlFor="specimenCollectionDate">
                        Specimen Collection Date
                      </label>
                      <DatePicker
                        className="input"
                        selected={specimenCollectionDate}
                        onChange={(date:Date) => setSpecimenCollectionDate(date)}
                      />
                    </div>

                    <div className="columns">
                      <div className="column is-two-thirds">
                        {clinicSettings.enableMedicationList && (
                          <>
                            <div className="block">
                              <label className="label has-text-dark" htmlFor="input-medicationList">
                                Prescribed Medications <span className="is-size-7">(Click the "Add" button or press "Enter" to add medication)</span>
                              </label>
                              <div className="field has-addons">
                                <div className="control is-expanded">
                                  <input
                                    id="input-medicationList"
                                    name="medicationList"
                                    className={errors.medicationList && touched.medicationList ? "input error" : "input"}
                                    type="text"
                                    placeholder="(Optional) e.g. Codeine, Oxycodone"
                                    value={values.medicationList}
                                    onChange={(e) => handleMedicationListOnChange(e, handleChange)}
                                    onBlur={handleBlur}
                                    onKeyUp={(e) => handleKeyUp(e, values.medicationList, setFieldValue)}
                                  />
                                </div>
                                <div className="control">
                                  <button
                                    className="button is-info"
                                    onClick={(e) => {
                                      e.preventDefault();

                                      if (process.env.NODE_ENV === 'development') {
                                        console.log('Add prescribed medication (add button):', values.medicationList);
                                      }

                                      handleKeyUp({ key: 'Enter' }, values.medicationList, setFieldValue);
                                    }}
                                  >
                                    <span className="icon is-small mr-1">
                                      <i className="fas fa-plus-circle" />
                                    </span>
                                    Add
                                  </button>
                                </div>
                              </div>
                              {errors.medicationList && touched.medicationList && (
                                <div className="input-feedback" data-testid="errors-medicationList">
                                  {errors.medicationList}
                                </div>
                              )}

                              {medicationTags.length > 0 && (
                                <div className="tags" style={{marginTop: "2em"}}>

                                  {medicationTags.map((med) => {
                                    return generateTag(med, 'medicationList');
                                  })}
                                </div>
                              )}
                            </div>

                            <br/>
                          </>
                        )}

                        <div className="block">
                          <label className="label has-text-dark" htmlFor="input-icd10Codes">
                            Diagnosis Codes <span className="is-size-7">(Click the "Add" button or press "Enter" to add ICD-10 codes)</span>
                          </label>
                          <div className="field has-addons">
                            <div className="control is-expanded">
                              <Autosuggest
                                id="input-icd10Codes"
                                className={errors.icd10Codes && touched.icd10Codes ? "input error" : "input"}
                                suggestions={icd10Suggestions}
                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={{
                                  ...inputProps,
                                  onBlur: (e) => {
                                    e.target.id = 'input-icd10Codes';
                                    e.target.name = 'icd10Codes';
                                    handleBlur(e);
                                  },
                                  onKeyUp: (e) => {
                                    handleAutocompleteKeyUp(e);
                                  },
                                  className: errors.icd10Codes && touched.icd10Codes ? "input error" : "input",
                                }}
                                onSuggestionSelected={(e, { suggestion, method }) => {
                                  if (method === 'enter') {
                                    e.preventDefault();
                                  }

                                  addIcd10Tag(suggestion.code);
                                  setIcd10Code('');
                                  // setFieldValue('icd10Codes', suggestion.code);
                                }}
                              />
                            </div>
                            <div className="control">
                              <button
                                className="button is-info"
                                onClick={(e) => {
                                  e.preventDefault();

                                  if (process.env.NODE_ENV === 'development') {
                                    console.log('Add ICD-10 code (add button):', icd10Code);
                                  }

                                  if (icd10Code.length > 0) {
                                    addIcd10Tag(icd10Code.toUpperCase());
                                    setIcd10Code('');
                                    // setFieldValue('icd10Codes', suggestion.code);
                                  }
                                }}
                              >
                                <span className="icon is-small mr-1">
                                  <i className="fas fa-plus-circle" />
                                </span>
                                Add
                              </button>
                            </div>
                          </div>
                          {(errors.icd10Codes && touched.icd10Codes ?
                            <div className="input-feedback help" data-testid="errors-icd10Codes">
                              {errors.icd10Codes}
                            </div>
                            :
                            <div className="input-feedback help feedback-hidden" data-testid="errors-icd10Codes"/>
                          )}

                          {icd10CodeTags.length > 0 && (
                            <div className="tags">
                              {icd10CodeTags.map((tag) => {
                                return generateTag(tag, 'icd10Code');
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="block">
{/*                       <label className="label" htmlFor="input-clinicalPurpose"> */}
{/*                         Clinical Purpose <span className="is-size-7">(Clinical info/indicator(s) supporting order for definitve testing)</span> */}
{/*                       </label> */}
{/*                       <div className="select"> */}
{/*                         <select */}
{/*                           id="input-clinicalPurpose" */}
{/*                           name="clinicalPurpose" */}
{/*                           className={errors.clinicalPurpose && touched.clinicalPurpose ? "input error" : "input"} */}
{/*                           value={values.clinicalPurpose} */}
{/*                           onChange={handleChange} */}
{/*                           onBlur={handleBlur} */}
{/*                         > */}
{/*                           <option value="">&mdash;</option> */}
{/*  */}
{/*                           {CLINICAL_PURPOSE.map((purpose, i) => ( */}
{/*                             <option key={i} value={purpose}>{purpose}</option> */}
{/*                           ))} */}
{/*  */}
{/*                           <option value="Other">Other</option> */}
{/*                         </select> */}
{/*                       </div> */}

                      <label className="label has-text-dark" htmlFor="input-clinicalPurpose">
                        Clinical Purpose <span className="is-size-7">(Clinical info/indicator(s) supporting order for definitve testing)</span>
                      </label>

                      <div className="mt-3 ml-4">
                        {CLINICAL_PURPOSE.map((purpose, i) => (
                          <React.Fragment key={i}>
                            <label className="checkbox">
                              <Field type="checkbox" name="clinicalPurpose" value={purpose} />
                              <span className="ml-2 has-text-dark">{purpose}</span>
                            </label>
                            <br />
                          </React.Fragment>
                        ))}

                        <label className="checkbox">
                          <Field type="checkbox" name="clinicalPurpose" value="Other" />
                          <span className="ml-2 has-text-dark">Other</span>
                        </label>
                      </div>

                      {values.clinicalPurpose.includes("Other") && (
                        <>
                          <label className="label mt-2 has-text-dark" htmlFor="input-clinicalPurposeOther">
                            Please Specify
                          </label>
                          <input
                            id="input-clinicalPurposeOther"
                            name="clinicalPurposeOther"
                            className={errors.clinicalPurposeOther ? "input error" : "input"}
                            type="text"
                            value={values.clinicalPurposeOther}
                            onChange={handleChange}
                          />
                          {errors.clinicalPurposeOther && touched.clinicalPurposeOther && (
                            <div className="input-feedback" data-testid="errors-clinicalPurposeOther">
                              {errors.clinicalPurposeOther}
                            </div>
                          )}
                        </>
                      )}

                      {errors.clinicalPurpose && touched.clinicalPurpose && (
                        <div className="input-feedback" data-testid="errors-clinicalPurpose">
                          {errors.clinicalPurpose}
                        </div>
                      )}
                    </div>

                    <hr />

                    {renderTestMenu(values, errors)}

                    <br/>

                    <h4 className="title is-5">Did You Perform Point of Care Tests?</h4>

                    <p className="has-text-dark">
                      Point of Care Tests
                      <a
                        href="#"
                        className="ml-4"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPOCTests(!showPOCTests)
                        }}
                      >
                        Show/edit
                      </a>
                    </p>

                    {showPOCTests && (
                      <div>
                        <p>Radeas will confirm all positive and negative results.</p>
                        {/*                 <div className="mt-3"> */}
                        {/*                   <label className="checkbox"> */}
                        {/*                     <Field type="checkbox" name="testStrips" /> */}
                        {/*                     <span className="ml-2 has-text-grey">Test Strips</span> */}
                        {/*                   </label> */}

                        {/*                   <label className="checkbox ml-6"> */}
                        {/*                     <Field type="checkbox" name="analyzer" /> */}
                        {/*                     <span className="ml-2 has-text-grey">Analyzer</span> */}
                        {/*                   </label> */}
                        {/*                 </div> */}

                        <div style={{ overflowX: "auto" }}>
                          <table className="table is-bordered is-hoverable mt-5 mb-6">
                            <thead>
                              <tr className="has-background-info-dark">
                                <th className="">Code</th>
                                <th className="">Drug Name</th>
                                <th className="">Positive</th>
                                <th className="">Negative</th>
                                <th className="">N/A</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="has-text-dark">AMP</td>
                                <td className="has-text-dark">Amphetamines</td>
                                <td><Field type="radio" name="AMP" value="pos" /></td>
                                <td><Field type="radio" name="AMP" value="neg" /></td>
                                <td><Field type="radio" name="AMP" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">BAR</td>
                                <td className="has-text-dark">Barbiturates</td>
                                <td><Field type="radio" name="BAR" value="pos" /></td>
                                <td><Field type="radio" name="BAR" value="neg" /></td>
                                <td><Field type="radio" name="BAR" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">BUP</td>
                                <td className="has-text-dark">Buprenorphine</td>
                                <td><Field type="radio" name="BUP" value="pos" /></td>
                                <td><Field type="radio" name="BUP" value="neg" /></td>
                                <td><Field type="radio" name="BUP" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">BZO</td>
                                <td className="has-text-dark">Benzodiazepines</td>
                                <td><Field type="radio" name="BZO" value="pos" /></td>
                                <td><Field type="radio" name="BZO" value="neg" /></td>
                                <td><Field type="radio" name="BZO" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">COC</td>
                                <td className="has-text-dark">Cocaine</td>
                                <td><Field type="radio" name="COC" value="pos" /></td>
                                <td><Field type="radio" name="COC" value="neg" /></td>
                                <td><Field type="radio" name="COC" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">mAMP</td>
                                <td className="has-text-dark">Methamphetamine</td>
                                <td><Field type="radio" name="mAMP" value="pos" /></td>
                                <td><Field type="radio" name="mAMP" value="neg" /></td>
                                <td><Field type="radio" name="mAMP" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">MDMA</td>
                                <td className="has-text-dark">Ecstasy</td>
                                <td><Field type="radio" name="MDMA" value="pos" /></td>
                                <td><Field type="radio" name="MDMA" value="neg" /></td>
                                <td><Field type="radio" name="MDMA" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">MTS</td>
                                <td className="has-text-dark">Methadone</td>
                                <td><Field type="radio" name="MTS" value="pos" /></td>
                                <td><Field type="radio" name="MTS" value="neg" /></td>
                                <td><Field type="radio" name="MTS" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">OPI</td>
                                <td className="has-text-dark">Opiates</td>
                                <td><Field type="radio" name="OPI" value="pos" /></td>
                                <td><Field type="radio" name="OPI" value="neg" /></td>
                                <td><Field type="radio" name="OPI" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">OXY</td>
                                <td className="has-text-dark">Oxycodone</td>
                                <td><Field type="radio" name="OXY" value="pos" /></td>
                                <td><Field type="radio" name="OXY" value="neg" /></td>
                                <td><Field type="radio" name="OXY" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">PCP</td>
                                <td className="has-text-dark">Phencyclidine</td>
                                <td><Field type="radio" name="PCP" value="pos" /></td>
                                <td><Field type="radio" name="PCP" value="neg" /></td>
                                <td><Field type="radio" name="PCP" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">PPX</td>
                                <td className="has-text-dark">Propoxyphene</td>
                                <td><Field type="radio" name="PPX" value="pos" /></td>
                                <td><Field type="radio" name="PPX" value="neg" /></td>
                                <td><Field type="radio" name="PPX" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">TCA</td>
                                <td className="has-text-dark">Tricyclic Antidepressants</td>
                                <td><Field type="radio" name="TCA" value="pos" /></td>
                                <td><Field type="radio" name="TCA" value="neg" /></td>
                                <td><Field type="radio" name="TCA" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">THC</td>
                                <td className="has-text-dark">Marijuana</td>
                                <td><Field type="radio" name="THC" value="pos" /></td>
                                <td><Field type="radio" name="THC" value="neg" /></td>
                                <td><Field type="radio" name="THC" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">Other</td>
                                <td>
                                  <input
                                    id="input-otherPOCDrugName0"
                                    name="otherPOCDrugName0"
                                    className="input has-text-dark"
                                    type="text"
                                    placeholder="Drug name"
                                    value={values.otherPOCDrugName0}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </td>
                                <td><Field type="radio" name="otherPOCDrug0" value="pos" /></td>
                                <td><Field type="radio" name="otherPOCDrug0" value="neg" /></td>
                                <td><Field type="radio" name="otherPOCDrug0" value="n/a" /></td>
                              </tr>
                              <tr>
                                <td className="has-text-dark">Other</td>
                                <td>
                                  <input
                                    id="input-otherPOCDrugName1"
                                    name="otherPOCDrugName1"
                                    className="input has-text-dark"
                                    type="text"
                                    placeholder="Drug name"
                                    value={values.otherPOCDrugName1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </td>
                                <td><Field type="radio" name="otherPOCDrug1" value="pos" /></td>
                                <td><Field type="radio" name="otherPOCDrug1" value="neg" /></td>
                                <td><Field type="radio" name="otherPOCDrug1" value="n/a" /></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                    <hr />

                    <div>
                      <h4 className="title is-5">Patient Signature</h4>

                      <Popup
                        modal
                        trigger={<p className="button is-info">Show QR Code</p>}
                        closeOnDocumentClick={true}
                      >
                        {(close) => {
                          let patientName = `${patientDetails.firstName || ''} ${patientDetails.lastName || ''}`;
                          if (patientName == ' ') patientName = 'No Name Given';
                          const url = `${process.env.REACT_APP_BASE_URL}${ROUTES.PATIENT_SIGNATURE_CAPTURE.replace(':patient_name', encodeURIComponent(patientName)).replace(':specimen_id', specimenId)}`

                          return (
                            <div onClick={close}>
                              <i
                                className="icon is-medium is-pulled-right fas fa-times-circle mt-2 mr-2"
                                onClick={() => close()}
                              />

                              <div className="p-3 has-text-centered" style={{ border: "1px solid black", padding: "100rem" }}>
                                <QRCode value={url} />

                                <p className="mt-5"><a href={url} target="_blank">{url}</a></p>
                              </div>
                            </div>
                          );
                        }}
                      </Popup>
                    </div>

                    <br/><br/>

                    <input
                      type="submit"
                      className="button is-primary"
                      value="Place Order"
                      disabled={isSubmitting || icd10CodeTags.length === 0 || (isV2 && values.selectedTestMenu.length < 2)}
                    />
                    {loading ? <ButtonLoading /> : null}
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ToxicologyTestOrderDetails;
