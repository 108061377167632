import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';

import Loading, { ButtonLoading } from '../Loading';

import '../../styles/form.css';

export const GET_TOXICOLOGY_TESTS = gql`
  query ToxicologyTestProfile {
    toxicologyTestProfile {
      id
      clinicCode
      toxicologyTests {
        drugName
        pos
      }
      customTests
      etgTests
    }
  }
`;

export const UPDATE_TOXICOLOGY_TESTS = gql`
  mutation UpdateToxicologyTestProfile(
    $clinicCode: String!
    $toxicologyTests: [ToxicologyTestInput]
    $customTests: String
    $etgTests: [String]
  ) {
    updateToxicologyTestProfile(
      data: {
        clinicCode: $clinicCode,
        toxicologyTests: $toxicologyTests,
        customTests: $customTests,
        etgTests: $etgTests
      }
    ) {
      id
      clinicName
      clinicCode
      toxicologyTests {
        drugName
        pos
      }
    }
  }
`;

const ToxicologyTests = ({ authUser, nextStep }) => {
  return (
    <div className="container">
      <h3 className="title is-3 ml-5">Toxicology Tests</h3>
      <p className="ml-5">Select your standard of care for your patient population:</p>

      <br />

      <ToxicologyTestsForm nextStep={nextStep} />
    </div>
  );
};

const initialState = [
  {
    drugName: 'All (G0483; 80307)',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Codeine',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Morphine',
    pos: false,
    neg: false,
    indent: 1,
  },
  {
    drugName: 'Hydromorphone',
    pos: false,
    neg: false,
    indent: 2,
  },
  {
    drugName: 'Hydrocodone',
    pos: false,
    neg: false,
    indent: 1,
  },
  {
    drugName: 'Naloxone',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Naltrexone',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Oxycodone',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Oxymorphone',
    pos: false,
    neg: false,
    indent: 1,
  },
  {
    drugName: 'Buprenorphine',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Norbuprenorphine',
    pos: false,
    neg: false,
    indent: 1,
  },
  {
    drugName: 'Fentanyl',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Norfentanyl',
    pos: false,
    neg: false,
    indent: 1,
  },
  {
    drugName: 'Methadone',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'EDDP',
    pos: false,
    neg: false,
    indent: 1,
  },
  {
    drugName: 'Tapentadol',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Tramadol',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Alprazolam',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Clonazepam (7-Amino)',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Diazepam',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Nordiazepam',
    pos: false,
    neg: false,
    indent: 1,
  },
  {
    drugName: 'Temazepam',
    pos: false,
    neg: false,
    indent: 1,
  },
  {
    drugName: 'Oxazepam',
    pos: false,
    neg: false,
    indent: 2,
  },
  {
    drugName: 'Lorazepam',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Carisoprodol',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Meprobamate',
    pos: false,
    neg: false,
    indent: 1,
  },
  {
    drugName: 'Amitriptyline',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Cyclobenzaprine',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Gabapentin',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Pregabalin',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Methylphenidate',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Ketamine',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Amphetamine',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Methamphetamine',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: '6-MAM',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Benzoylecgonine',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'MDA',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'MDMA',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'MDPV',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'PCP',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Carboxy-THC',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'JWH-018',
    pos: false,
    neg: false,
    indent: 0,
  },
  {
    drugName: 'Urine Validation Testing',
    pos: false,
    neg: false,
    indent: 0,
  },
];

const ToxicologyTestsForm = ({ nextStep }) => {
  // TODO: Replace with mutation
  const [loading, setLoading] = useState(true);
  const [tests, setTests] = useState(initialState);
  const [customTests, setCustomTests] = useState('');
  const [etgTests, setEtgTests] = useState([]);
  const [etgCheckbox, setEtgCheckbox] = useState(false);
  const [clinicCode, setClinicCode] = useState('');

  useQuery(
    GET_TOXICOLOGY_TESTS,
    {
      fetchPolicy: 'network-only',
      onCompleted({ toxicologyTestProfile }) {
        if (process.env.NODE_ENV === 'development')
          console.log('Query toxicologyTestProfile:', toxicologyTestProfile);

        if (toxicologyTestProfile.toxicologyTests.length !== 0) {
          // TODO: Merge with initialState

          const tox = _.cloneDeep(toxicologyTestProfile.toxicologyTests);

          // Check if Diazepam is present
          const diazepamIdx = _.findIndex(tox, { drugName: 'Diazepam' });

          if (diazepamIdx === -1) {
            const idx = _.findIndex(tox, { drugName: 'Nordiazepam' });
            tox.splice(idx, 0, {
              drugName: 'Diazepam',
              pos: false,
            });
          }

          setTests(tox);
        }

        setCustomTests(toxicologyTestProfile.customTests);
        setEtgTests(toxicologyTestProfile.etgTests);
        setEtgCheckbox(toxicologyTestProfile.etgTests.length > 0);
        setClinicCode(toxicologyTestProfile.clinicCode);

        setLoading(false);
      },
      onError(error) {
        // TODO: show error notification
        console.log('Settings - Clinic Info Error:', error);

        setLoading(false);
      }
    }
  );

  const [updateClinicInfo, { loading: loadingMutation }] = useMutation(
    UPDATE_TOXICOLOGY_TESTS,
    {
      onCompleted({ updateToxicologyTestProfile: data }) {
        // Move to next step
        nextStep();
      },
      onError(error) {
        // TODO: Use notification
        console.log('Update Toxicology Test mutation error:', error);
      }
    }
  );

  const handleClick = (i, val) => {
    const newTests = _.cloneDeep(tests);

    if (i === 0) {
      // Preserve last row
      const lastRow = { ...newTests[newTests.length-1] };

      // Toggle entire column
      if (!tests[0][val]) {
        // false -> true
        newTests.forEach((row) => row[val] = true);
      } else {
        // true -> false
        newTests.forEach((row) => row[val] = false);
      }

      // Restore last row
      newTests[newTests.length-1] = lastRow;
    } else {
      // Toggle row
      newTests[i][val] = !newTests[i][val];

      // Uncheck first row ("All") if it's on and this turns off a row
      if (newTests[0][val] && i !== newTests.length-1 && !newTests[i][val]) {
        newTests[0][val] = false;
      }
    }

    // Check if whole array is true and set All accordingly
    const posResult = newTests.filter((row, i) => {
      if (i === 0 || i === newTests.length-1) return false;

      return row.pos === false;
    });
    const negResult = newTests.filter((row, i) => {
      if (i === 0 || i === newTests.length-1) return false;

      return row.neg === false;
    });

    if (posResult.length === 0) {
      newTests[0].pos = true;
    } else {
      newTests[0].pos = false;
    }

    if (negResult.length === 0) {
      newTests[0].neg = true;
    } else {
      newTests[0].neg = false;
    }

    setTests(newTests);
  };

  const handleSubmit = () => {
    const toxicologyTests = tests.map(({ drugName, pos }) => ({
      drugName,
      pos,
    }));

    const etgTests = etgCheckbox ? ['ETG'] : [];

    // Execute the mutation
    // updateClinicInfo({
    //   variables: {
    //     clinicCode,
    //     toxicologyTests,
    //     customTests,
    //     etgTests,
    //   },
    // });

    // TODO: Call mutation above. Skipping mutation since it
    //       overwrites tests with v1 data.
    console.log('Skipping this...');
    nextStep();
  };

  if (loading) return <Loading />;

  return (
    <div className="columns ml-4">
      <div className="column is-two-thirds">
        <div className="form">
          <table className="table is-bordered is-hoverable">
            <tbody>
            {tests.map((row, i) => (
              <tr
                key={i}
                className={i === 0 || i === tests.length-1 ? "has-background-white" : "has-background-white"}
              >
                <td className={row.indent && `pl-${4+row.indent}`}>
                    <span className={i === 0 || i === tests.length-1 ? "has-text-weight-semibold" : ""}>
                      {row.drugName.includes("All (G0483; 80307)") ? "Select All" : row.drugName}
                    </span>
                </td>
                <td onClick={() => handleClick(i, 'pos')}>
                    <span className="icon is-small">
                      <i
                        className="fas fa-check"
                        style={{ visibility: row.pos ? 'visible' : 'hidden' }}
                      />
                    </span>
                </td>
              </tr>
            ))}
            </tbody>
          </table>

          <div className="columns">
            <div className="column is-5">
              <label className="label" htmlFor="input-etgCheckbox">
                Alcohol Biomarker (EtG/EtS)
              </label>
            </div>

            <div className="column">
              <label className="checkbox">
                <input
                  id="input-etgCheckbox"
                  name="etgCheckbox"
                  type="checkbox"
                  checked={etgCheckbox}
                  onChange={() => setEtgCheckbox(!etgCheckbox)}
                />
                {/* <span className="ml-2">{etgCheckbox ? "(yes)" : "(no)"}</span> */}
              </label>
            </div>
          </div>

          <div className="block">
            <label className="label" htmlFor="input-customTests">
              Additional Tests (test names, separated by commas)
            </label>
            <textarea
              id="input-customTests"
              name="customTests"
              className="textarea"
              placeholder="(Optional)"
              value={customTests}
              onChange={(e) => setCustomTests(e.target.value)}
            />
          </div>

          <div className="mt-6">
            <button
              className="button is-primary"
              disabled={loading}
              onClick={handleSubmit}
            >
              Save and Continue
            </button>
            {loading ? <ButtonLoading /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToxicologyTests;
