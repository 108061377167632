export const ROOT = "/";
export const HOME = "/home";

// Clinic routes
export const SIGN_UP = "/signup";
export const DASHBOARD = "/dashboard";
export const ACCOUNT = "/account";
export const CLINIC_ADMIN = "/admin";
export const CLINIC_ADMIN_DETAILS = "/admin/users/:id";
export const CLINIC_ADMIN_USERS = "/admin/users";

export const CLINIC_TEST_PROFILE = "/test-profile";
export const SIGNATURE_CARD = "/test-profile/signature-card";

export const PHYSICIAN_SIGNATURE_CAPTURE = "/test-profile/signatures/physician/:id";

// Clinic orders routes
export const DASHBOARD_CREATE_TOXICOLOGY_ORDER = "/dashboard/orders/toxicology/create";
export const DASHBOARD_PENDING_ORDERS = "/dashboard/orders/pending";
export const DASHBOARD_COMPLETED_ORDERS = "/dashboard/orders/complete";
export const DASHBOARD_ORDER_DETAILS = "/dashboard/orders/details/:id";
export const DASHBOARD_ORDER_RESULTS = "/dashboard/orders/results/:id";
export const DASHBOARD_BATCH_DOWNLOAD_REPORTS = "/dashboard/orders/batch/download";

export const PATIENT_SIGNATURE_CAPTURE = "/dashboard/orders/signatures/patient/:patient_name/:specimen_id";

// Patient routes
export const PATIENT_DETAILS = "/dashboard/patient/:id";
export const PATIENT_NEW = "/dashboard/patients/new";
export const PATIENT_LIST = "/dashboard/patients";

// General auth routes
export const SIGN_IN = "/signin";
export const FORGOT_PASSWORD = "/reset-password";
export const RESET_PASSWORD = "/reset-password/:token";
