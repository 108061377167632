import React from 'react';
import { useHistory } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';

const SignatureCard = () => {
  const history = useHistory();

  let sigPad = {};

  return (
    <div style={{ width: "100%", height: "100vh", top: "10%", left: "10%" }}>
      <div
        style={{ width: "80%", height: "80%", margin: "0 auto", backgroundColor: "#fff" }}
      >
        <SignaturePad
          canvasProps={{ className: { width: "100%", height: "100%" } }}
          ref={(ref) => sigPad = ref}
        />
      </div>

      <button
        className="button is-info m-6"
        onClick={() => history.goBack()}
      >
        Save
      </button>

      <button
        className="button is-danger is-pulled-right m-6"
        onClick={() => history.goBack()}
      >
        Cancel
      </button>

      <button
        className="button is-info is-pulled-right m-6"
        onClick={() => sigPad.clear()}
      >
        Clear
      </button>
    </div>
  );
};

export default SignatureCard;
