import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Account from "./Account";
import Admin from "./Admin";
// import Dashboard, { PatientSignatureCapture } from "./Dashboard";
import ForgotPassword from "./auth/ForgotPassword";
import LandingPage from "./Landing";
//import Navigation from './Navigation';
import SideNavigation from "./SideNavigation";
import Patient, { PatientList, PatientPage } from "./Patient";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import ResetPassword from "./auth/ResetPassword";
import TestProfile, { PhysicianSignatureCapture, SignatureCard } from "./TestProfile";
import Dashboard, {
  CreateToxicologyOrder,
  ViewToxicologyResults,
  BatchDownloadReports,
  ToxicologyOrderDetails,
  PatientSignatureCapture,
} from "./Dashboard";
import Notification, { useNotification } from './Notification';

import * as ROUTES from "../constants/routes";

import "../styles/App.scss";

const App = () => {
  // Get notifications
  const { messageType, messageText } = useNotification();

  return (
    <Router>
      <div className="columns">
        <SideNavigation />

        <div className="column">
          <Notification messageType={messageType} messageText={messageText} />

          <Switch>
            <Route exact path={ROUTES.ROOT} component={LandingPage} />

            <Route path={ROUTES.PATIENT_SIGNATURE_CAPTURE} component={PatientSignatureCapture} />

            <Route path={ROUTES.SIGN_IN} component={SignIn} />
            <Route path={ROUTES.SIGN_UP} component={SignUp} />
            <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
            <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />

            <Route path={ROUTES.ACCOUNT} component={Account} />
            <Route path={ROUTES.CLINIC_ADMIN} component={Admin} />

            <Route
              path={ROUTES.DASHBOARD_BATCH_DOWNLOAD_REPORTS}
              component={BatchDownloadReports}
            />
            <Route
              path={ROUTES.DASHBOARD_CREATE_TOXICOLOGY_ORDER}
              component={CreateToxicologyOrder}
            />
            <Route
              path={ROUTES.DASHBOARD_PENDING_ORDERS}
              component={(props) => (
                <Dashboard {...props} activeTab="pending_orders" />
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_COMPLETED_ORDERS}
              component={(props) => (
                <Dashboard {...props} activeTab="completed_orders" />
              )}
            />
            <Route
              path={ROUTES.DASHBOARD_ORDER_RESULTS}
              component={ViewToxicologyResults}
            />
            <Route
              path={ROUTES.DASHBOARD_ORDER_DETAILS}
              component={ToxicologyOrderDetails}
            />

            <Route path={ROUTES.PATIENT_DETAILS} component={PatientPage} />
            <Route path={ROUTES.PATIENT_NEW} component={Patient} />
            <Route path={ROUTES.PATIENT_LIST} component={PatientList} />

            <Route path={ROUTES.SIGNATURE_CARD} component={SignatureCard} />
            <Route path={ROUTES.PHYSICIAN_SIGNATURE_CAPTURE} component={PhysicianSignatureCapture} />
            <Route path={ROUTES.CLINIC_TEST_PROFILE} component={TestProfile} />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default App;
