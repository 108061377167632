import React, { useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { useAuth } from '../auth';
import { ButtonLoading } from '../Loading';
import { createNotification } from '../Notification';

import 'react-datepicker/dist/react-datepicker.css';

export const GENERATE_BATCH_LIST = gql`
  query GenerateBatchList(
    $startDate: String!,
    $endDate: String,
  ) {
    generateBatchList(
      startDate: $startDate,
      endDate: $endDate,
    )
  }
`;

const BatchDownloadReports = () => {
  const [startDate, setStartDate] = useState(new Date(new Date().setHours(0,0,0,0)));
  const [endDate, setEndDate] = useState(null);
  const [specimenIdList, setSpecimenIdList] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  // Require auth
  const authUser = useAuth({ messageType: 'info', messageText: 'Please log in' });

  const [generateBatchList] = useLazyQuery(
    GENERATE_BATCH_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted({ generateBatchList }) {
        console.log('useLazyQuery:', generateBatchList);
        setSpecimenIdList(generateBatchList);

        downloadReports(generateBatchList);
        // setLoading(false);
      },
      onError(error) {
        console.log('Generate Batch List', error);
      },
    },
  );

  const downloadReports = async (ids=[]) => {
    const specimenList = ids.length > 0 ? ids : specimenIdList;

    if (process.env.NODE_ENV === 'development') {
      console.log('Downloading reports...');
      console.log('Specimen ID List:', specimenList);
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_BATCH_DOWNLOAD_REPORTS_URL,
        { samples: specimenList },
        { responseType: 'blob' },
      );

      // console.log(response);

      const reportStartDate = formatDate(startDate);
      const reportEndDate = endDate ? formatDate(endDate) : null;

      fileDownload(
        response.data,
        reportEndDate ? `reports_${reportStartDate}_${reportEndDate}.zip` : `reports_${reportStartDate}.zip`,
      );

      history.goBack();
    } catch (error) {
      console.log('Report download', error);
      createNotification('danger', 'Sorry, could not download reports.');

      history.goBack();
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('Done downloading.');
    }

    setLoading(false);
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
  };

  const handleChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  const handleSubmit = () => {
    let start, end;

    // Format start/end dates
    // start = formatDate(startDate);
    start = startDate;

    if (endDate) {
      // const d = new Date(endDate.getTime());
      // end = formatDate(new Date(d.setHours(24,0,0,0)));
      end = new Date(endDate.setHours(24, 0, 0, 0));
    } else {
      // const d = new Date(startDate.getTime());
      // end = formatDate(new Date(d.setHours(24,0,0,0)));
      end = new Date(startDate.getTime());
      end.setDate(end.getDate() + 1);
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('Start:', start.toISOString());
      console.log('  End:', end.toISOString());
    }

    setLoading(true);

    generateBatchList({
      variables: {
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      },
    });
  };

  return (
    <div className="page">
      <h4 className="title is-4 is-inline-block mt-1">Batch Download Reports</h4>
      <br />

      <div className="block">
        <label className="label" htmlFor="input-dates">
          {/* Select Date(s) <br /> */}
          {/* <span className="has-text-weight-light">Select a start date, then select an end date.<br />Just pick a start date to download reports for that day only.</span> */}
          Select a single day, or a range of dates:
        </label>
        <DatePicker
          id="input-dates"
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={handleChange}
          selectsRange
          inline
        />
      </div>

      <button
        className="button is-primary"
        onClick={handleSubmit}
        disabled={loading}
      >
        Download Reports
      </button>
      {loading && <ButtonLoading />}
    </div>
  );
};

export default BatchDownloadReports;
