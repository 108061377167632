import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';

import SpecimenRejectedNotesModal from '../Dashboard/SpecimenRejectedNotesModal';
import Loading from '../Loading';

import { createNotification } from '../Notification';
import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/status';

export const GET_TOXICOLOGY_ORDERS_BY_PATIENT_ID = gql `
  query GetToxicologyOrdersByPatientId(
    $id: String!
  ) {
    getToxicologyOrdersByPatientId(
      id: $id
    ) {
      id
      clinicCode
      specimenId
      specimenType
      status
      createdDate
      specimenRunDate
      medicationListTags
      rejectSpecimenComment
      resultsReview {
        drugName
        result
      }
    }
  }
`;

const PatientHistory = () => {
  const [orders, setOrders] = useState([]);
  const [medicationList, setMedicationList] = useState([]);
  const [posMetabolites, setPosMetabolites] = useState([]);
  const [showRejectedNotesModal, setShowRejectedNotesModal] = useState(null);
  const [loading, setLoading] = useState(true);

  // Get Patient ID from URL
  const { id } = useParams();

  // Get orders
  useQuery(
    GET_TOXICOLOGY_ORDERS_BY_PATIENT_ID, {
      variables: { id },
      onCompleted({ getToxicologyOrdersByPatientId }) {
        if (process.env.NODE_ENV === 'development') console.log('Orders:', getToxicologyOrdersByPatientId);

        parseMedicationList(getToxicologyOrdersByPatientId);
        parsePosMetabolites(getToxicologyOrdersByPatientId);

        setOrders(getToxicologyOrdersByPatientId);
        setLoading(false);
      },
      onError(error) {
        console.log('Get Toxicology Orders by Patient ID', error);
        createNotification('danger', 'Sorry, could not get patient order history.');

        setLoading(false);
      },
    },
  );

  const handleShowRejectedNotesModal = (order) => {
    setShowRejectedNotesModal(order);
  };

  const handleCloseRejectedNotesModal = () => {
    setShowRejectedNotesModal(null);
  };

  const parseMedicationList = (orderDetails) => {
    const medList = [];
    const medications = new Set();

    // Find unique prescribed medications
    orderDetails.length > 0 && orderDetails.forEach(({ medicationListTags }) => {
      medicationListTags.forEach((med) => medications.add(med));
    });

    // Find orders with these meds
    medications.forEach((drugName) => {
      const medDetails = { drugName, orders: [] };

      orderDetails.forEach((order) => {
        if (order.medicationListTags.includes(drugName)) {
          medDetails.orders.push(order);
        }
      });

      medList.push(medDetails);
    });

    // Sort medList alphabetically by drugName


    setMedicationList(medList);
  };

  const parsePosMetabolites = (orderDetails) => {
    const metabolites = [];

    orderDetails.length > 0 && orderDetails.forEach((order) => {
      const pos = order.resultsReview.filter((test) => test.result !== 'ND' && test.result !== '');

      pos.forEach((drug) => {
        metabolites.push({
          drugName: drug.drugName,
          result: drug.result,
          specimenId: order.specimenId,
          clinicCode: order.clinicCode,
          collectionDate: order.createdDate,
          runDate: order.specimenRunDate,
        });
      })
    });

    setPosMetabolites(metabolites);
  };

  const renderOrderStatus = (status, order) => {
    // TODO: How many different statuses do we want to display, and which colors?

    // const green = '#66ff8c';
    const green = '#42C514';
    const yellow = '#CAB209';

    switch (status) {
      case STATUS.TOXICOLOGY_ANALYST_COMPLETE:
        return (
          <span className="has-text-success-dark">
            <span className="mr-2 icon is-small">
              <i className="fas fa-circle fa-small" aria-hidden="true"/>
            </span>
            Complete
          </span>
        );
      case STATUS.TOXICOLOGY_CLINIC_CREATED:
      case STATUS.TOXICOLOGY_LAB_RECEIVED:
      case STATUS.TOXICOLOGY_LAB_BATCHED:
      case STATUS.TOXICOLOGY_LAB_COMPLETE:
      case STATUS.TOXICOLOGY_LAB_RERUN:
      case STATUS.TOXICOLOGY_LAB_IN_PROCESS:
      case STATUS.TOXICOLOGY_ANALYST_PENDING:
      case STATUS.TOXICOLOGY_ANALYST_IN_PROCESS:
      case STATUS.TOXICOLOGY_ANALYST_REVIEW_IN_PROCESS:
      case STATUS.TOXICOLOGY_ANALYST_PENDING_IN_PROCESS:
      case STATUS.TOXICOLOGY_ANALYST_REVIEW:
        // TODO: Change color, yellow?
        return (
          <span className="has-text-warning-dark">
            <span className="mr-2 icon is-small">
              <i className="fas fa-circle fa-small" aria-hidden="true"/>
            </span>
            Pending
          </span>
        );
      case STATUS.TOXICOLOGY_LAB_REJECTED:
        return (
          <span
            className="has-text-danger is-clickable"
            style={{ whiteSpace: "nowrap", fontSize: "0.8rem" }}
            data-tooltip="View rejection reason"
            onClick={() => handleShowRejectedNotesModal(order)}
          >
            <span className="mr-2 icon is-small">
              <i className="fas fa-circle fa-small" aria-hidden="true"/>
            </span>
            Specimen Rejected
          </span>
        );
      case STATUS.ARCHIVE:
        return (
          <span
            className="has-text-info-dark"
            style={{ whiteSpace: "nowrap" }}
          >
            <span className="mr-2 icon is-small">
              <i className="fas fa-circle fa-small" aria-hidden="true"/>
            </span>
            Archived
          </span>
        );
      default:
        // TODO: Change color, red?
        return (
          <span style={{ color: green }}>
            <span className="mr-2 icon is-small">
              <i className="fas fa-circle fa-small" aria-hidden="true"/>
            </span>
            Other
          </span>
        );
    }
  };

  const renderResultsButton = (id, status, order) => {
    // Render info icon for rejected specimens
    if (status === STATUS.TOXICOLOGY_LAB_REJECTED) {
      return (
        <span
          className="is-clickable has-text-danger"
          style={{ fontSize: "0.8rem" }}
          data-tooltip="View rejection reason"
          onClick={() => handleShowRejectedNotesModal(order)}
        >
          <span className="icon is-small is-pulled-right">
            <i className="fas fa-info-circle" aria-hidden="true"/>
          </span>
        </span>
      );
    }

    // Only render button if order is complete
    if (status !== STATUS.TOXICOLOGY_ANALYST_COMPLETE) {
      return null;
    }

    return (
      <Link
        to={ROUTES.DASHBOARD_ORDER_RESULTS.replace(':id', id)}
        className="button is-info is-small"
      >
        View Result
      </Link>
    );
  };

  const generateTooltip = (orders) => {
    // const tooltip = orders.map((order) => (
    //   `${new Date(parseFloat(order.createdDate)).toDateString()}, ${order.specimenId}`
    // ));
    const tooltip = orders.map((order) => (
      `${new Date(parseFloat(order.createdDate))
        .toLocaleString([], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })}, ${order.specimenId}`
    ));

    return tooltip.join('\n');
  };

  // if (loading) return <Loading />;

  return (
    <div className="page">
      <span className="has-text-grey-dark has-text-weight-bold is-size-4">Patient History</span>
      <br/>
      <br />

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="mt-3 label">
            <span className="has-text-grey-dark">Medications:</span> {medicationList.map(({ drugName, orders }, i) => {
              if (drugName.toUpperCase() === 'NONE') {
                return null;
              }

              if (i+1 === medicationList.length) {
                return (
                  <span
                    key={i}
                    className="has-tooltip-arrow"
                    data-tooltip={generateTooltip(orders)}
                  >
                    {drugName}
                  </span>
                );
              } else {
                return (
                  <span
                    key={i}
                    className="has-tooltip-arrow"
                    data-tooltip={generateTooltip(orders)}
                  >
                    {`${drugName}, `}
                  </span>
                );
              }
            })}
          </div>

          <hr />

          <h6 className="title is-6">Orders</h6>

          <div style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
                <tr>
                  <th style={{ fontSize: "0.9rem" }}>Specimen ID</th>
                  {/* <th>Clinic Code</th> */}
                  <th style={{ fontSize: "0.9rem" }}>Type</th>
                  <th style={{ fontSize: "0.9rem" }}>Collection Date</th>
                  <th style={{ fontSize: "0.9rem" }}>Run Date</th>
                  <th style={{ fontSize: "0.9rem" }}>Status</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {orders.map((order) => (
                  <tr key={order.id}>
                    <td style={{ fontSize: "0.9rem" }}>{order.specimenId}</td>
                    {/* <td className="is-size-7">{order.clinicCode}</td> */}
                    <td style={{ fontSize: "0.9rem" }}>{order.specimenType}</td>
                    <td style={{ fontSize: "0.9rem" }}>{new Date(parseFloat(order.createdDate)).toDateString()}</td>
                    <td style={{ fontSize: "0.9rem" }}>{new Date(Date.parse(order.specimenRunDate)).toDateString() === 'Invalid Date' ? 'N/A' : new Date(Date.parse(order.specimenRunDate)).toDateString()}</td>
                    <td style={{ fontSize: "0.9rem", maxWidth: "120px" }}>{renderOrderStatus(order.status, order)}</td>
                    <td style={{ fontSize: "0.9rem" }}>{renderResultsButton(order.id, order.status, order)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <hr />

          <h6 className="title is-6">Previous Positive Results</h6>

          <div style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
                <tr>
                  <th>Drug/Metabolite</th>
                  <th>Value</th>
                  <th>Collection Date</th>
                </tr>
              </thead>

              <tbody>
                {posMetabolites.map((metab, i) => {
                  console.log(metab);
                  return (
                  <tr key={i}>
                    <td>{metab.drugName}</td>
                    <td>{metab.result}</td>
                    <td>{new Date(parseFloat(metab.collectionDate)).toDateString()}</td>
                  </tr>
                )
                })}
              </tbody>
            </table>
          </div>

          <SpecimenRejectedNotesModal
            isOpen={!!showRejectedNotesModal}
            handleCloseModal={handleCloseRejectedNotesModal}
            specimenId={showRejectedNotesModal?.specimenId}
            message={showRejectedNotesModal?.rejectSpecimenComment}
          />
        </>
      )}
    </div>
  );
};

export default PatientHistory;
