import React from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import ClinicUsersList from './ClinicUsersList';
import Loading from '../Loading';
import { EditTestProfileButton } from '../TestProfile';

import { createNotification } from '../Notification';
import { AUTH_USER_QUERY } from '../../constants/gql';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

const AdminPage = () => {
  const history = useHistory();

  // Get auth user
  const { data, loading } = useQuery(AUTH_USER_QUERY);
  const { authUser } = data;

  // Require auth and admin
  if (!authUser || !authUser.roles.includes(ROLES.CLINIC_ADMIN)) {
    // Create notification and redirect
    createNotification('info', 'Please log in');
    history.push(ROUTES.SIGN_IN);
  }

  if (loading) return <Loading />;

  return (
    <section className="section">
      <div className="container">
        <h3 className="title is-3">Settings</h3>

        <br />
        <EditTestProfileButton />

        <hr />
        <h4 className="title is-4">User Management</h4>
        <br />

        <ClinicUsersList />
      </div>
    </section>
  );
}

export default AdminPage;
