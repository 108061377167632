import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import SpecimenRejectedNotesModal from './SpecimenRejectedNotesModal';
import Loading from '../Loading';

import { createNotification } from '../Notification';
import { ME_CLINIC_USER } from '../Admin/ClinicUsersList';
import { useAuth } from '../auth';
import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/status';

const PAGINATION_LIMIT = 20;

export const COMPLETED_TOXICOLOGY_ORDERS = gql`
  query CompletedToxicologyOrders(
    $clinicCode: String!
  ) {
    completedToxicologyOrders(
      clinicCode: $clinicCode
    ) {
      id
      patientFirstName
      patientLastName
      patientId
      clinicCode
      specimenId
      specimenType
      createdDate
      resultsUploadedDate
      resultsReviewedDate
      clinicStatus
      status
      rejectSpecimenComment
    }
  }
`;

export const PAGINATED_COMPLETED_TOXICOLOGY_ORDERS = gql`
  query PaginatedCompletedToxicologyOrders(
    $clinicCode: String,
    $cursor: String,
    $limit: Int
  ) {
    paginatedCompletedToxicologyOrders(
      clinicCode: $clinicCode,
      cursor: $cursor,
      limit: $limit
    ) {
      id
      patientFirstName
      patientLastName
      patientId
      clinicCode
      specimenId
      specimenType
      clinicUserId
      radeasUserId
      createdDate
      resultsUploadedDate
      resultsReviewedDate
      status
      rejectSpecimenComment
    }
  }
`;

const CompletedOrders = () => {
  const [cursor, setCursor] = useState('');
  const [orders, setOrders] = useState([]);
  const [clinicCode, setClinicCode] = useState('');
  const [showRejectedNotesModal, setShowRejectedNotesModal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [moreData, setMoreData] = useState(true);

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  // Get user's clinic code
  useQuery(
    ME_CLINIC_USER,
    {
      onCompleted({ meClinicUser }) {
        const { clinicCode } = meClinicUser;

        setClinicCode(clinicCode);
        getCompletedOrders();
      },
      onError(error) {
        console.log('meClinicUser query error:', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      }
    }
  );

  // Get completed orders
//   const [getCompletedOrders, { loading: loadingToxicologyOrders }] = useLazyQuery(
//     COMPLETED_TOXICOLOGY_ORDERS,
//     {
//       fetchPolicy: 'network-only',
//       variables: {
//         clinicCode: clinicCode,
//       },
//       onCompleted({ completedToxicologyOrders }) {
//         // Sort in descending order
//         const orders = _.orderBy(completedToxicologyOrders, ['resultsUploadedDate'], ['desc']);
//
//         setOrders(orders);
//         setLoading(false);
//       },
//       onError(error) {
//         console.log('Completed toxicology orders error:', error);
//         createNotification('danger', 'Sorry, something went wrong. Please try again.');
//
//         setLoading(false);
//       }
//     }
//   );

  // Get completed orders
  const [getCompletedOrders, { data, error, fetchMore, loading: loadingToxicologyOrders }] = useLazyQuery(
    PAGINATED_COMPLETED_TOXICOLOGY_ORDERS,
    {
      // fetchPolicy: 'network-only',
      variables: {
        clinicCode: clinicCode,
        cursor: cursor,
        limit: PAGINATION_LIMIT,
      },
    }
  );

  useEffect(() => {
    if (data) {
      const { paginatedCompletedToxicologyOrders } = data;

      if (process.env.NODE_ENV === 'development') {
        console.log('data:', data);
        console.log('Query:', paginatedCompletedToxicologyOrders);
      }

      // Sort by completed date, descending (newest first)
      // const orders = _.orderBy(paginatedCompletedToxicologyOrders, ['resultsUploadedDate'], ['desc']);
      const orders = _.cloneDeep(paginatedCompletedToxicologyOrders);

      setOrders(orders);
      setLoading(false);

      if (paginatedCompletedToxicologyOrders.length % PAGINATION_LIMIT !== 0) {
        setMoreData(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.log('Completed toxicology orders error:', error);
      createNotification('danger', 'Sorry, something went wrong. Please try again.');

      setLoading(false);
    }
  }, [error]);

  const loadMore = () => {
    if (moreData) {
      const cursor = orders.at(-1).id;
      setCursor(cursor);

      if (process.env.NODE_ENV === 'development') {
        console.log('Loading more...');
        console.log(`Cursor: ${cursor}`);
      }

      fetchMore({
        variables: {
          cursor,
          length: PAGINATION_LIMIT,
        },
      });
    }
  };

  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${month}/${day}/${year+1}, ${parseInt(hour) > 12 ? hour-12 : hour}:${minute} ${parseInt(hour) > 12 ? "PM" : "AM"}`;
  };

  const handleShowRejectedNotesModal = (order) => {
    setShowRejectedNotesModal(order);
  };

  const handleCloseRejectedNotesModal = () => {
    setShowRejectedNotesModal(null);
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="page">
        <h4 className="title is-4 is-inline-block mt-1">Completed Orders</h4>

        <Link
          to={ROUTES.DASHBOARD_BATCH_DOWNLOAD_REPORTS}
          className="button is-primary is-pulled-right"
        >
          Batch Download Reports
        </Link>

        <br />

        {loading ? (
          <Loading />
        ) : (
          <>
            <div style={{ overflowX: "auto" }}>
              <table className="table is-fullwidth is-striped is-hoverable">
                <thead>
                  <tr>
                    <th style={{ fontSize: "0.9rem" }}>Patient ID</th>
                    <th style={{ fontSize: "0.9rem" }}>Patient Name</th>
                    <th style={{ fontSize: "0.9rem" }}>Specimen ID</th>
                    <th style={{ fontSize: "0.9rem" }}>Type</th>
                    <th style={{ fontSize: "0.9rem" }}>Collection Date</th>
                    <th style={{ fontSize: "0.9rem" }}>Processed Date</th>
                    <th />
                  </tr>
                </thead>

                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td className="has-text-dark" style={{ fontSize: "0.8rem" }}>{order.patientId}</td>
                      <td className="has-text-dark" style={{ fontSize: "0.8rem" }}>{order.patientLastName}, {order.patientFirstName}</td>
                      <td className="has-text-dark" style={{ fontSize: "0.8rem" }}>{order.specimenId}</td>
                      <td className="has-text-dark" style={{ fontSize: "0.8rem" }}>{order.specimenType}</td>
                      <td className="has-text-dark" style={{ fontSize: "0.7rem" }}>
                        {new Date(parseInt(order.createdDate)).toDateString()}
                        {/* {new Date(parseInt(order.createdDate)) */}
                        {/*   .toLocaleString([], { */}
                        {/*     year: 'numeric', */}
                        {/*     month: 'numeric', */}
                        {/*     day: 'numeric', */}
                        {/*     hour: '2-digit', */}
                        {/*     minute: '2-digit', */}
                        {/*   }) */}
                        {/* } */}
                      </td>
                      <td className="has-text-dark" style={{ fontSize: "0.7rem" }}>
                        {/* {new Date(parseInt(order.resultsUploadedDate)).toDateString() === 'Invalid Date' ? 'N/A' : new Date(parseInt(order.resultsUploadedDate)).toDateString()} */}
                        {new Date(parseInt(order.resultsReviewedDate)).toDateString() === 'Invalid Date' ? 'N/A' : new Date(parseInt(order.resultsReviewedDate)).toDateString()}
                        {/* {new Date(parseInt(order.resultsUploadedDate)) */}
                        {/*   .toLocaleString([], { */}
                        {/*     year: 'numeric', */}
                        {/*     month: 'numeric', */}
                        {/*     day: 'numeric', */}
                        {/*     hour: '2-digit', */}
                        {/*     minute: '2-digit', */}
                        {/*   })} */}
                      </td>
                      <td style={{textAlign: "center"}}>
                        {order.status === STATUS.TOXICOLOGY_ANALYST_COMPLETE ? (
                          <Link
                            to={ROUTES.DASHBOARD_ORDER_RESULTS.replace(':id', order.id)}
                            className="button has-text-info is-small"
                          >
                            View Result
                          </Link>
                        ) : (
                          // <span className="has-text-danger" style={{ fontSize: "0.8rem" }}>
                          //   Specimen Rejected
                          // </span>
                          <span
                            className="is-clickable has-text-danger"
                            style={{ fontSize: "0.8rem" }}
                            data-tooltip="View rejection reason"
                            onClick={() => handleShowRejectedNotesModal(order)}
                          >
                            <span className="mr-1 icon is-small">
                              <i className="fas fa-info-circle" aria-hidden="true"/>
                            </span>
                            Specimen Rejected
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {moreData && (
                <div className="mt-6 has-text-centered">
                  <button
                    className="button is-info"
                    onClick={loadMore}
                    disabled={!moreData}
                  >
                    Load More
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <SpecimenRejectedNotesModal
        isOpen={!!showRejectedNotesModal}
        handleCloseModal={handleCloseRejectedNotesModal}
        specimenId={showRejectedNotesModal?.specimenId}
        message={showRejectedNotesModal?.rejectSpecimenComment}
      />
    </>
  );
};

export default CompletedOrders;
