import React, { useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { NewPatientButton } from './';
import Loading from '../Loading';
import { createNotification } from '../Notification';
import { useAuth } from '../auth';
import * as ROUTES from '../../constants/routes';

const GET_CLINIC_CODE = gql`
  query ClinicCode {
    meClinicUser {
      clinicCode
    }
  }
`;

const PATIENTS_BY_CLINIC = gql`
  query PatientsByClinic(
    $clinicCode: String!
  ) {
    patientsByClinic(
      clinicCode: $clinicCode
    ) {
      id
      patientId
      firstName
      lastName
      birthMonth
      birthDay
      birthYear
      email
      phone
    }
  }
`;

const PatientList = () => {
  const [clinicCode, setClinicCode] = useState('');
  const [patients, setPatients] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  // Get clinic code
  useQuery(
    GET_CLINIC_CODE,
    {
      fetchPolicy: 'network-only',
      onCompleted({ meClinicUser }) {
        setClinicCode(meClinicUser.clinicCode);
        getPatients({ variables: { clinicCode: meClinicUser.clinicCode } });
      },
      onError(error) {
        createNotification('danger', 'Sorry, unable to retrieve clinic code.');
        console.log('Error getting clinic code:', error);
      }
    }
  );

  const [getPatients, { loading: loadingPatients, data: patientsData }] = useLazyQuery(
    PATIENTS_BY_CLINIC,
    {
      fetchPolicy: 'network-only',
      onCompleted({ patientsByClinic }) {
        setPatients(patientsByClinic);

        setLoading(false);
      },
      onError(error) {
        createNotification('danger', 'Sorry, unable to retrieve patients.');
        console.log('Get patients query', error);
      }
    },
  );

  const formatBirthday = (patient) => {
    return `${patient.birthMonth}/${patient.birthDay}/${patient.birthYear}`;
  };

  const handleClick = (patient) => {
    history.push(ROUTES.PATIENT_DETAILS.replace(':id', patient.id));
  };

  const generatePatients = () => {
    if (filter.length === 0) {
      return generateRows(patients);
    } else {
      const filtered = patients.filter((patient) => {
        const birthday = `${patient.birthMonth}/${patient.birthDay}/${patient.birthYear}`;
        const birthdayNoZeros = `${parseInt(patient.birthMonth)}/${parseInt(patient.birthDay)}/${parseInt(patient.birthYear)}`;

        return (
          patient.patientId.toUpperCase().includes(filter.toUpperCase()) ||
          patient.firstName.toUpperCase().includes(filter.toUpperCase()) ||
          patient.lastName.toUpperCase().includes(filter.toUpperCase()) ||
          patient.email.toUpperCase().includes(filter.toUpperCase()) ||
          patient.phone.includes(filter) ||
          patient.phone.replace(/-/g, '').includes(filter) ||
          birthday.includes(filter) ||
          birthdayNoZeros.includes(filter)
        );
      });

      return generateRows(filtered);
    }
  };

  const generateRows = (p) => {
    return (
      // p.map((patient) => (
      //   <tr key={patient.id}>
      //     <td>{patient.lastName}</td>
      //     <td>{patient.firstName}</td>
      //     <td>{patient.patientId}</td>
      //     <td>{patient.birthMonth}/{patient.birthDay}/{patient.birthYear}</td>
      //     <td>{patient.email}</td>
      //     <td>{patient.phone}</td>
      //     <td style={{textAlign: "right"}}>
      //       <button
      //         className="button has-text-info"
      //         onClick={() => handleSelectPatient(patient)}
      //       >
      //         Select
      //       </button>
      //     </td>
      //   </tr>
      // ))

      p.map((patient) => (
        <tr
          key={patient.id}
          onClick={() => handleClick(patient)}
          className="is-clickable"
        >
          <td className="has-text-dark">{patient.lastName}</td>
          <td className="has-text-dark">{patient.firstName}</td>
          <td className="has-text-dark">{patient.patientId}</td>
          <td className="has-text-dark">{formatBirthday(patient)}</td>
          <td className="has-text-dark">{patient.email}</td>
          <td className="has-text-dark">{patient.phone}</td>
        </tr>
      ))
    );
  };

  if (loading) return <Loading />;

  return (
    <div className="page">
      <NewPatientButton />
      <h4 className="title is-4 is-inline-block mt-1">Patients</h4>

      <div className="my-5">
          <div className="columns">
            <div className="column is-one-third">
              <div className="field is-grouped">
                <div className="control is-expanded">
                  <input
                    name="filter"
                    type="text"
                    className="input"
                    placeholder="Search patients"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </div>
                <div className="control">
                  <button
                    className="button is-info"
                    onClick={() => setFilter('')}
                    disabled={filter === '' ? true : false}
                  >
                    <span className="icon is-small mr-2">
                      <i className="fas fa-times-circle" />
                    </span>
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div style={{ overflowX: "auto" }}>
        <table className="table is-fullwidth is-striped is-hoverable">
          <thead>
            <tr>
              <th>Last Name</th>
              <th>First Name</th>
              <th>ID</th>
              <th>Birthday</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>

          <tbody>
            {generatePatients()}
          </tbody>

          {/* <tbody> */}
          {/*   {patients.map((patient) => ( */}
          {/*     <tr */}
          {/*       key={patient.id} */}
          {/*       onClick={() => handleClick(patient)} */}
          {/*       className="is-clickable" */}
          {/*     > */}
          {/*       <td>{patient.lastName}</td> */}
          {/*       <td>{patient.firstName}</td> */}
          {/*       <td>{patient.patientId}</td> */}
          {/*       <td>{formatBirthday(patient)}</td> */}
          {/*       <td>{patient.email}</td> */}
          {/*       <td>{patient.phone}</td> */}
          {/*     </tr> */}
          {/*   ))} */}
          {/* </tbody> */}
        </table>
      </div>
    </div>
  );
};

export default PatientList;
