import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup";
import _ from 'lodash';

import PatientProfile from "./PatientProfile";
import PatientHistory from "./PatientHistory";

import { ButtonLoading } from '../Loading';
import { useAuth } from "../auth";
import * as ROUTES from '../../constants/routes';

import '../../styles/modal.css';

const PatientPage = () => {
  const [patient, setPatient] = useState({});

  // Require auth
  useAuth({ messageType: "info", messageText: "Please log in" });

  const handlePatient = (p) => {
    setPatient(p);
  };

  return (
    <section className="section">
      <h1 className="title is-size-4">Patient Details</h1>

      <div className="">
        {_.isEmpty(patient) ? (
          <ButtonLoading />
        ) : (
          <Link
            className="button is-info mb-4"
            to={{
              pathname: ROUTES.DASHBOARD_CREATE_TOXICOLOGY_ORDER,
              state: { patient },
            }}
          >
            Order Test
          </Link>
        )}
      </div>

      <div className="columns">
        <div className="column is-one-third-desktop is-one-third-tablet">
          <PatientProfile
            handlePatient={handlePatient}
          />
        </div>

        <div className="column is-two-thirds-desktop is-two-thirds-tablet">
          <PatientHistory />
          <br />
          <br />
        </div>
      </div>
    </section>
  );
};

const DeletePatientModal = () => {
  return (
    <Popup
      trigger={
        <button
          className="button is-danger mt-4"
        >
          Delete Patient
        </button>
      }
    >

    </Popup>
  );
};

export default PatientPage;
