import React, { useRef, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';
import SignaturePad from 'react-signature-canvas';

import { createNotification } from '../Notification';

import '../TestProfile/signature.css';

export const SAVE_PATIENT_SIGNATURE = gql`
  mutation SetSignature(
    $foreignKey: String!
    $specimenId: String
    $type: String
    $signature: String
  ) {
    setSignature(
      data: {
        foreignKey: $foreignKey,
        specimenId: $specimenId,
        type: $type,
        signature: $signature
      }
    ) {
      foreignKey
    }
  }
`;

const PatientSignatureCapture = () => {
  const [sigImage, setSigImage] = useState(null);

  const { specimen_id, patient_name } = useParams();

  const signatureRef = useRef(null);

  const [saveSignatureMutation, { loading: savingSignature }] = useMutation(
    SAVE_PATIENT_SIGNATURE,
    {
      onCompleted({ setSignature }) {
        console.log(`Signature saved:`, setSignature);
        createNotification('info', 'Signature saved, you may close this page now.');
      },
      onError(error) {
        // TODO: Use notification
        console.log('Save signature', error);
      }
    },
  );

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = (close) => {
    const signature = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');

    setSigImage(signature);

    const variables = {
      foreignKey: specimen_id,
      specimenId: specimen_id,
      type: 'Patient',
      signature,
    };

    if (process.env.NODE_ENV === 'development') {
      console.log('variables:', variables);
    }

    // Save the signature
    saveSignatureMutation({ variables });

    close();
  };

  const deleteSignature = (close) => {
    setSigImage(null);
    close && close();
  };

  return (
    <div className="container">
      <h3 className="title is-3 ml-5">Patient Consent/Insurance Release</h3>
      <p className="ml-5 mt-3"><strong>{patient_name}, {specimen_id}</strong></p>
      <p className="ml-5 mt-3">I consent to the collection and testing of my specimen. I certify that the specimen identified on this form is my own, and that the specimen is fresh and free from any adulteration or contamination. I certify that the information provided on this form and on the specimen label is accurate.</p>
      <p className="ml-5 mt-3">I understand my signature requests that payment of authorized insurance or Medicare benefits be made either to or on my behalf to Radeas for the urine drug testing services furnished to me by the physician. I acknowledge that Radeas may be an out of network facility with my insurance provider.</p>
      <p className="ml-5 mt-3">I authorize any holder of medical information about me to release to the insurance company or to CMS (Centers for Medicare and Medicaid Services), and it’s agents any information needed to determine these benefits or the benefits payable to related services. This assignment will remain in effect until revoked by me in writing. A photocopy of this assignment is to be considered as valid as an original.</p>
      <p className="ml-5 mt-3">I understand that I am financially responsible for all charges, whether or not paid by said insurance. I consent to receiving electronic statements. I am also aware that in some circumstances my insurance provider will send the payment directly to me for services provided. Under law, I agree to endorse the insurance check and forward it to Radeas, within 30 days of receipt. Failure to do so could result in my account being forwarded to a collections agency and reported to the Credit Bureau. I authorize Radeas to release the results of this screening to the ordering facility.</p>
      <p className="ml-5 mt-3"><strong>I acknowledge Radeas LLC will perform confirmatory & presumptive analysis, as medically necessary.</strong></p>

      <Popup
        modal
        trigger={<p className="button is-info is-outlined mx-6 my-4">Sign</p>}
        closeOnDocumentClick={false}
      >
        {(close) => (
          <>
            <SignaturePad
              ref={signatureRef}
              canvasProps={{ className: "signatureCanvas" }}
            />

            <div
              className="p-3"
              style={{ border: "1px solid black" }}
            >
              <button
                className="button is-info mr-6"
                onClick={() => saveSignature(close)}
              >
                Save
              </button>
              <button
                className="button is-info is-outlined mr-6"
                onClick={clearSignature}
              >
                Clear
              </button>
              <button
                className="button is-danger"
                onClick={close}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </Popup>

      {sigImage && (
        <img
          src={sigImage}
          alt="Signature"
          style={{
            display: "block",
            margin: "0 auto",
            border: "1px solid black",
            width: "35%",
          }}
        />
      )}
    </div>
  );
};

export default PatientSignatureCapture;
