import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";

import PatientInfoForm from "./PatientInfoForm";

import Loading from "../Loading";
import { createNotification } from "../Notification";

export const GET_PATIENT_BY_ID = gql`
  query GetPatientById($id: String!) {
    getPatientById(id: $id) {
      id
      patientId
      clinicCode
      officeMrn
      firstName
      lastName
      middleName
      sex
      birthMonth
      birthDay
      birthYear
      email
      phone
      address1
      address2
      city
      state
      zipCode
      ethnicity
      race
      maritalStatus
      responsiblePartyRelationship
      responsiblePartyFirstName
      responsiblePartyLastName
      responsiblePartyMiddleName
      responsiblePartyBirthMonth
      responsiblePartyBirthDay
      responsiblePartyBirthYear
      responsiblePartySex
      responsiblePartyAddress1
      responsiblePartyAddress2
      responsiblePartyCity
      responsiblePartyState
      responsiblePartyZipCode
      responsiblePartyPhone
      responsiblePartyEmail
      patientInsurance
      primaryInsuranceCompany
      primaryInsurancePolicyNumber
      primaryInsuranceGroupNumber
      primaryInsuranceSubscriberRelationship
      additionalInsurance {
        companyName
        policyNumber
        groupNumber
      }
      createdDate
      updatedDate
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient(
    $id: String!
    $patientId: String
    $officeMrn: String
    $firstName: String
    $lastName: String
    $middleName: String
    $sex: String
    $birthMonth: String
    $birthDay: String
    $birthYear: String
    $email: String
    $phone: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zipCode: String
    $ethnicity: String
    $race: String
    $maritalStatus: String
    $responsiblePartyRelationship: String
    $responsiblePartyFirstName: String
    $responsiblePartyLastName: String
    $responsiblePartyMiddleName: String
    $responsiblePartyBirthMonth: String
    $responsiblePartyBirthDay: String
    $responsiblePartyBirthYear: String
    $responsiblePartySex: String
    $responsiblePartyAddress1: String
    $responsiblePartyAddress2: String
    $responsiblePartyCity: String
    $responsiblePartyState: String
    $responsiblePartyZipCode: String
    $responsiblePartyPhone: String
    $responsiblePartyEmail: String
    $patientInsurance: String
    $primaryInsuranceCompany: String
    $primaryInsurancePolicyNumber: String
    $primaryInsuranceGroupNumber: String
    $primaryInsuranceSubscriberRelationship: String
    $additionalInsurance: [AdditionalInsuranceInput]
  ) {
    updatePatient(
      id: $id
      data: {
        patientId: $patientId
        officeMrn: $officeMrn
        firstName: $firstName
        lastName: $lastName
        middleName: $middleName
        sex: $sex
        birthMonth: $birthMonth
        birthDay: $birthDay
        birthYear: $birthYear
        email: $email
        phone: $phone
        address1: $address1
        address2: $address2
        city: $city
        state: $state
        zipCode: $zipCode
        ethnicity: $ethnicity
        race: $race
        maritalStatus: $maritalStatus
        responsiblePartyRelationship: $responsiblePartyRelationship
        responsiblePartyFirstName: $responsiblePartyFirstName
        responsiblePartyLastName: $responsiblePartyLastName
        responsiblePartyMiddleName: $responsiblePartyMiddleName
        responsiblePartyBirthMonth: $responsiblePartyBirthMonth
        responsiblePartyBirthDay: $responsiblePartyBirthDay
        responsiblePartyBirthYear: $responsiblePartyBirthYear
        responsiblePartySex: $responsiblePartySex
        responsiblePartyAddress1: $responsiblePartyAddress1
        responsiblePartyAddress2: $responsiblePartyAddress2
        responsiblePartyCity: $responsiblePartyCity
        responsiblePartyState: $responsiblePartyState
        responsiblePartyZipCode: $responsiblePartyZipCode
        responsiblePartyPhone: $responsiblePartyPhone
        responsiblePartyEmail: $responsiblePartyEmail
        patientInsurance: $patientInsurance
        primaryInsuranceCompany: $primaryInsuranceCompany
        primaryInsurancePolicyNumber: $primaryInsurancePolicyNumber
        primaryInsuranceGroupNumber: $primaryInsuranceGroupNumber
        primaryInsuranceSubscriberRelationship: $primaryInsuranceSubscriberRelationship
        additionalInsurance: $additionalInsurance
      }
    ) {
      id
      patientId
      clinicCode
      officeMrn
      firstName
      lastName
      middleName
      sex
      birthMonth
      birthDay
      birthYear
      email
      phone
      address1
      address2
      city
      state
      zipCode
      ethnicity
      race
      maritalStatus
      responsiblePartyRelationship
      responsiblePartyFirstName
      responsiblePartyLastName
      responsiblePartyMiddleName
      responsiblePartyBirthMonth
      responsiblePartyBirthDay
      responsiblePartyBirthYear
      responsiblePartySex
      responsiblePartyAddress1
      responsiblePartyAddress2
      responsiblePartyCity
      responsiblePartyState
      responsiblePartyZipCode
      responsiblePartyPhone
      responsiblePartyEmail
      patientInsurance
      primaryInsuranceCompany
      primaryInsurancePolicyNumber
      primaryInsuranceGroupNumber
      primaryInsuranceSubscriberRelationship
      additionalInsurance {
        companyName
        policyNumber
        groupNumber
      }
      createdDate
      updatedDate
    }
  }
`;

const PatientProfile = ({ handlePatient }) => {
  const [patientInfo, setPatientInfo] = useState({});
  const [editPatientInfo, setEditPatientInfo] = useState(false);
  const [loading, setLoading] = useState(true);

  // Get Patient ID from URL
  const { id } = useParams();

  const history = useHistory();

  // Run patient query
  useQuery(GET_PATIENT_BY_ID, {
    variables: { id },
    onCompleted({ getPatientById }) {
      // if (process.env.NODE_ENV === 'development') console.log(getPatientById);

      // Pass patient info to parent
        handlePatient(getPatientById);

      setPatientInfo(getPatientById);
      setLoading(false);
    },
    onError(error) {
      console.log("Patient Page query error:", error);
      createNotification("danger", "Sorry, could not get patient details.");

      setLoading(false);
    },
  });

  const [updatePatient, { loading: loadingMutation }] = useMutation(
    UPDATE_PATIENT,
    {
      onCompleted({ updatePatient }) {
        // console.log(updatePatient);

        setPatientInfo(updatePatient);
        setEditPatientInfo(false);
      },
      onError(error) {
        // TODO: create notification
        console.log("Update patient mutation", error);
      },
    }
  );

  const handleSubmit = (values) => {
    // console.log('handleSubmit:', values);

    updatePatient({
      variables: {
        id,
        ...values,
      },
    });
  };

  const handleCancel = (reload=false) => {
    setEditPatientInfo(false);

    if (reload) {
      // Reload the page
      history.go(0);
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="page">
      <span className="has-text-grey-dark has-text-weight-bold is-size-4">
        Patient Profile
      </span>

      <button className="button is-ghost is-pulled-right" type="button">
        <span
          className="icon is-cobalt"
          title="Edit Patient Information"
          onClick={() => setEditPatientInfo(true)}
        >
          <i className="fas fa-edit" />
        </span>
      </button>

      <h1 className="title has-text-primary is-size-2 mt-3">
        {patientInfo.firstName} {patientInfo.middleName} {patientInfo.lastName}
      </h1>
      <span className="sublabel is-4 mr-3">Clinic </span>
      <span><b>{patientInfo.clinicCode}</b></span>

      {/* <br /> */}
      {/* <br /> */}
      {/* <span className="sublabel is-4 mr-3">Clinic's Patient ID </span> */}
      {/* <span><b>{patientInfo.patientId}</b></span> */}

      {/*      <span className="sublabel is-4 mr-3">
        Date Created
      </span>
      <span className="has-text-white">
        {new Date(parseInt(patientInfo.createdDate))
          .toLocaleString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })
        }
      </span>

      <br /><br />

      <span className="sublabel is-4 mr-3">
        Last Modified
      </span>
      <span className="has-text-white">
        {patientInfo.updatedDate && new Date(parseInt(patientInfo.updatedDate))
          .toLocaleString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })
        }
      </span>*/}

      <br/><br/>
      <PatientInfoForm
        patientInfo={patientInfo}
        isEditing={editPatientInfo}
        isUpdating={loadingMutation}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
      />
    </div>
  );
};

export default PatientProfile;
