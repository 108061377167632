import React from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import SignOutButton from './auth/SignOutButton';

import { AUTH_USER_QUERY } from '../constants/gql';
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';

const SideNavigation = () => {
  // Get authUser
  const { data } = useQuery(AUTH_USER_QUERY);
  const { authUser } = data;

  return (authUser ?
      <div className="column is-one-quarter is-one-fifth-desktop side-nav">
        <div className="p-4" >
          <h1 className="title is-size-2 has-text-centered">Radeas Labs</h1>

          <div className="ml-6">
            <div className="my-4">
              <span className="mr-3 icon is-small"><i className="fas fa-vial" aria-hidden="true" /></span>
              <span className="title is-6">Toxicology Orders</span>
              {/*<span className="icon is-small is-pulled-right"><i className="fas fa-angle-up" aria-hidden="true" /></span>*/}

              <div className="ml-5">
                <p className="my-1 ml-1">
                  <Link to={ROUTES.DASHBOARD_CREATE_TOXICOLOGY_ORDER} className="side-nav-sub-link">Place New Order</Link>
                </p>
              </div>

              <div className="ml-5">
                <p className="my-1 ml-1">
                  <Link to={ROUTES.DASHBOARD_PENDING_ORDERS} className="side-nav-sub-link">Active</Link>
                </p>
                <p className="my-1 ml-1">
                  <Link to={ROUTES.DASHBOARD_COMPLETED_ORDERS} className="side-nav-sub-link">Completed</Link>
                </p>
              </div>
            </div>

            <div className="my-4">
              <Link to={ROUTES.PATIENT_LIST}>
                <span className="mr-3 icon has-text-black is-small"><i className="fas fa-user-injured" aria-hidden="true" /></span>
                <span className="title is-6">Patients</span>
              </Link>
            </div>

            <div className="my-4">
              <Link to={ROUTES.ACCOUNT}>
                <span className="mr-3 icon has-text-black is-small"><i className="fas fa-user-circle" aria-hidden="true" /></span>
                <span className="title is-6">Account</span>
              </Link>
            </div>

            <div className="my-4">
              {!!authUser.roles.includes(ROLES.CLINIC_ADMIN) && (
                <Link to={ROUTES.CLINIC_TEST_PROFILE}>
                  <span className="mr-3 icon has-text-black is-small"><i className="fas fa-cog" aria-hidden="true" /></span>
                  <span className="title is-6">Settings</span></Link>
              )}
            </div>

            <div className="my-5">
              <span className="ml-5 has-text-grey">Support (919) 263-1150</span>
            </div>

            <div className="mt-5 ml-1">
              <SignOutButton/>
            </div>
          </div>
        </div></div>
      : null

  );
}

export default SideNavigation;
