import _ from 'lodash';

const ILLICIT_DRUGS = [
  'Methamphetamine',
  '6-MAM',
  'Benzoylecgonine',
  'MDA',
  'MDMA',
  'MDPV',
  'PCP',
  'Carboxy-THC',
  'JWH-018',
];

const FLAGS = {
  0: "Illicit drug detected",
  1: "Illicit drug detected, D-Isomer detected",
  2: "Possible contaminant of Amphetamine",
  3: "Indicated med not detected",
  4: "Indicated med not detected, metabolite detected",
  5: "Non-indicated med detected",
  6: "Non-indicated substance detected",
  7: "Nicotine metabolite detected",
  8: "Placental hormone detected",
  9: "Large amounts of medication present without metabolites. May indicate direct addition of drug into sample",
};

class DrugReport {
  constructor(data) {
    this.data = data;
    this.drugMetabolites = data.drugMetabolites || [];
    this.medicationList = (data.medicationList && data.medicationList.map((name) => name.toUpperCase())) || [];
    this.medicationListMetabolites = {};
    this.testList = (data.toxicologyTests && data.toxicologyTests.map(({ drugName }) => drugName.toUpperCase())) || [];
    this.testListMetabolites = {};
    this.allTestsAndMetabolites = [];
    this.illicitDrugs = (data.illicitDrugs && data.illicitDrugs.map((name) => name.toUpperCase())) || ILLICIT_DRUGS.map((name) => name.toUpperCase());
    this.drugNames = [];
    this.medicationComparisonSummary = {
      medicationConsistent: [],
      medicationInconsistentRxNotFound: [],
      medicationInconsistentNotPrescribed: [],
    };

    // Find drugName for drugBrand
    this.medicationList.forEach((drugBrand) => {
      this.getDrugNameForDrugBrand(drugBrand);
    });

    // Drug names only
    this.getDrugNamesOnlyFromMedicaitonList();

    // Find metabolites of drugs in the medication list
    this.medicationList.forEach((drugName) => {
      const metabolites = this.getAllMetabolites(drugName);

      if (drugName.toUpperCase() === 'DIAZEPAM') {
        this.medicationListMetabolites[drugName] = new Set(
          ['Nordiazepam', 'Temazepam', 'Oxazepam'].map((name) => name.toUpperCase())
        );
      } else {
        this.medicationListMetabolites[drugName] = metabolites;
      }
    });

    // Find metabolites of drugs in toxicology tests
    this.testList.forEach((drugName) => {
      const metabolites = this.getAllMetabolites(drugName);

      this.testListMetabolites[drugName] = metabolites;
    });

    const metabSet = new Set();

    for (const drugName in this.testListMetabolites) {
      metabSet.add(drugName.toUpperCase());

      this.testListMetabolites[drugName].forEach((metab) => {
        metabSet.add(metab.toUpperCase());
      });
    }

    // Set all tests and child metabolites
    this.allTestsAndMetabolites = Array.from(metabSet);
  }

  static splitDrugList(drugList, delimiter=',') {
    return drugList.split(delimiter).map((item) => (item.toUpperCase().trim()));
  }

  getDrugNameForDrugBrand(drugBrand) {
    const names = new Set();
    const brand = drugBrand.toUpperCase();

    const drugNames = this.data.toxicologyTests.forEach((test) => {
      const brands = test.drugBrand.split(',').map((n) => (n.trim().toUpperCase()));

      if (brands.includes(brand)) {
        names.add(test.drugName.toUpperCase());
      }
    });

    // Special case for Klonopin
    if (brand === 'Klonopin'.toUpperCase() || brand === 'Clonazepam'.toUpperCase()) {
      names.add('Clonazepam (7-Amino)'.toUpperCase());
    }

    // Special case for Suboxone or Zubsolv (combo of Buprenorphine and Naloxone)
    if (brand === 'Suboxone'.toUpperCase() || brand === 'Zubsolv'.toUpperCase()) {
      names.add('NALOXONE');
      names.add('BUPRENORPHINE');
    }

    const namesArray = Array.from(names);

    // this.medicationList.push(...names);
    this.medicationList.push(...namesArray);
  }

  getDrugNamesOnlyFromMedicaitonList() {
    const names = new Set();

    this.data.toxicologyTests.forEach((test) => {
      const drugName = test.drugName.toUpperCase();

      const idx = _.findIndex(this.medicationList, (name) => (name === drugName));

      if (idx > -1) {
        names.add(drugName);
      }
    });

    this.drugNames = Array.from(names);
  }

  getMetabolites(drug) {
    return this.drugMetabolites.find((d) => (d.drugName.toUpperCase() === drug.toUpperCase()));
  }

  getAllMetabolites(drug) {
    const set = new Set();

    this.getChildren(drug, set);

    return set;
  }

  getChildren(drug, set) {
    const { metabolites = [] } = this.getMetabolites(drug) || {};

    metabolites.forEach((name) => {
      set.add(name.toUpperCase());

      // If "glucuronide", also add "Metab."
      if (name.toUpperCase().includes('GLUCURONIDE')) {
        set.add(name.toUpperCase().replace('GLUCURONIDE', 'METAB.'));
      }

      this.getChildren(name, set);
    });
  }

  parseValue(val) {
    if (!val) {
      return val;
    }

    const test = val.toUpperCase().trim();

    if (test === 'ND') {
      return test;
    }

    if (test[0] === '>') {
      const num = test.split('>')[1].trim();

      return parseFloat(num);
    }

    return parseFloat(test.trim());
  }

  checkPosNeg({ value, cutoff }) {
    const val = this.parseValue(value);

    if (isNaN(val)) {
      return 'NEG';
    } else {
      // if (val >= parseFloat(cutoff)) {
      if (val > parseFloat(cutoff)) {
        return 'POS';
      }
    }

    return 'NEG';
  }

  checkCI({ drugName, value, cutoff }) {
    const val = this.parseValue(value);

    // Check if drug is in medication list
    if (this.medicationList.includes(drugName.toUpperCase())) {
      if (isNaN(val)) {
        return 'I';
      } else {
        if (val > 0) {
        // if (parseFloat(value) >= parseFloat(cutoff)) {
          return 'C';
        } else {
          return 'I';
        }
      }
    }

    // Check if drug is a metabolite of a drug in medication list
    for (const drug in this.medicationListMetabolites) {
      if (this.medicationListMetabolites[drug].has(drugName.toUpperCase())) {
        if (isNaN(val)) {
          return 'C';
        }

        // Metabolite of parent drug found in medication list
        // if (val >= parseFloat(cutoff)) {
        if (val > parseFloat(cutoff)) {
          return 'C';
        } else {
          // TODO: What happens if it's "ND" or under cutoff?
          // Should ALL metabolites of prescribed parent drug show up, or is ND ok and still C?
          // If a metabolite os a parent prescribed drug shows up, but under cutoff, is that C or I?
          return 'C';
        }
      }
    }

    // Check if value is above cutoff
    // if (!isNaN(val) && val >= parseFloat(cutoff)) {
    if (!isNaN(val) && val > parseFloat(cutoff)) {
      return 'I';
    }

    return 'C';
  }

  checkFlags({ drugName, value, cutoff }) {
    const val = this.parseValue(value);

    if (val <= this.parseValue(cutoff)) {
      return [];
    }

    // Check EtH flag
    if (drugName.substring(0, 5).toUpperCase().includes('ETHYL')) {
      const etgFlag = this.checkEtgFlag({ drugName, val, cutoff: parseFloat(cutoff) });
      if (etgFlag) {
        return [etgFlag];
      } else {
        return [];
      }
    }

    // Check illicit drug flag
    const illicitFlag = this.checkIllicitDrugFlag({ drugName, val });
    if (illicitFlag) {
      return [illicitFlag];
    }

    // Check indicated medication flag
    const indicatedDrug = this.checkIndicatedDrugFlag({ drugName, val });
    if (indicatedDrug) {
      return [indicatedDrug];
    }

    // Check non-indicated meds (not illicit)
    const nonIndicatedMedFlag = this.checkNonIndicatedMedFlag({ drugName, val });
    if (nonIndicatedMedFlag) {
      return [nonIndicatedMedFlag];
    }

    return [];
  }

  checkIllicitDrugFlag({ drugName, val }) {
    if (!isNaN(val) && val > 0) {
      if (this.illicitDrugs.includes(drugName.toUpperCase())) {
        return FLAGS['0'];
      }
    }

    return null;
  }

  checkNonIndicatedMedFlag({ drugName, val }) {
    if (!isNaN(val) && val > 0) {
      return FLAGS['5'];
    }

    return null;
  }

  checkIndicatedDrugFlag({ drugName, val }) {
    // Check if drugName is in medicationList
    if (!isNaN(val) && this.medicationList.includes(drugName.toUpperCase())) {
      return [];
    }

    if ((isNaN(val) || val === 0) && this.medicationList.includes(drugName.toUpperCase())) {
      return FLAGS['3'];
    } else {
      const metabs = [];

      for (const key in this.medicationListMetabolites) {
        this.medicationListMetabolites[key].forEach((name) => {
          metabs.push(name);
        });
      }

      if (!isNaN(val) && val > 0 && metabs.includes(drugName.toUpperCase())) {
        return FLAGS['4'];
      }
    }

    return null;
  }

  checkEtgFlag({ drugName, val, cutoff }) {
    if (!isNaN(val) && val >= cutoff) {
      return FLAGS['6'];
    }

    return null;
  }

  checkIfMetabolite({ drugName }) {
    let match = false;

    for (const drug of this.drugMetabolites) {
      const metabolites = drug.metabolites.map((name) => name.toUpperCase());

      if (metabolites.includes(drugName.toUpperCase())) {
        match = true;

        break;
      }
    }

    return match;
  }

  getMetaboliteParent({ drugName }) {
    for (const drug of this.drugMetabolites) {
      const metabolites = drug.metabolites.map((name) => name.toUpperCase());

      if (metabolites.includes(drugName.toUpperCase())) {
        return drug.drugName;
      }
    }

    return null;
  }

  checkIfSiblingMetabolitesPresent({ drugName, parent, category }) {
    let siblingsPresent = false;

    const index = _.findIndex(this.drugMetabolites, (drug) => {
      const metabolites = drug.metabolites.map((name) => name.toUpperCase());

      return metabolites.includes(drugName.toUpperCase());
    });

    const { metabolites } = this.drugMetabolites[index];

    for (const metabolite of metabolites) {
      if (metabolite.toUpperCase() === drugName.toUpperCase()) {
        continue;
      }

      if (this.medicationComparisonSummary[category].includes(metabolite)) {
        siblingsPresent = true;

        break;
      }
    }

    return siblingsPresent;
  }

  addDrugToMedicationComparisonSummary({ drugName, category }) {
    const categories = [
      'medicationConsistent',
      'medicationInconsistentRxNotFound',
      'medicationInconsistentNotPrescribed',
    ];

    let drugToAdd = drugName;

    if (!categories.includes(category)) {
      return;
    }

    // Check if metabolite or parent drug
    const isMetabolite = this.checkIfMetabolite({ drugName });

    if (isMetabolite) {
      // Get the metabolite's parent
      const parent = this.getMetaboliteParent({ drugName });

      // Check if parent is already in the category
      if (this.medicationComparisonSummary[category].includes(parent)) {
        return;
      }

      // Check if sibling metabolites are present
      const siblingMetabolitesPresent = this.checkIfSiblingMetabolitesPresent({ drugName, parent, category });

      if (siblingMetabolitesPresent) {
        // Don't add sibling metabolites
        return;
      }
    }

    // Add drug
    if (!this.medicationComparisonSummary[category].includes(drugToAdd)) {
      this.medicationComparisonSummary[category].push(drugToAdd);
    }
  }

  removeDrugFromMedicationComparisonSummary(drugName) {
    for (const category in this.medicationComparisonSummary) {
      const idx = _.findIndex(this.medicationComparisonSummary[category], (name) => name.toUpperCase() === drugName.toUpperCase());

      if (idx > -1) {
        this.medicationComparisonSummary[category].splice(idx, 1);
      }
    };
  }

  checkMedicationComparisonSummary({ drugName, cI, posNeg }) {
    // Check if drug is prescribed and not detected.
    // This is indicated by drugName in this.drugNames and cI is "I".
    // Add to "medicationInconsistentRxNotFound".
    if (this.drugNames.includes(drugName.toUpperCase()) && cI === 'I') {
      this.removeDrugFromMedicationComparisonSummary(drugName);
      this.addDrugToMedicationComparisonSummary({ drugName, category: 'medicationInconsistentRxNotFound' });

      return 'medicationInconsistentRxNotFound';
    }

    // Check if drug is prescribed and detected.
    // This is indicated by drugName in this.drugNames and cI is "C".
    // Add to "medicationConsistent".
    if (this.drugNames.includes(drugName.toUpperCase()) && cI === 'C') {
      this.removeDrugFromMedicationComparisonSummary(drugName);
      this.addDrugToMedicationComparisonSummary({ drugName, category: 'medicationConsistent' });

      return 'medicationConsistent';
    }

    // Check if drug is detected and not prescribed.
    // This is indicated by drugName not in this.drugNames and cI is "I".
    // Add to "medicationInconsistentNotPrescribed".
    if (!this.drugNames.includes(drugName.toUpperCase()) && cI === 'I') {
      this.addDrugToMedicationComparisonSummary({ drugName, category: 'medicationInconsistentNotPrescribed' });

      return 'medicationInconsistentNotPrescribed';
    }

    // Catch-all for drugs that don't fit in previous categories.
    // Remove from medication comparison summary if necessary.
    this.removeDrugFromMedicationComparisonSummary(drugName);

    return null;
  }

  evaluate({ drugName, cutoff, value }) {
    // arg "value" is the entered "result" in the Analyst Dashboard
    const posNeg = this.checkPosNeg({ value, cutoff });
    const cI = this.checkCI({ drugName, value, cutoff });
    const flags = this.checkFlags({ drugName, value, cutoff });
    const medicationComparisonSummary = this.checkMedicationComparisonSummary({ drugName, cI, posNeg });

    return {
      posNeg,
      cI,
      flags,
      medicationComparisonSummary: this.medicationComparisonSummary,
    };
  }
}

export default DrugReport;
