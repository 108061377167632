import React, { useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { ButtonLoading } from '../Loading';

import { clearNotification, createNotification } from '../Notification';
import { AUTH_USER_QUERY } from '../../constants/gql';
import * as ROUTES from '../../constants/routes';

import '../../styles/form.css';

export const MIN_PASSWORD_LENGTH = 8;

export const CHANGE_CLINIC_USER_PASSWORD = gql`
  mutation ChangeClinicUserPassword(
    $id: String!,
    $password: String!
  ) {
    updateClinicUser(
      id: $id,
      data: {
        password: $password
      }
    ) {
      id
      updatedDate
    }
  }
`;

const ChangePassword = () => {
  const history = useHistory();

  useEffect(() => {
    // Clear notifications when navigating away
    return function cleanup() {
      clearNotification();
    }
  }, []);

  // Get auth user
  const { data: authUserData } = useQuery(AUTH_USER_QUERY);
  const { authUser } = authUserData;

  // Require auth
  if (!authUser) {
    // Redirect to login page
    history.push(ROUTES.SIGN_IN);
  }

  const [changePassword, { loading }] = useMutation(
    CHANGE_CLINIC_USER_PASSWORD,
    {
      onCompleted({ updateClinicUser }) {
        createNotification('info', 'Your password has been updated.');
      },
      onError(error) {
        console.log('Password change', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    },
  );

  return (
    <div className="page">
      <h4 className="title is-4 is-inline-block mt-1">Change Password</h4>

      <Formik
        initialValues={{
          password: '',
          confirmPassword: ''
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const { password } = values;

          // Execute the mutation
          changePassword({ variables: { id: authUser.id, password } });

          resetForm();
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required("New password is required.")
            .min(MIN_PASSWORD_LENGTH, `Password must be at least ${MIN_PASSWORD_LENGTH} characters.`),
          confirmPassword: Yup.string()
            .required("Password confirmation is required.")
            .oneOf([Yup.ref('password'), null], "Passwords must match.")
        })}
      >
        {(formProps) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = formProps;

          return (
            <form onSubmit={handleSubmit}>

                <label htmlFor="input-password">
                  New Password
                </label>
                <input
                  id="input-password"
                  name="password"
                  className={errors.password && touched.password ? "input error" : "input"}
                  type="password"
                  placeholder="Enter a password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {(errors.password && touched.password ?
                  <div className="input-feedback" data-testid="errors-password">
                    {errors.password}
                  </div>:
                    <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode"/>
                )}



                <label htmlFor="input-confirmPassword">
                  Confirm New Password
                </label>
                <input
                  id="input-confirmPassword"
                  name="confirmPassword"
                  className={errors.confirmPassword && touched.confirmPassword ? "input error" : "input"}
                  type="password"
                  placeholder="Re-enter password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {(errors.confirmPassword && touched.confirmPassword ?
                  <div className="input-feedback" data-testid="errors-confirmPassword">
                    {errors.confirmPassword}
                  </div>:
                    <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode"/>
                )}


              <input
                type="submit"
                className="button is-primary"
                value="Submit"
                disabled={isSubmitting}
              />
              {loading ? <ButtonLoading /> : null}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChangePassword;
